module.exports = ['$scope', 'ngDialog', 'activities', function($scope, ngDialog, activityService) {

  $scope.hourStep = 1;
  $scope.minuteStep = 1;

  var hash = $scope.ngDialogData.activityHash;
  var activityId = $scope.ngDialogData.activityId;
  var label = $scope.ngDialogData.activityLabel;
  var articleId = $scope.ngDialogData.articleId;
  var date = $scope.ngDialogData.date;
  var student = $scope.ngDialogData.student;
  var articleValues = $scope.ngDialogData.articleValues;

  $scope.myTime = new Date();
  if (articleValues[0].value && articleValues[0].value !== '') {
    $scope.myTime = new Date(articleValues[0].value);
  }


  $scope.selectTime = function() {
    tempArticleValues = [];
    var articleValue = {
      id: articleValues[0].id,
      value: $scope.myTime
    };
    tempArticleValues.push(articleValue);
    $scope.ngDialogData.postRegistration(0, date, student.id, hash, articleId, tempArticleValues);
    ngDialog.close();
  };

  $scope.resetTime = function() {
    tempArticleValues = [];
    var articleValue = {
      id: articleValues[0].id,
      value: null
    };
    tempArticleValues.push(articleValue);
    $scope.ngDialogData.postRegistration(0, date, student.id, hash, articleId, tempArticleValues);
    $scope.myTime = new Date();
    ngDialog.close();
  };
}];
