var app = angular.module('informatApp');

app.directive('scrollTogether', ['scrollRegister', function(scrollRegisterService) {
  return {
    restrict: 'A',
    scope: {
      scrollGroup: '@'
    },
    link: function(scope, element, attributes) {
      scrollRegisterService.registerScrollable(element, scope.scrollGroup);

      element.bind('scroll', function() {
        if(!element.data('wasJSScroll')){
          scrollRegisterService.scroll(element[0].id, element[0].scrollLeft, scope.scrollGroup);
        }
        element.data('wasJSScroll',false);
      });
    }
  };
}]);

app.factory('scrollRegister', ['lodash', function(lodashService) {
  var scrollables = {},
      _ = lodashService._;

  function scroll(elemId, scrollLeft, group) {
    _.forEach(scrollables[group], function(scrollable) {
      scrollable.data('wasJSScroll',true);
      if (scrollable[0].id !== elemId) {
        scrollable[0].scrollLeft = scrollLeft;
      }
    });
  }

  function registerScrollable(scrollable, group) {
    if (!scrollables[group]) {
      scrollables[group] = [];
    }
    var hasBeenRegistered = false;
    for (var i = 0; i < scrollables[group].length; i++) {
      var elem = scrollables[group][i];
      if(elem[0].id === scrollable[0].id){
        scrollables[group][i] = scrollable;
        hasBeenRegistered = true;
        break;
      }
    }

    if (!hasBeenRegistered) {
      scrollables[group].push(scrollable);
    }
  }

  return {
    scroll: scroll,
    registerScrollable: registerScrollable
  };
}]);
