module.exports = ['$scope', 'authentication', 'groups', 'period', 'activities', 'ngDialog',
function($scope, authenticationService, groupService, periodService, activityService, ngDialog) {
  $scope.logout = function() {
    authenticationService.logout();
  };

  $scope.numberOverviewBtnActive = false;
  $scope.previousView = '';
  $scope.toggleNumberOverview = function() {
    if (!$scope.numberOverviewBtnActive) {
      var temp = $scope.viewSrc;
      $scope.previousView = temp; // Using temp variable to break scope pointer
      $scope.changeView('numbers');
      $scope.numberOverviewBtnActive = true;
    }
    else {
      if ($scope.previousView === 'grid/gridView' || $scope.mobileView){
        $scope.changeView('grid');
      }
      else if ($scope.previousView === 'columns/columnView') {
        $scope.changeView('columns');
      }
      $scope.numberOverviewBtnActive = false;
      $scope.previousView = '';
    }
  };

  $scope.closeNumberOverview = function() {
    $scope.numberOverviewBtnActive = false;
    $scope.previousView = '';
  };
  $scope.$on('close-numbers', $scope.toggleNumberOverview);
}];
