// Intercepts all rest calls in order to add the authToken, if any.
// If the server returns an authentication error, redirects to the login page.

module.exports = ['$q', '$location', '$cookies', 'storage', 'CONFIG', function($q, $location, $cookies, storageService, CONFIG) {
  var baseURL = CONFIG.apiBasePath;

  return {
    'request': function(config) {
      config.headers = config.headers || {};
      var token = storageService.getAuthToken(),
          url = '';
      if (config.url.indexOf('/api') === 0) {

        if (config.url === "/api/token") {
          url = baseURL + 'token';
          config.data.grant_type = 'password';
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
          config.data = $.param(config.data);
        }
        else if (token) {
          url = baseURL + config.url.substring(5);

          config.headers.Authorization = 'BEARER ' + token;
          if (config.url.indexOf('?') !== -1) {
            config.url += '&cacheBuster=' + (new Date()).getTime();
          }
          else {
            config.url += '?cacheBuster=' + (new Date()).getTime();
          }
        }
        else if (token === false && config.url.indexOf('forgotPassword') === -1) {
          $location.path('/login');
        }

        config.url = url;
      }

      return config;
    }
  };
}];
