module.exports = ['$scope', 'ngDialog', 'lodash', 'students', 'spinnerService',
  function($scope, ngDialog, lodashService, studentService, spinnerService) {
    "use strict";
    $scope.filterDropdownOpened = false;
    $scope.sortDropdownOpened = false;
    $scope.studentSorts = studentService.getSorts();

    function unselectAllDropdowns() {
      $scope.filterDropdownOpened = false;
      $scope.sortDropdownOpened = false;
    }

    function openModalDropdown(id, listData, property) {
      $('.ngdialog--dropdown-theme--list').remove();
      var template = 'partials/modals/dropdownListModal';
      if (id === 'dropdown-modal--student-filter') {
        template = 'partials/modals/dropdownListModalWithInput';

      }
      ngDialog.open({
        template: template,
        appendTo: '#' + id,
        className: 'ngdialog--dropdown-theme--list',
        overlay: true,
        showClose: false,
        scope: $scope,
        trapFocus: false,
        preCloseCallback: function() {
          unselectAllDropdowns();
        },
        data: {
          'list': listData,
          'property': property,
          'id': id,
          'searchTextCallback': $scope.searchTextCallback,
          'selectedFilter': $scope.selectedStudentFilter
        }
      });
    }

    $scope.openFilterSelectionPicker = function(id, listData, property) {
      ngDialog.close();
      if (!$scope.filterDropdownOpened) {
        unselectAllDropdowns();
        openModalDropdown(id, listData, property);
        $scope.filterDropdownOpened = true;
      } else {
        unselectAllDropdowns();
      }
    };
    $scope.openSortSelectionPicker = function(id, listData, property) {
      ngDialog.close();
      if (!$scope.sortDropdownOpened) {
        unselectAllDropdowns();
        openModalDropdown(id, listData, property);
        $scope.sortDropdownOpened = true;
      } else {
        unselectAllDropdowns();
      }
    };

    $scope.select = function(item, property, id) {
      ngDialog.close('#' + id);
      var type = id.replace('dropdown-modal--', '');
      if (type === 'student-filter') {
        studentService.setSelectedStudentFilter(item, true);
      } else if (type === 'student-sort') {
        studentService.setSelectedStudentSort(item, true);
      }
      unselectAllDropdowns();
      $scope.$emit('dropdownSelected', {
        type: type
      });
    };
  }
];
