/**
 * StorageService
 *
 * This service provides an interface to save and get data from a session cookie,
 * to allow the user to refresh the page and still maintain, for example, his
 * selected school, group, date range, etc...
 */

module.exports = ['$cookies', 'lodash', function ($cookies, lodashService) {
    "use strict";

    var storageCookieName = 'storage',
            _ = lodashService._;

    function saveData(object) {
      // Check if cookie exists;
      var cookie = $cookies.getObject(storageCookieName),
          content = {};

      if (cookie) {
        content = cookie;
      }

      _.forEach(object, function (value, key) {
        content[key] = value;
      });

      $cookies.putObject(storageCookieName, content);
    }

    function retrieveData(key) {
      // Check if cookie exists;
      var cookie = $cookies.getObject(storageCookieName);
      if (cookie) {
        return cookie[key];
      }
      else {
        return false;
      }
    }

    function retrieveAll() {
      return $cookies.getObject(storageCookieName);
    }

    // Removes all the values with a key in the keys array from the cookie
    function remove(keys) {
      var cookie = $cookies.getObject(storageCookieName);
      if (cookie) {
        _.forEach(keys, function (value, key) {
          delete cookie[value];
        });
        $cookies.putObject(storageCookieName, cookie);
      }
    }

    function removeStorageCookie() {
      $cookies.remove(storageCookieName);
    }

    function getAuthToken() {
      var authCookie = $cookies.getObject('auth');

      if (!authCookie) {
        return false;
      }

      if (authCookie && (moment(authCookie.tokenExpiry) > moment() && authCookie.token)) {
        return authCookie.token;
      } else {
        return false;
      }
    }

    function removeAuthToken() {
      $cookies.remove('auth');
    }

    function getAuthRefreshToken() {
      var authCookie = $cookies.getObject('auth');

      if (!authCookie) {
        return false;
      }
      //clear the refresh token in the cookie so that a refresh can only be attempted once
      var refreshToken = authCookie.refreshToken ? authCookie.refreshToken : false;

      if (refreshToken) {
        delete(authCookie.refreshToken);
        $cookies.putObject('auth',authCookie);
      }

      return refreshToken;
    }

    function clear(){
      var allCookies = $cookies.getAll();
      _.forEach(allCookies,function(value,key){
        $cookies.remove(key);
      });
    }

    return {
      saveData: saveData,
      retrieveData: retrieveData,
      retrieveAll: retrieveAll,
      removeStorageCookie: removeStorageCookie,
      remove: remove,
      getAuthToken: getAuthToken,
      removeAuthToken: removeAuthToken,
      getAuthRefreshToken: getAuthRefreshToken,
      clear: clear
    };
  }];
