module.exports = ['$scope', '$timeout', 'activities', 'columns', 'ngDialog', 'period', '$rootScope', 'lodash',
  function($scope, $timeout, activityService, columnService, ngDialog, periodService, $rootScope, lodashService) {
    "use strict";

    var _ = lodashService._,
      arrowHidden = false,
      wrapper = document.getElementById('activity-button-container__wrapper'),
      container = document.getElementById('activity-button-container');

    $scope.$on('activity-updated', function() {
      columnService.updateColumns();
      $scope.safeApply();
    });

    // On click handler for the activityButtonDirective
    $scope.toggleActivity = function(id, active) {
      id = parseInt(id);

      var viewMode = '',
        isActive;
      if ($scope.viewSrc === 'grid/gridView') {
        viewMode = 'grid';
      }

      // In grid view, you can't toggle an activity off, only toggle another activity on.
      // This is to prevent no activity from being selected
      if (!(viewMode === 'grid' && !active))  {
        activityService.setActivityActive(id, active, viewMode);
        isActive = active;
      } else {
        isActive = !active;
      }

      var gaData = {
        eventCategory: 'activity',
        eventAction: 'toggled',
        eventLabel: 'toggled an activity in the sidecolumn'
      };
      $rootScope.$broadcast('send-ga-event', gaData);

      return isActive;
    };
    $scope.dateStart = '';

    // On click handler for the add new activity button
    $scope.addNewActivity = function() {
      var period = periodService.getPeriodAsMoment();

      $rootScope.$broadcast('send-ga-pageview', '/new-activity');
      ngDialog.open({
        template: 'partials/modals/newActivity',
        className: 'ngdialog-theme-default ngdialog-theme-activity',
        appendTo: '#modal',
        trapFocus: false,
        data: {
          dateStart: period.startDate,
          dateEnd: period.endDate
        }
      });
    };

    // If the activity bar contains more elements then are visible,
    // show a visual element indicating the user can scroll
    var checkShowArrow = function() {
      if ($scope.activities && $scope.activities.length * 90 > wrapper.clientHeight) {
        showArrow(true);
        arrowHidden = false;
      } else {
        hideArrow();
      }
    };

    var hideArrow = _.throttle(function() {
      if (!arrowHidden) {
        showArrow(false);
        arrowHidden = true;
      }
    }, 100);

    function showArrow(show) {
      $scope.showArrow = show;
      $scope.arrowTopOffset = wrapper.clientHeight + 38;
      $scope.safeApply();
    }

    // Hide the arrow when the user scrolls in the activity bar. It's purpose
    // as a visual indicator is complete.
    $('#activity-button-container__wrapper').on('scroll', hideArrow);

    checkShowArrow();

    $scope.$watch('activitiesLoaded', function(newValue, oldValue) {
      if (newValue) {
        // Activities have been loaded. I don't know why this should be false,
        // and not true. Research this more if there's time!
        $('#activity-button-container__wrapper').scrollTop(0);
        $timeout(checkShowArrow, 100);
      }
    });

    $(window).on('resize', _.throttle(checkShowArrow, 500));
  }
];
