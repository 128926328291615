module.exports = ['$window', function ($window) {
    return {
      restrict: 'E',
      scope: {
        buttonType: '@',
        name: '@',
        active: '=',
        activityId: '@',
        onClick: '=',
        iconName: '@',
        hash:'@'
      },
      template: '<i ng-hide="showPlaceholderIcon" class="fa {{icon}} activitybar__button__icon"></i>' +
              '<div ng-show="showPlaceholderIcon" class="activitybar__button__icon">' +
              '<i class="text--bold">{{placeholder}}</i>' +
              '</div>' +
              '<h3 class="text--extrabold">{{name}}</h3>',
      link: function (scope, element, attrs) {
        // if (scope.buttonType !== 'add') {
        //   scope.onClick(scope.activityId, true);
        // }

        switch (scope.buttonType) {
          case 'add':
            scope.icon = 'fa-plus-circle';
            break;
          case 'delete':
            scope.icon = 'fa-trash-o';
            break;
          case 'default' :
            scope.icon = 'fa-' + scope.iconName;
            break;
        }

        // If there is no font awesome icon defined, just show the first letter of the name
        if (!scope.iconName && scope.buttonType !== 'add' && scope.buttonType !== 'delete') {
          scope.showPlaceholderIcon = true;
          scope.placeholder = scope.name.charAt(0);
        }
        else {
          scope.showPlaceholderIcon = false;
        }

        element.bind('click', function () {
          if (scope.buttonType !== 'add' && scope.buttonType !== 'delete') {
            scope.active = scope.onClick(scope.hash, !scope.active);
          }
          else {
              scope.onClick(scope.hash, !scope.active);
          }
        });
      }
    };
  }];
