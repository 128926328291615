module.exports = ['$scope', '$controller', 'activities', 'students', 'absences', 'columns', 'period', 'ngDialog', 'lodash', 'CONFIG', '$rootScope','feedback', '$timeout',
  function($scope, $controller, activityService, studentService, absenceService, columnService, periodService, ngDialog, lodashService, CONFIG, $rootScope, feedbackService, $timeout) {
    var _ = lodashService._;
    $controller('studentColumnController', {
      $scope: $scope
    });
    $controller('columnViewController', {
      $scope: $scope
    });
    $scope.imageBaseURL = CONFIG.apiBasePath;
    $scope.locked = false;

    $scope.$on('activity-updated', function() {
      $scope.activity = _.find($scope.activities, function(activity) {
        return activity.active === true;
      });

      if (!$scope.activity && $scope.activities) {
        activityService.setActivityActive($scope.activities[0].hash, true, 'grid');

        $scope.activity = _.find($scope.activities, function(activity) {
          return activity.active === true;
        });
      }

      if ($scope.activity) {
        $scope.activity.hash = ($scope.activity.id + $scope.activity.name).hashCode();
      }
      initializeGridView();

      // This timeout fixed an issue where, after duplicating a registration, the view data in the grid was
      // not updated. Don't judge.
      $timeout(function() {
        $scope.$broadcast('updateActivityValue');
        $scope.safeApply();
      }, 50);
    });

    $scope.gridInitialized = false;

    // Set only the first active activity to active. All the rest should be inactive.
    (function() {
      if ($scope.dataLoaded) {
        var firstActiveActivity = _.find($scope.activities, function(activity) {
          return activity.active === true;
        });

        // Check if no activities are active. If so, activate first activity.
        if (!firstActiveActivity) {
          activityService.setActivityActive($scope.activities[0].hash, true, 'grid');

          firstActiveActivity = _.find($scope.activities, function(activity) {
            return activity.active === true;
          });
        } else {
          activityService.setActivityActive(firstActiveActivity.hash, true, 'grid');
        }

        $scope.activity = firstActiveActivity;
        initializeGridView();
      }
    })();

    // Gets the selected article or absence time.
    // Depending on this selection, sets the necessary data in scope.
    function initializeGridView() {
      if ($scope.activity.absenceColumn) {
        // It's an absence, not a registration
        $scope.isAbsence = true;
        $scope.property = 'dayPart';
        $scope.articles = absenceService.getAbsenceTimes();
        if ($scope.articles.length > 0 && $scope.articles[0].time) {
          $scope.property = 'time';
        }
      } else {
        $scope.isAbsence = false;
        $scope.property = 'name';
        // Columnservice is needed because it has the logic for the articles in correct days
        var columnDetails = columnService.getColumnDetails($scope.activity.hash),
          temp = columnDetails.subColumns;
        if (temp[0].id === 'calendar') {
          $scope.articles = _.slice(temp, 1, temp.length); //remove calendar subcolumn

        } else {
          $scope.articles = temp;
        }
      }
      $scope.selectedArticle = $scope.articles[0];
      $scope.articlesDropdownOpened = false;
      $scope.gridInitialized = true;
    }

    $scope.gridUpdate = false;
    $scope.$watch('selectedArticle', function()  {
      $scope.gridUpdate = true;
    });

    function unselectAllDropdowns() {
      $scope.articlesDropdownOpened = false;
    }
    $scope.showLock = function(isAbsenceColumn) {
      var selectedDate = periodService.getPeriod();
      if (moment(selectedDate.startDate).isAfter(moment()) && isAbsenceColumn) {
        return false;
      }
      return true;
    };
    $scope.openArticleSelectionPicker = function() {
      ngDialog.close();
      if (!$scope.articlesDropdownOpened) {
        unselectAllDropdowns();

        ngDialog.open({
          template: 'partials/modals/dropdownListModal',
          appendTo: '#dropdown-modal--articles',
          className: 'ngdialog--dropdown-theme--list',
          overlay: true,
          showClose: false,
          trapFocus: false,
          preCloseCallback: function() {
            unselectAllDropdowns();
          },
          scope: $scope,
          data: {
            'list': $scope.articles,
            'property': $scope.property,
            'id': 'dropdown-modal--articles',
            'selected': $scope.selectedArticle
          }
        });

        $scope.articlesDropdownOpened = true;
      } else {
        unselectAllDropdowns();
      }
    };

    var previousSelectFunction = $scope.select;
    $scope.select = function(item, property, id) {
      if (id === 'dropdown-modal--articles') {
        $scope.selectedArticle = item;
        unselectAllDropdowns();
        ngDialog.close('#' + id);
      } else {
        previousSelectFunction(item, property, id);
      }
    };

    $scope.showPeriodModal = function(isAbsence, data) {
      if (isAbsence)  {
        showPeriodAbsenceModal(data.absenceCodes, data.student, data.activity, data.absenceTime);
      } else {
        showPeriodRegistrationModal(data.student, data.activity);
      }
    };

    function showPeriodAbsenceModal(absenceCodes, student, activity, absenceTime) {

      $rootScope.$broadcast('send-ga-pageview', '/period-absence-modal');
      ngDialog.open({
        template: 'partials/modals/periodAbsenceModal',
        appendTo: '#modal',
        trapFocus: false,
        data: {
          "student": student,
          "absenceCodes": absenceCodes,
          "column": activity,
          "dashBoardCallback": $scope.testCall,
          "subColumn": absenceTime,
          "hasPeriodCheck": true
        },
        className: 'ngdialog-theme-default ngdialog--width700'
      });
    }

    function showPeriodRegistrationModal(student, activity) {
      ngDialog.open({
        template: 'partials/modals/periodRegistrationModal',
        appendTo: '#modal',
        trapFocus: false,
        data: {
          'student': student,
          'activityId': activity.id
        },
        className: 'ngdialog-theme-default ngdialog--width700'
      });
    }

    $scope.isLocked = function() {
      var formattedDate = periodService.getPeriod().startDate;
      if ($scope.activity.absenceColumn) {
        return $scope.absenceSubcolumnData[formattedDate][$scope.selectedArticle.id].locked;
      } else {
        return $scope.activity.locked;
      }
    };

    $scope.lockCurrentGridView = function() {
      var gaData = {
        eventCategory: 'gridview',
        eventAction: 'lock',
        eventLabel: 'Lock the current gridview'
      };
      $rootScope.$broadcast('send-ga-event', gaData);
      // Check absence vs activity
      var formattedDate = '';

      if ($scope.activity.type === 'D') {
        formattedDate = periodService.getPeriod().startDate;
      } else {
        formattedDate = $scope.activity.date;
      }

      var date = moment(formattedDate, "YYYY/MM/DD");
      if ($scope.activity.absenceColumn) {
        absenceService.lockAbsenceList(
          date,
          $scope.selectedArticle.id, !$scope.absenceSubcolumnData[formattedDate][$scope.selectedArticle.id].locked
        ).then(
          function(result) {},
          function(error) {
            console.log('Error locking current grid view: ', error);
          }
        );
      }
      else {
        // activity lock here
        activityService.lockActivity(
          $scope.activity.id,
          $scope.activity.name,
          date, !$scope.activity.locked
        ).then(
          function(result) {
            $scope.activity.locked = !$scope.activity.locked;
            feedbackService.createFeedback(result.data, $scope.activity.name, 'De activiteit werd bevestigd');
          },
          function(error) {
            console.log('Error locking current grid view: ', error);
          }
        );
      }
    };

    $scope.openMultiPersonGridView = function(locked) {
      if (!locked) {
        $rootScope.$broadcast('send-ga-pageview', '/multi-person-gridview-modal');
        var template = '',
          data = {
            "students": $scope.studentList,
            "column": $scope.activity,
            'subColumn': $scope.selectedArticle,
            "date": periodService.getPeriod().startDate
          };
        if ($scope.isAbsence) {
          template = 'partials/modals/multiPersonAbsences';
        } else {
          template = 'partials/modals/multiPersonRegistrations';
          data.date = {
            'moment': moment(data.date)
          };
        }

        ngDialog.open({
          template: template,
          appendTo: '#modal',
          trapFocus: false,
          data: data,
          className: 'ngdialog-theme-default ngdialog--width800'
        });
      }
    };

    $scope.openDuplicateRegistrationModal = function(column, date) {
      //self.postMultiPersonRegistration = function(persons, activityId, label, date, articles) {
      $rootScope.$broadcast('send-ga-pageview', '/duplicate-registration-modal');
      var tempStudents = studentService.getPersons();

      var students = [];
      _.forEach(tempStudents, function(student) {
        var objStudent = {
          id: student.id,
          addressId: student.addresses[0].id
        };
        students.push(objStudent);
      });

      ngDialog.open({
        template: 'partials/modals/duplicateModal',
        appendTo: '#modal',
        className: 'ngdialog-theme-default',
        overlay: true,
        showClose: true,
        scope: $scope,
        trapFocus: false,
        data: {
          "students": students,
          "activityId": column.id,
          "label": column.name,
          "date": moment(date, 'YYYY/MM/DD')
          //"date": moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
        }
      });
    };
  }
];
