module.exports = ['$scope', 'ngDialog', function($scope, ngDialog) {
  $scope.myNumber = 0;

  if ($scope.ngDialogData.value) {
    $scope.myNumber = $scope.ngDialogData.value;
  }
  $scope.resetNumber = function() {
    $scope.myNumber = 0;
  };

  $scope.increaseNumber = function() {
    $scope.myNumber++;
  };

  $scope.decreaseNumber = function() {
    if ($scope.myNumber > 0) {
      $scope.myNumber--;
    }
  };

  $scope.selectNumber = function() {
    $scope.ngDialogData.setValue($scope.myNumber);
    ngDialog.close();
  };

  $('#grid__container').scroll(function(e) {
    ngDialog.close();
  });

}];
