module.exports = ['$scope', 'columns', 'ngDialog', 'absences', 'activities', '$rootScope', 'feedback',
  function($scope, columnService, ngDialog, absenceService, activityService, $rootScope, feedbackService) {
    var columnDetails;

    function updateColumnDetails() {
      columnDetails = columnService.getColumnDetails($scope.column.hash);

      $scope.subColumns = columnDetails.subColumns;
      $scope.dates = columnDetails.dates;
      $scope.hasMultiLock = columnDetails.options.hasMultiLock;
    }

    updateColumnDetails();
    columnService.subscribeCallback(updateColumnDetails);


    $scope.isSubcolumnHeader = function(subColumn) {
      return (subColumn.id !== 'calendar');
    };
    $scope.isSingle = function(hashCode) {
      return ($scope.subColumns.length <= 1 || ($scope.hasCalendar(hashCode) && $scope.subColumns.length <= 2));
    };
    $scope.showMultiPersonAbsenceModal = function(subColumn, students, column, date, locked) {
      if (!locked) {
        $rootScope.$broadcast('send-ga-pageview', '/multiperson-absence-modal');
        ngDialog.open({
          template: 'partials/modals/multiPersonAbsences',
          appendTo: '#modal',
          trapFocus: false,
          data: {
            "students": students,
            "subColumn": subColumn,
            "column": column,
            "date": date
          },
          className: 'ngdialog-theme-default ngdialog--width800'
        });
      }
    };

    $scope.showMultiPersonRegistrationModal = function(locked, students, column, subColumn, date) {
      if (!locked.locked) {
        $rootScope.$broadcast('send-ga-pageview', '/multiperson-registration-modal');
        ngDialog.open({
          template: 'partials/modals/multiPersonRegistrations',
          appendTo: '#modal',
          trapFocus: false,
          data: {
            "students": students,
            "column": column,
            'subColumn': subColumn,
            "date": date,
            "registrations":locked
          },
          className: 'ngdialog-theme-default ngdialog--width800'
        });
      }
    };

    $scope.toggleAbsenceLock = function(date, subColumn) {
      var gaData = {
        eventCategory: 'Absencelock',
        eventAction: 'toggle',
        eventLabel: 'Toggled a lock for an absence'
      };
      $rootScope.$broadcast('send-ga-event', gaData);
      absenceService.lockAbsenceList(date.moment, subColumn.id, !$scope.absenceSubcolumnData[date.formatted][subColumn.id].locked).then(
        function(result) {
          console.log('succesfully locked list', result);
        },
        function(error) {
          console.log('Error toggling absence lock: ', error);
        }
      );
    };

    $scope.toggleActivityLock = function(date) {
      var gaData = {
        eventCategory: 'Activitylock',
        eventAction: 'toggle',
        eventLabel: 'Toggled a lock for an activity'
      };
      $rootScope.$broadcast('send-ga-event', gaData);
      activityService.lockActivity($scope.column.id, $scope.column.name, date, !$scope.registrations[$scope.column.hash][date.formatted].isLocked).then(
        function(result) {
          console.log('succesfully locked list', result);
          if ($scope.registrations[$scope.column.hash][date.formatted].isLocked) {
            feedbackService.createFeedback(result.data, 'Registraties', 'De lijst is bevestigd', 'success');
          }
        },
        function(error) {
          console.log('error in activity lock.', error);
        }
      );
    };

    // Hide icon if in future
    $scope.hideLocked = function(date, showInFuture) {
      if (date.isAfter(moment()) && !showInFuture) {
        return true;
      }
    };

    $scope.showMultiDatePersonModal = function(locked, students, column, dates) {
      if (!locked) {
        $rootScope.$broadcast('send-ga-pageview', '/multidate-person-modal');
        ngDialog.open({
          template: 'partials/modals/multiDatePersonRegistrations',
          appendTo: '#modal',
          trapFocus: false,
          data: {
            "students": students,
            "column": column,
            "date": dates[0]
          },
          className: 'ngdialog-theme-default ngdialog--width800'
        });
      }
    };
  }
];
