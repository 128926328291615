module.exports = [function () {
  function imageError(event){
    var target = $(event.target),
        className = target.attr('class');
    target.replaceWith('<i class="'+className+' fa fa-user"></i>');
  }

  return {
    restrict: 'A',
    link: function (scope, element, attributes) {
      // this is a use capture event, since error events do not bubble
      element[0].addEventListener('error',imageError,true);

    }
  };
}];
