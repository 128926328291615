/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

module.exports = [
  '$rootScope', 'Hub', '$timeout', 'lodash', 'storage', '$q', 'CONFIG',
  function($rootScope, Hub, $timeout, lodashService, storageService, $q, CONFIG) {
    var groupId;
    var currentPeriod;
    var connected = false;
    var establishingConnection = false;
    var groupConnectionAttempts = 0;
    var groupHub;
    var callbacks = {
        syncRegistrations: null,
        syncAbsencesCallback: null,
        lockActivitiesCallback: null,
        lockAbsenceListCallback: null
      },
      baseURL = CONFIG.apiBasePath,
      socketPromiseCallbacks = {
        resolve: null,
        reject: null
      };

    var _ = lodashService._;

    var maxAmountOfReconnections = (Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0) ? 5 : 100;
    var reconnectionCount = 0;
    var reestablishingAfterFailure = false;

    //logging option can be add to have advanced SignalR loggs in console
    var hubSettings = {
      rootPath: baseURL + "signalr/hubs",
      methods: ['connectToGroup'],
      transport: ['webSockets', 'longPolling'],
      queryParams: {
        token: storageService.getAuthToken()
      },
      logging: false,
      autoConnect: false,
      useSharedConnection: false,
      //handle connection error
      errorHandler: function(error) {
        // error.source == 'TimeoutException'
        console.error("socket errorHandler invoked: ", error);
        if (socketPromiseCallbacks.reject) {
          socketPromiseCallbacks.reject();
        }

        $rootScope.$broadcast('socket-connection-error');
      },
      //client side methods
      listeners: {
        syncAbsences: function(absences) {
          if (typeof callbacks.syncAbsencesCallback == "function") {
            callbacks.syncAbsencesCallback(absences);
          }
        },
        lockAbsenceLists: function(absenceLists) {
          if (typeof callbacks.lockAbsenceListCallback == "function") {
            callbacks.lockAbsenceListCallback(absenceLists);
          }
        },
        syncRegistrations: function(activities) {
          if (typeof callbacks.syncRegistrations == "function") {
            callbacks.syncRegistrations(activities);
          }
        },
        lockActivities: function(activities) {
          if (typeof callbacks.lockActivitiesCallback == "function") {
            callbacks.lockActivitiesCallback(activities);
          }
        }
      },
      stateChanged: function(state) {
        switch (state.newState) {
          case $.signalR.connectionState.connecting:
            console.log("Connecting to the server by socket...");
            reconnectionCount++;
            break;
          case $.signalR.connectionState.connected:
            console.log("Socket connection established.");
            connected = true;
            reconnectionCount = 0;
            reestablishingAfterFailure = false;

            if (!resolvePromise() && groupId && currentPeriod) {
              groupConnectionAttempts = 0;
              connectToGroup(groupId, currentPeriod);
            }
            break;
          case $.signalR.connectionState.reconnecting:
            console.log("Reconnecting socket connection...");
            break;
          case $.signalR.connectionState.disconnected:
            console.log("socket connection disconnected.");
            console.log("attempting to restablish main connection...");
            connected = false;
            if (reconnectionCount >= maxAmountOfReconnections) {

            } else {
              reestablishingAfterFailure = true;
              groupHub.connect(); //restablish the connection
            }
            break;
        }
      }
    };

    function resolvePromise() {
      if (socketPromiseCallbacks.resolve) {
        socketPromiseCallbacks.reject = null;
        socketPromiseCallbacks.resolve();
        // once resolve is called it is no longer needed
        socketPromiseCallbacks.resolve = null;
        return true;
      }
      return false;
    }

    function init() {
      //declaring the hub connection
      if (!connected) {
        groupHub = new Hub('groupHub', hubSettings);
      } else {
        resolvePromise();
      }
    }

    function resetGroupConnectionAttempts() {
      groupConnectionAttempts = 0;
    }
    /**
     * A function to subscribe to updates from a particular group
     * @param {type} group
     * @returns {undefined}
     */
    function connectToGroup(group, period) {
      console.log("trying to connect to group: ", group, period);
      //Check if a group id is already set, if it is disconnect
      // from the old group and connect to the new group

      if (!group || !period) {
        return;
      }

      groupId = group.groupId || group;
      currentPeriod = period;
      if (!establishingConnection && connected && groupId && currentPeriod) {
        establishingConnection = true;
        groupHub.connectToGroup(groupId, currentPeriod.startDate.format("YYYY-MM-DDTHH:mm:ssZ"), currentPeriod.endDate.format("YYYY-MM-DDTHH:mm:ssZ")).then(
          function(success) {
            establishingConnection = true;
            groupConnectionAttempts = 0;
            $rootScope.$broadcast('socket-reconnected');
            console.log("connectedToGroup " + groupId + "for period: ", currentPeriod);
          },
          function(errors) {
            if (groupConnectionAttempts < 3) {
              console.log("connection attempts: ", groupConnectionAttempts);
              groupConnectionAttempts++;
              establishingConnection = false;
              console.log("socket connection error, attempting to reconnect...");

              $timeout(function() {
                connectToGroup(groupId, currentPeriod);
              }, 2000);
            } else {
              //remove old Hub instance and create a new one to retry connection

              resetGroupConnectionAttempts();
              groupHub.disconnect();
              //groupHub = undefined;
              groupHub = new Hub('groupHub', hubSettings);
              groupHub.connect();
              establishingConnection = false;
              if (socketPromiseCallbacks.reject) {
                socketPromiseCallbacks.reject();
                return;
              }

              $rootScope.$broadcast('socket-connection-error');
              console.error("socket error: ", errors);
            }
          });
      }
    }

    /**
     * Set the callback functions to be used when an update event occurs:
     * @param {type} callbacksObject = {
     syncActivityCallback: null,
     syncAbsencesCallback: null,
     lockActivitiesCallback: null,
     lockAbsenceListCallback: null
     }
     */
    function setCallbacks(callbacksObject) {
      callbacks = _.merge(callbacks, callbacksObject);
    }

    function setSocketPromiseCallbacks(callbacksObject) {
      socketPromiseCallbacks = _.merge(socketPromiseCallbacks, callbacksObject);
    }

    function setConnectionState(state) {
      establishingConnection = state;
    }

    return {
      init: init,
      connectToGroup: connectToGroup,
      resetGroupConnectionAttempts: resetGroupConnectionAttempts,
      setCallbacks: setCallbacks,
      setSocketPromiseCallbacks: setSocketPromiseCallbacks,
      setConnectionState: setConnectionState,
      maxAmountOfReconnections: maxAmountOfReconnections
    };
  }
];
