module.exports = ['$scope', 'activities', 'spinnerService', '$timeout', 'lodash', '$rootScope', 'ngDialog', '$q','feedback', function($scope, activityService, spinnerService, $timeout, lodashService, $rootScope, ngDialog, $q, feedbackService) {
  "use strict";
  var _ = lodashService._;
  $scope.selectedDay = moment();
  $scope.formattedDay = null;

  var students = $scope.ngDialogData.students;
  var activityId = $scope.ngDialogData.activityId;
  var label = $scope.ngDialogData.label;
  var date = $scope.ngDialogData.date;

  function formatDate(day) {
    if (moment.isMoment(day)) {
      return day.format('YYYY-MM-DDTHH:mm:ssZ');
    }
    return moment(day).format('YYYY-MM-DDTHH:mm:ssZ');
  }

  $scope.data = {
    selectedActivity: undefined,
    activityTitle: ''
  };
  $scope.startDayMinOne = moment($scope.activityDateStart, "YYYY/MM/DD").subtract(1, 'days');

  // Same for end day
  $scope.endDayPlusOne = moment($scope.activityDateEnd, "YYYY/MM/DD").add(1, 'days');

  $scope.checkIfValid = function() {
    if ($scope.selectedDay === null) {
      return false;
    } else {
      return true;
    }
  };

  $scope.optionsDatepickerStart = {
    callback: function(day) {
      filterStartDays(day);
    }
  };

  function filterStartDays(day) {
    $scope.selectedDay = day;
    $scope.checkIfValid();
  }

  function _filter(day) {
    if (day.isBefore(moment(moment(), "YYYY/MM/DD").subtract(1, 'days'))) {
      return false;
    } else {
      return day;
    }
  }
  $scope.duplicateRegistration = function() {
    if ($scope.checkIfValid()) {
      spinnerService.show('modalSpinner');
      var activity = _.find(activityService.registrations, function(registration) {
        return registration.id === activityId;
      });

      var data = {
        activityId: activityId,
        label: label,
        fromDate: formatDate($scope.selectedDay),
        toDate: formatDate(date)
      };

      activityService.duplicateActivity(data).then(
        function(result) {
          feedbackService.createFeedback(result.data, 'Activiteiten', 'Registraties zijn gekopieerd');

          spinnerService.hide('modalSpinner');
          //DEPRECATED
          $rootScope.$broadcast('update-grid-view');

          $rootScope.$broadcast('update-dashboard');
          ngDialog.close();
        },
        function(error) {
          feedbackService.createFeedback(error.data, 'Activiteiten', 'Registraties konden niet gekopieerd worden');

          spinnerService.hide('modalSpinner');
          console.log('error duplicating activity: ', error);
        }
      );

    }
  };
}];
