module.exports = ['activities', 'absences', 'period', 'lodash', function(activityService, absenceService, periodService, lodashService)  {
  var _ = lodashService._,
    columns = [],
    /*
     * columnDetails: An array of objects with the following structure:
     *    - columnHash : The hash of the main column, corresponding to the activity hash
     *    - options: {
     *        - showCalendar: Flag indicating wether to show calendar buttons on the left
     *        - showEditAllBtn: Flag indicating wether to show the edit all button, used to set a status for all students in the entire period
     *        - hasMultiLock: Flag indicating wether to show a seperate lock for each period (subcolumn), or one lock for all
     *      }
     *    - dates: []
     *    - subColumns: an array of objects, each containing:
     *        - id: the id of the period of the subcolumn
     *        - name: the name of the subcolumn, i.e. VM, NM, 18:00,...
     *        - type: the type of the subcolumn, time | bool | num | absence
     */
    columnDetails = [],
    callbacks = [];

  // Creates the initial columnDetails array, based on the data from the activity service
  function createColumnDetails() {
    var activities = activityService.activities,
        dates;

    if (periodService.useDateRangeArray) {
      dates = periodService.dateRangeArray;
    }
    else {
      dates = periodService.getDatesInPeriod();
    }

    columnDetails = [];

    _.forEach(activities, function(activity) {
      // Create the subcolumns array
      var subColumns = [];
      var articles = activity.articles;

      _.forEach(articles, function(article) {
        var subColumn = {
          id: article.id,
          name: article.name,
          type: article.type
        };

        if (article.type === 'absence') {
          subColumn.absenceColumn = true;
          subColumn.absenceCodes = absenceService.getAbsenceCodes();
          subColumn.toggleableCodes = _.filter(subColumn.absenceCodes, function(absenceCode) {
            return absenceCode.toggle;
          });
        } else if (article.type === 'multi') {
          subColumn.articleValues = article.articleValues;
          subColumn.absenceColumn = false;
        } else {
          subColumn.absenceColumn = false;
        }

        subColumns.push(subColumn);
      });

      columnDetails.push({
        columnHash: activity.hash,
        options: {
          showCalendar: (activity.type !== 'P'),
          showEditAllBtn: false,
          hasMultiLock: false
        },
        dates: (activity.type === 'P') ? [periodService.createPeriodDate(activity.date,'')] : dates,
        subColumns: subColumns
      });
    });
    updateColumns();
  }

  function subscribeCallback(callback) {
    if (_.isFunction(callback)) {
      callbacks.push(callback);
    }
  }

  function reset() {
    callbacks = [];
    columns.length = 0;
    columnDetails.length = 0;
  }

  function updateColumns() {
    // For each activity in the activityservice, add a column if the activity
    // is active, and isn't already added into the column array. (Assuming the
    // name of the activity is unique!)
    var activities = activityService.activities;
    _.forEach(activities, function(activity, key) {
      var activityPresentInColumns = _.find(columns, {
        'hash': activity.hash
      });
      if (activity.active) {
        // If the active activity does not yet exist in the columns array, add it.
        if (!activityPresentInColumns) {
          columns.push({
            'id': activity.id,
            'name': activity.name,
            'type': activity.type,
            'absenceColumn': activity.absenceColumn,
            'icon': activity.icon,
            'hash': activity.hash
          });
        }
      } else {
        // Check if an inactive activity still resides in the columns array. If
        // so, remove it.
        if (activityPresentInColumns) {
          _.remove(columns, function(column) {
            return column.hash === activity.hash;
          });
        }
      }
    });

    _.forEach(callbacks, function(callback) {
      callback();
    });
  }

  function getColumnDetails(columnHash) {
    var columnDetail = _.find(columnDetails, {
      'columnHash': columnHash
    });
    if (columnDetail && columnDetail.options.showCalendar) {
      if (typeof _.find(columnDetail.subColumns, {
          'id': 'calendar'
        }) === 'undefined') {
        // Add calendar subcolumn
        columnDetail.subColumns.unshift({
          'id': 'calendar'
        });
      }
    }
    return columnDetail;
  }

  updateColumns();
  return {
    columns: columns,
    updateColumns: updateColumns,
    getColumnDetails: getColumnDetails,
    createColumnDetails: createColumnDetails,
    subscribeCallback: subscribeCallback,
    reset: reset
  };
}];
