module.exports = ['$rootScope', '$q', 'lodash', '$http', 'schools', 'groups', 'period', 'absences', 'spinnerService',
  function ($rootScope, $q, lodashService, $http, schoolService, groupService, periodService, absenceService, spinnerService)  {
    var _ = lodashService._,
      baseUrl = '/api',
      absenceActivityId = 0,
      self = {};

    self.activities = [];
    self.registrations = {};
    self.previousActiveActivities = []; // Keeps name, id and active state of all activities. Used to keep activities active between group changes.

    /*
     * activity: [
     *  { id: integer,
     *    name: String,
     *    icon: font awesome name,
     *    buttonType: 'default' | 'add' | 'delete',
     *    type: D | P   => If D, show a subcolumn per day. If P, show one column for the entire period
     *    canEditLabel: boolean,
     *    canAdd: boolean,
     *    locked: boolean,
     *    active: boolean   => we add this!
     *    comment: String
     *  },...
     * ]
     */

    self.updateRegistration = function (activityData) {
      //for each updated activity
      _.forEach(activityData, function (activity) {
        var hashCode = (activity.activityId + activity.activityLabel).hashCode(),
          date = moment(activity.date).format('YYYY/MM/DD');

        if (self.registrations[hashCode]) {
          if (self.registrations[hashCode][date]) {
            if (self.registrations[hashCode][date][activity.personId]) {
              if (self.registrations[hashCode][date][activity.personId][activity.articleId]) {
                for (var j = 0; j < self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues.length; j++) {
                  if (self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues[j].id === activity.articleValueId) {
                    var checkForTimeNumber = false;
                    // update numbers (before actual value so we can compare with previous value)
                    var number = 0,
                      prevValue = self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues[j].value;

                    if (self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues[j].type === 'num') {
                      prevValue = self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues[j].prevValue;
                      if (!prevValue) {
                        number = parseInt(activity.value);
                      }
                      else {
                        if (activity.value) {
                          number += parseInt(activity.value) - parseInt(prevValue);
                        }
                        else {
                          number -= prevValue;
                        }
                      }
                      self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues[j].prevValue = activity.value;
                    }
                    else if (self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues[j].type === 'bool') {
                      if (activity.value === 'JA') {
                        number += prevValue === 'JA' ? 0 : 1;
                      }
                      else {
                        number -= prevValue === 'NEE' ? 0 : 1;
                      }
                    }
                    else {
                      checkForTimeNumber = true;
                    }

                    self.registrations[hashCode][date][activity.personId][activity.articleId].articleValues[j].value = activity.value;
                    self.registrations[hashCode][date][activity.personId].addressId = activity.addressId;
                    self.registrations[hashCode][date][activity.personId][activity.articleId].update = true;
                    self.registrations[hashCode][date][activity.personId][activity.articleId].planned = activity.isPlanned;

                    if (checkForTimeNumber) {
                      numbers = checkNumberOfEnteredTimes(hashCode, date, activity.articleId, j);
                      self.registrations[hashCode][date].numbers[activity.articleId].articleValueNumbers[activity.articleValueId].registrations = numbers.registrations;
                      self.registrations[hashCode][date].numbers[activity.articleId].articleValueNumbers[activity.articleValueId].planned = numbers.planned;

                      var totalRegistrations = 0,
                        totalPlanned = 0;
                      _.forEach(self.registrations[hashCode][date].numbers[activity.articleId].articleValueNumbers, function (articleValueNumber) {
                        totalRegistrations += articleValueNumber.registrations;
                        totalPlanned += articleValueNumber.planned;
                      });
                      self.registrations[hashCode][date].numbers[activity.articleId].totalRegistrations = totalRegistrations;
                      self.registrations[hashCode][date].numbers[activity.articleId].totalPlanned = totalPlanned;
                    } else {
                      if (self.registrations[hashCode][date][activity.personId][activity.articleId].planned) {
                        self.registrations[hashCode][date].numbers[activity.articleId].totalPlanned += number;
                        self.registrations[hashCode][date].numbers[activity.articleId].articleValueNumbers[activity.articleValueId].planned += number;
                      } else {
                        self.registrations[hashCode][date].numbers[activity.articleId].totalRegistrations += number;
                        self.registrations[hashCode][date].numbers[activity.articleId].articleValueNumbers[activity.articleValueId].registrations += number;
                      }
                    }
                  }
                }
              }

            }
          }
        }
      });

      return self.registrations;
    };

    function checkNumberOfEnteredTimes(hashCode, date, articleId, articleValueIndex) {
      var registrations = 0,
        planned = 0;
      _.forEach(self.registrations[hashCode][date], function (registrationForPerson) {
        if (registrationForPerson && registrationForPerson[articleId] && !registrationForPerson[articleId].articleValueNumbers) {
          if (registrationForPerson[articleId].articleValues[articleValueIndex].value) {
            if (registrationForPerson[articleId].planned) {
              planned += 1;
            } else {
              registrations += 1;
            }
          }
        }
      });
      return {
        registrations: registrations,
        planned: planned
      };
    }

    self.getActivitiesForGroup = function () {
      //'/api/groups/:groupId/activities'
      var req = {
        method: 'GET',
        url: baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/activities/' + periodService.getDatesUrlPart(periodService.useDateRangeArray),
        headers: {
          'Content-Type': 'application/json'
        }
      };

      return $q(function (resolve, reject) {
        $http(req).then(
          function (response) {
            resolve(response);
          },
          function (error) {
            console.log('error in activityService: ', error);
            reject(error);
          });
      });
    };

    // Used when the websocket updates the lock status of activities.
    self.lockActivities = function (changed) {
      var tempRegistrations = self.registrations;

      _.forEach(changed, function (activityChanged) {
        var date = moment(activityChanged.date).format('YYYY/MM/DD');
        var hashCode = (activityChanged.activityId + activityChanged.label).hashCode();

        tempRegistrations[hashCode][date].isLocked = activityChanged.lock;
      });

      self.registrations = tempRegistrations;
    };

    // If viewMode = 'grid', only one activity can be active at a time
    self.setActivityActive = function (hash, active, viewMode) {
      if (viewMode === 'grid') {
        _.forEach(self.activities, function (activity) {
          if (activity.hash != hash) {
            activity.active = false;
          }
          else {
            activity.active = active;
          }
        });
      }
      else {
        _.find(self.activities, {
          'hash': hash
        }).active = active;
      }
      resetPreviouslyActiveActivities();
      $rootScope.$broadcast('activity-updated');
    };

    self.resetActiveActivities = function () {
      _.forEach(self.activities, function (activity) {
        activity.active = false;
      });
      resetPreviouslyActiveActivities();
      $rootScope.$broadcast('activity-updated');
    };

    // Requests all activity registration data for students of the selected school
    // group combination in the selected period. We populate the sidebar based on
    // this data, and also the actual values for every registration.
    self.updateRegistrations = function (firstTimeSetup, viewsrc) {
      self.registrations = {};

      var cacheBustingTimeStamp = Math.floor(Date.now() / 1000);

      var req = {
        method: 'GET',
        url: baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/registrations' + periodService.getDatesUrlPart(periodService.useDateRangeArray) + '&cachebusting=' + cacheBustingTimeStamp,
        headers: {
          'Content-Type': 'application/json'
        }
      };

      return $q(function (resolve, reject) {
        $http(req).then(
          function (response) {
            self.createActivities(response.data.registratieActivities, firstTimeSetup, viewsrc);
            self.buildOrUpdateRegistrationsHashMap(response.data.registratieActivities);
            resolve(self.activities);
          },
          function (error) {
            console.log('error in activity Service: ', error);
            reject(error);
          });
      });
    };

    /* NEW STRUCTURE - a hashMap
     * registrations: {
     *   hashCode: {   //string(id + name).hashCode()
     *     id: Activity id  => necessary for registration post
     *     label: string,
     *     isFree: boolean,
     *     2015/11/12: {
     *       isLocked: boolean,
             comment:  string
     *       123: {  //personId
     *         addressId: integer,
     *         123: { //articleId
     *           canEdit: boolean,
     *           planned: boolean,
     *           type: multi | num | time | bool
     *           articleValues: [
     *             {
     *               id: integer,
     *               name: String,
     *               type: 'time' | 'bool'| 'num' | 'absence'
     *               value: String | boolean | integer
     *             }
     *           ]
     *         }
     *       },
     *       numbers: { // for numbers overview (per day)
     *         confirmed: boolean,
     *         123: { //articleId
     *            articleName: String,
     *            totalRegistrations: integer,
     *            totalPlanned: integer,
     *            multi: boolean,
     *            articleValueNumbers: {
     *              123: { // articleValueId
     *                articleValueName: String,
     *                registrations: integer,
     *                planned: integer
     *              }
     *            }
     *         }
     *       } // end numbers
     *     }
     *   }
     * }
     * @returns {Array}
     */

    self.buildOrUpdateRegistrationsHashMap = function (activityData) {
      var temp = self.registrations;
      _.forEach(activityData, function (activity) {
        var hashCode = (activity.activityId + activity.activityLabel).hashCode();

        var numbers = {};

        _.forEach(activity.registrations, function (registration) {
          var tempReg = {
            'addressId': registration.addressId
          };

          var momentDate = moment(activity.date),
            date = momentDate.format('YYYY/MM/DD');
          _.forEach(registration.articles, function (article) {
            tempReg[article.id] = {
              'canEdit': article.canEdit,
              'articleValues': article.articleValues,
              'planned': article.planned, // planned if it's after today
              'show': registration.show
            };

            if (!numbers[article.id]) {
              numbers[article.id] = {
                'totalRegistrations': 0,
                'totalPlanned': 0,
                'articleValueNumbers': {}
              };
            }

            _.forEach(article.articleValues, function (articleValue) {
              if (!numbers[article.id].articleValueNumbers[articleValue.id]) {
                numbers[article.id].articleValueNumbers[articleValue.id] = {
                  'planned': 0,
                  'registrations': 0
                };
              }

              var number = 0;
              if (articleValue.type === 'num') {
                number = parseInt(articleValue.value);
              } else if (articleValue.type === 'bool') {
                number = articleValue.value === 'JA' ? 1 : 0;
              } else {
                number = articleValue.value ? 1 : 0;
              }

              if (articleValue.value) {
                if (article.planned) {
                  numbers[article.id].totalPlanned += number;
                  numbers[article.id].articleValueNumbers[articleValue.id].planned += number;
                } else {
                  numbers[article.id].totalRegistrations += number;
                  numbers[article.id].articleValueNumbers[articleValue.id].registrations += number;
                }
              }
            });

            // If an article contains multiple articleValues, the type of the article
            // is 'multi'. This indicates that the button should open a multi input
            // pop-up, instead of a time or num pop-up or a boolean toggle.
            if (article.articleValues.length > 1) {
              tempReg[article.id].type = 'multi';
            } else {
              tempReg[article.id].type = article.articleValues[0].type;
            }


            // For type number, add a previousvalue to help in calculating the numbers overview with the websocket update.
            if (tempReg[article.id].type === 'num' || tempReg[article.id].type === 'multi') {
              _.forEach(tempReg[article.id].articleValues, function (articleValue) {
                articleValue.prevValue = articleValue.value;
              });
            }
          });

          if (!temp[hashCode]) {
            temp[hashCode] = {
              'id': activity.activityId,
              'label': activity.activityLabel,
              'type': activity.type,
              'isFree': activity.isFree
            };
            // we only need the date when type is 'P'
            if (activity.type === 'P') {
              temp[hashCode].date = date;
            }
          }

          if (!temp[hashCode][date]) {
            numbers.confirmed = activity.isLocked;
            temp[hashCode][date] = {
              'isLocked': activity.isLocked,
              'comment': activity.comment,
              'numbers': numbers
            };
          } else {
            temp[hashCode][date].numbers = _.merge(temp[hashCode][date].numbers, numbers);
          }

          if (!temp[hashCode][date][registration.personId]) {
            temp[hashCode][date][registration.personId] = {};
          }
          _.merge(temp[hashCode][date][registration.personId], tempReg);
        });
        self.registrations = temp;
      });
    };

    self.createActivities = function (activityDataset, firstTimeSetup, viewsrc) {
      var right = _.find(groupService.getSelectedGroup().applications, function (application) {
        return application.application === 'registrations';
      }).right;

      _.forEach(activityDataset, function (activityData) {

        var activity = _.find(self.activities, function (activity) {
          if (activity.id === activityData.activityId) {
            // Activity is only unique if both id and label are different.
            return activity.name === activityData.activityLabel;
          }
        });

        if (!activity) {
          // Activity doesn't exist yet.
          var date = moment(activityData.date).format('YYYY/MM/DD');
          var hashCode = (activityData.activityId + activityData.activityLabel).hashCode();
          activity = {
            'id': activityData.activityId,
            'hash': hashCode,
            'name': activityData.activityLabel,
            'icon': activityData.icon,
            'buttonType': 'default',
            'type': activityData.type,
            'date': date,
            'absenceColumn': false,
            'canDelete': (right === 3 && activityData.canDelete) ? true : false,
            'canEditLabel': (right === 3 && activityData.canEditLabel) ? true : false,
            'canAdd': (right === 3 && activityData.canAdd) ? true : false,
            'locked': activityData.isLocked,
            'active': activityData.active,
            'comment': activityData.comment,
            'clickable': (right >= 1) ? true : false, //TODO Remove this when adding registrations to the project
            'class': 'activitybar__button--default',
            'articles': []
          };

          if (firstTimeSetup) {
            if (viewsrc === 'grid/gridView') {
              activity.active = false;
            }
          }
          else {
            activity.active = getPreviouslyActiveState(activity.id);
            activity.active = getPreviouslyActiveState(activity.id);
          }

          // If activty wasn't already active, checks the previously active state.
          // if (!activity.active) {
          //   console.log('previous active: ', activity.active);
          //   activity.active = getPreviouslyActiveState(activity.id, activity.name);
          //   console.log('and now: ', activity.active);
          // }

          self.activities.push(activity);

          _.forEach(activityData.registrations, function (registrationData) {
            // Every registrationData is the registration data for one person on one date

            _.forEach(registrationData.articles, function (article) {
              if (!_.find(activity.articles, function (activityArticle) {
                  return activityArticle.id === article.id;
                })) {
                var newArticle = {
                  'id': article.id,
                  'name': article.name,
                  'articleValues': article.articleValues
                };

                // If an article contains multiple articleValues, the type of the article
                // is 'multi'. This indicates that the button should open a multi input
                // pop-up, instead of a time or num pop-up or a boolean toggle.
                if (article.articleValues.length > 1) {
                  newArticle.type = 'multi';
                } else {
                  newArticle.type = article.articleValues[0].type;
                }

                activity.articles.push(newArticle);
              }
            });
          });
        } else {
          // TODO
          // 1 Activity exists?
          // 2 Fill it up with data
          // 3 ???
          // 4 Profit
        }
      });

      // resetPreviouslyActiveActivities();
    };

    function getPreviouslyActiveState(id) {
      return _.includes(self.previousActiveActivities, id);
    }

    function resetPreviouslyActiveActivities() {
      self.previousActiveActivities = _.map(self.activities, function(activity) {
        if (activity.active) {
          return activity.id;
        }
      });

      _.remove(self.previousActiveActivities, function(activeActivityId) {
        return !activeActivityId && activeActivityId !== 0;
      });
    }

    // TODO REWRITE!!!
    self.getRegistrationValue = function (activityId, personId, articleValueId) {
      var registrationsPerActivity = false,
        registrationsPerPerson = false;

      registrationsPerActivity = _.find(registrations, function (r) {
        return r.activityId === parseInt(activityId);
      });


      if (registrationsPerActivity) {
        registrationsPerPerson = _.find(registrationsPerActivity.registrations, function (r) {
          return r.personId === personId;
        });

        if (registrationsPerPerson) {
          return _.find(registrationsPerPerson.articleValues, function (a) {
            return a.id === parseInt(articleValueId);
          });
        }
      }

      return false;
    };

    /*
     * Although absence data is completely different from activity data,
     * it's better to map it to the same structure as an activity for the
     * frontend logic.
     */
    self.createAbsenceActivity = function (firstTimeSetup) {
      var hashCode = (absenceActivityId + 'Afwezigheden').hashCode();
      var absenceActivity = {
        'id': absenceActivityId,
        'hash': hashCode,
        'name': 'Afwezigheden',
        'icon': 'list-alt',
        'buttonType': 'default',
        'type': 'D',
        'absenceColumn': true,
        'canEditLabel': false,
        'canAdd': false,
        'locked': false,
        'active': firstTimeSetup,
        'comment': '',
        'articles': [],
        'clickable': true,
        'class': 'activitybar__button--default'
      };

      if (!firstTimeSetup) {
        absenceActivity.active = getPreviouslyActiveState(0);
      }

      if (self.activities && self.activities.length >= 1) {
        self.activities.unshift(absenceActivity);
      } else {
        self.activities = [absenceActivity];
      }

      resetPreviouslyActiveActivities();
    };

    // TODO REWRITE!!!
    self.addNewActivity = function (newActivity) {
      var activity = newActivity.selectedActivity,
        title = newActivity.activityTitle,
        date = newActivity.selectedDate,
        oldLabel = newActivity.oldLabel;
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/activities/edit',
        data = {
          "activityId": activity.id,
          "label": title,
          "date": moment(date).format('YYYY-MM-DDT') + '00:00:00'
        };

      return $http.post(url, data);
    };

    self.addAbsenceTimes = function () {
      var absenceActivity = _.findWhere(self.activities, {
          'id': absenceActivityId
        }),
        absenceTimes = absenceService.getAbsenceTimes();

      _.forEach(absenceTimes, function (absenceTime) {
        var name = (absenceTime.time) ? absenceTime.time : absenceTime.dayPart;
        absenceActivity.articles.push({
          'id': absenceTime.id,
          'name': name,
          'type': 'absence',
          'articleValues': [{
            'id': absenceTime.id,
            'name': name,
            'type': 'absence'
          }]
        });
      });
    };

    self.postRegistration = function (studentId, date, activityHash, addressId, articleId, articleValues) {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/registrations',
        data = {
          'registrations': [{
            'date': moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
            'personId': studentId,
            'activityId': self.registrations[activityHash].id,
            'label': self.registrations[activityHash].label,
            'addressId': addressId,
            'articles': [{
              'id': articleId,
              'articleValues': articleValues
            }]
          }]
        };

      return $http.post(url, data);
    };

    self.postRegistrations = function (registrations)  {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/registrations',
        data = {
          'registrations': registrations
        };

      if (data.registrations.length > 0) {
        return $http.post(url, data);
      } else {
        return $q(function (resolve, reject) {
          reject('No registrations to post.');
        });
      }

    };

    self.postPeriodRegistration = function (studentId, startDate, endDate, activityId, activityLabel, addressId, articles) {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/registrations/periods/' + studentId,
        data = {
          'ActivityId': activityId,
          'label': activityLabel,
          'addressId': addressId,
          'StartDate': moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
          'EndDate': moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
          'articles': articles
        };
      return $http.post(url, data);
    };

    self.postDatesArrayRegistration = function (studentId, dates, activityId, activityLabel, addressId, articles) {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/registrations/periods/' + studentId,
        data = {
          'ActivityId': activityId,
          'label': activityLabel,
          'addressId': addressId,
          'dates': dates,
          'articles': articles
        };
      return $http.post(url, data);
    };

    self.postMultiPersonRegistration = function (persons, activityId, label, date, articles) {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/registrations/multiperson',
        data = {
          'persons': persons,
          'activityId': activityId,
          'label': label,
          'date': moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
          'articles': articles
        };
      return $http.post(url, data);
    };

    self.lockActivity = function (id, label, date, locked) {
      if (!moment.isMoment(date)) {
        if (date.moment) {
          date = date.moment;
        } else {
          date = moment(date);
        }
      }
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/activities/lock',
        data = {
          'activities': []
        };

      data.activities.push({
        'activityId': id,
        'label': label,
        'date': date.format('YYYY-MM-DDTHH:mm:ssZ'),
        'lock': locked
      });

      return $http.post(url, data);
    };
    self.editActivity = function (id, label, date, oldLabel) {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/activities/edit',
        data = {
          'activityId': id,
          'label': label,
          'date': date.format('YYYY-MM-DDTHH:mm:ssZ'),
          'oldLabel': oldLabel
        };

      return $http.post(url, data);
    };
    self.removeActivity = function (id, label, date) {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/activities/remove',
        data = {
          'activityId': id,
          'label': label,
          'date': date.format('YYYY-MM-DDTHH:mm:ssZ')
        };

      return $http.post(url, data);
    };

    self.duplicateActivity = function (dataObj) {
      var url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups/' + groupService.getSelectedGroup().groupId + '/registrations/copy',
        data = {
          'activityId': dataObj.activityId,
          'label': dataObj.label,
          'fromDate': dataObj.fromDate,
          'toDate': dataObj.toDate
        };

      return $http.post(url, data);
    };

    //TODO post response update absences array and test if $scope.absences is updated
    return self;
  }
];
