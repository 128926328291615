// Takes care of boilerplate require code for browserify
var app = angular.module('informatApp');

app.controller('columnViewController', require('./columnViewController'));
app.directive('columnDirective', require('./columnDirective'));
app.controller('columnDirectiveController', require('./columnDirectiveController'));
app.directive('optionButtonDirective', require('./optionbuttonDirective'));
app.controller('columnFooterController', require('./columnFooterController'));
app.directive('columnAbsenceFooterDirective', require('./columnAbsenceFooterDirective'));
app.directive('columnActivityFooterDirective', require('./columnActivityFooterDirective'));
app.controller('columnFooterDirectiveController', require('./columnFooterDirectiveController'));
app.directive('columnHeaderDirective', require('./columnHeaderDirective'));
