module.exports = [
  '$scope', 'activities', 'spinnerService', '$timeout', 'lodash', 'absences', 'addresses', 'multiRegistration', 'feedback',
  function($scope, activities, spinnerService, $timeout, lodashService, absenceService, addressService, multiRegistrationService, feedbackService) {
    "use strict";

    var _ = lodashService._;

    $scope.subColumn = $scope.ngDialogData.subColumn;
    $scope.tempStudents = $scope.ngDialogData.students;
    $scope.students = [];
    $scope.column = $scope.ngDialogData.column;
    $scope.date = $scope.ngDialogData.date.formatted;
    if(!$scope.date){
      $scope.date = $scope.ngDialogData.date;
    }

    $scope.activityRegistrations = $scope.ngDialogData.registrations;

    $scope.activity = _.find(activities.activities, function(activity) {
      return activity.hash === $scope.column.hash;
    });
    $scope.article = _.find($scope.activity.articles, function(article) {
      return article.id === $scope.subColumn.id;
    });
    $scope.selectAll = true;

    var isAbsence = ($scope.activity && $scope.activity.absenceColumn) ? true : false;

    $scope.showSubOptions = false;
    $scope.absenceSubOptions = {};
    $scope.valid = false;

    var registrations = activities.registrations;


    $scope.allReasons = absenceService.getAbsenceCodes();
    $scope.reasons = [];
    var absences = absenceService.absences;
    if (isAbsence) {
      _.forEach($scope.tempStudents, function(student) {
        if (absences[student.id]) {
          _.forEach(absences[student.id][$scope.date], function(absence) {
            if (absence.show) {
              $scope.students.push(student);
              return false;
            }
          });
        }
      });

      $scope.data = {
        selectedReason: null,
        selectedSubOption: null,
        comment: '',
        selectedPersons: []
      };
    } else {
      $scope.students = $scope.tempStudents;
      $scope.data = {
        selectedPersons: []
      };
      if ($scope.article.type === 'multi') {
        if (!$scope.data[$scope.article.id]) {
          $scope.data[$scope.article.id] = {};
        }
        _.forEach($scope.article.articleValues, function(articleValue) {
          if (articleValue.type === 'bool') {

            $scope.data[$scope.article.id][articleValue.id] = false;
          }
        });
      }

    }

    if ($scope.article.articleValues.length === 1) {
      if (!$scope.data[$scope.article.id]) {
        $scope.data[$scope.article.id] = {};
      }
      if ($scope.article.articleValues[0].type === 'num') {
        $scope.data[$scope.article.id] = '1';
      } else if ($scope.article.articleValues[0].type === 'time') {
        $scope.data[$scope.article.id].checked = true;
      } else if ($scope.article.articleValues[0].type === 'bool') {
        $scope.data[$scope.article.id] = true;
      }
    }

    function resetOptions() {
      $scope.reasons = _.each($scope.reasons, function(item) {
        item.isSelected = false;
        _.each(item.subCodes, function(subCode) {
          subCode.isSelected = false;
        });
      });
    }

    $scope.checkTimePicker = function(articleId, articleValueId) {
      if (articleValueId) {
        $scope.data[articleId][articleValueId].checked = true;
      } else {
        $scope.data[articleId].checked = true;
      }
    };

    $scope.getPersonCodes = function() {
      resetOptions();
      var tempArr = [];
      var filteredArr = [];
      //Get types from each student and put them in 1 array,
      _.forEach($scope.data.selectedPersons, function(student) {
        tempArr.push(student.type);
      });
      //make each student type in this array unique, so you have only 1 unqiue type
      tempArr = _.uniq(tempArr);
      //loop every absence reason
      _.forEach($scope.allReasons, function(reason) {
        //bool to check if student type is in the array of the absence reason types.
        if (!reason.onlyPeriod) {
          var validReason = true;

          //loop every unique type
          _.forEach(tempArr, function(code) {

            //check if the unique type is also available in the reason type array.
            validReason = _.includes(reason.types, code);

            //if this is true then continue the loop, if not return false.
            if (!validReason) {
              return false;
            }
          });

          //if the array of reason types contains all the unique student types, add the reason to the filtered array.
          if (validReason) {
            filteredArr.push(reason);
          }
        }

      });
      //after doing all checks, equal the current reasons to the filtered array so the user only sees the proper reasons for the students.
      $scope.reasons = filteredArr;
      _.forEach(filteredArr, function(reason) {
        if ($scope.data.selectedReason) {
          if ($scope.data.selectedReason.id === reason.id) {
            reason.isSelected = true;
            return false;
          }
        }
        _.forEach(reason.subCodes, function(subCode) {
          if ($scope.data.selectedSubOption) {
            if ($scope.data.selectedSubOption.id === subCode.id) {
              subCode.isSelected = true;
              return false;
            }
          }
        });
      });
    };

    function resetSubOption() {
      _.each($scope.reasons, function(item) {
        _.each(item.subCodes, function(subCode) {
          subCode.isSelected = false;
        });
      });
    }

    $scope.checkIfSubOptions = function(absence) {
      _.each($scope.reasons, function(absence) {
        absence.isSelected = false;
      });
      $scope.data.selectedReason = absence;
      absence.isSelected = true;
      $scope.showSubOptions = true;
      $scope.absenceSubOptions = absence.subCodes;
    };
    $scope.selectSubOption = function(subOption) {
      resetSubOption();
      $scope.data.selectedSubOption = subOption;
      subOption.isSelected = true;
    };

    $scope.isValidPerson = function() {
      // TODO for some reason the person is valid check does not work instantly, look into this
      $timeout($scope.isValid, 10);
    };
    $scope.isValid = function() {
      if ($scope.data.selectedPersons.length === 0) {
        $scope.valid = false;
      } else if (isAbsence) {
        if ($scope.data.selectedReason !== null) {
          var found = false;
          _.forEach($scope.reasons, function(reason) {
            if ($scope.data.selectedReason.id === reason.id) {
              found = true;
            }
          });
          if (!found) {
            $scope.data.selectedReason = null;
          }
        }
        if ($scope.data.selectedReason === null || $scope.data.selectedPersons === null || $scope.data.selectedPersons.length === 0) {
          $scope.valid = false;
        } else {
          $scope.valid = true;
        }
      } else {
        $scope.valid = false;
        _.forEach($scope.data, function(value, key) {
          if (key !== 'selectedPersons') {
            /*
            if (typeof(value) === 'boolean' || value === 0 || multiRegistrationService.isValidRegistration(value)) {
              $scope.valid = true;
            }
            */
            $scope.valid = true;
          }
        });
      }

      return $scope.valid;
    };

    $scope.addMultiAbsence = function() {
      if ($scope.isValid()) {
        $scope.data.absenceTimeId = $scope.subColumn.id;
        $scope.data.absenceCodeId = $scope.data.selectedReason.id;
        if ($scope.data.selectedSubOption !== null) {
          $scope.data.absenceCodeId = $scope.data.selectedSubOption.id;
        }
        $scope.data.date = $scope.date;
        spinnerService.show('modalSpinner');
        absenceService.postAbsenceMultiPerson($scope.data).then(
          function(response) {
            spinnerService.hide('modalSpinner');
            $scope.closeThisDialog();
            feedbackService.createFeedback(response.data, 'Afwezigheden', 'De codes zijn aangepast');
          },
          function(error) {
            spinnerService.hide('modalSpinner');
            $scope.closeThisDialog();
            feedbackService.createFeedback(error.data, 'Afwezigheden', 'De codes konden niet worden aangepast');
            console.log('error in periodAbsenceController, added Period: ', error);
          });
      }
    };

    $scope.addMultiRegistration = function() {
      if ($scope.isValid()) {
        var articles = multiRegistrationService.createArticleData($scope.activity, $scope.data);
        var allZero = true;
        _.forEach($scope.data, function(value, key) {
          if (!Array.isArray(value)) {
            if (value > 0) {
              allZero = false;
            } else if (typeof(value) === 'object') {
              var objValue = value;
              _.forEach(objValue, function(value, key) {
                if (typeof(key) === 'string') {
                  if (value === true || value.checked === true || value > 0) {
                    allZero = false;
                  }
                }
              });
            }
          }
        });
        if (allZero) {
          var personsArray = [];
          _.forEach($scope.data.selectedPersons, function(person) {
            personsArray.push({
              'id': person.id,
              'addressId': null
            });
          });
          spinnerService.show('modalSpinner');
          activities.postMultiPersonRegistration(
              personsArray,
              $scope.activity.id,
              $scope.activity.name,
              $scope.ngDialogData.date.moment,
              articles)
            .then(
              function(response) {
                spinnerService.hide('modalSpinner');
                $scope.closeThisDialog();
                feedbackService.createFeedback(response.data, 'Activiteiten', 'Registraties zijn bewaard');

              },
              function(error) {
                console.log('error: ', error);
                spinnerService.hide('modalSpinner');
                $scope.closeThisDialog();
                feedbackService.createFeedback(error.data, 'Activiteiten', 'Registraties konden niet worden bewaard');
              }
            );
        } else {
          addressService.setRegistrations($scope.activityRegistrations);
          addressService.startAddressDialogChain($scope.data.selectedPersons, [$scope.ngDialogData.date.formatted], function(chosenAddresses) {
            // Create expected persons array [{id: GUID, addressId: GUID}, ...]
            spinnerService.show('modalSpinner');
            var personsArray = [];
            _.forEach(chosenAddresses, function(chosenAddress) {
              personsArray.push({
                'id': chosenAddress.studentId,
                'addressId': chosenAddress.addressId
              });
            });
            activities.postMultiPersonRegistration(
                personsArray,
                $scope.activity.id,
                $scope.activity.name,
                $scope.ngDialogData.date.moment,
                articles)
              .then(
                function(response) {
                  spinnerService.hide('modalSpinner');
                  $scope.closeThisDialog();
                  feedbackService.createFeedback(response.data, 'Activiteiten', 'Registraties zijn bewaard');
                },
                function(error) {
                  console.log('error: ', error);
                  spinnerService.hide('modalSpinner');
                  $scope.closeThisDialog();
                  feedbackService.createFeedback(error.data, 'Activiteiten', 'Registraties konden niet worden bewaard', 'error');

                }
              );
          });
        }

      }
    };

    function checkAll() {
      $scope.data.selectedPersons = angular.copy($scope.students);
    }
    $scope.unselectAllStudents = function() {
      $scope.selectAll = !$scope.selectAll;
      if ($scope.selectAll) {
        checkAll();
      } else {
        $scope.data.selectedPersons = [];
        $scope.isValid();
      }
    };



    $scope.$watch('data.selectedPersons', function(oldvalue, newvalue, scope) {
      $scope.getPersonCodes();
    }, true);
    //execute function on load
    checkAll();
    $scope.getPersonCodes();

    $scope.isValid();
  }
];
