// Controller for the custom directive optionbuttonDirective
module.exports = ['$scope', 'lodash', 'spinnerService', '$timeout', 'absences',
  function($scope, lodashService, spinnerService, $timeout, absenceService) {
    "use strict";
    var _ = lodashService._;

    // Initialize the modal with prefilled values
    function init() {
      $scope.showSubOptions = false;
      $scope.absenceSubOptions = {};

      $scope.absenceCodes = $scope.ngDialogData.absenceCodes;
      var filteredArr = [];
      _.forEach($scope.absenceCodes, function(reason) {
        //bool to check if student type is in the array of the absence reason types.
        if (!reason.onlyPeriod) {
          var validReason = true;
          //check if the unique type is also available in the reason type array.
          validReason = _.includes(reason.types, $scope.ngDialogData.student.type);

          //if the array of reason types contains all the unique student types, add the reason to the filtered array.
          if (validReason) {
            filteredArr.push(reason);
          }
        }

      });
      $scope.absenceCodes = filteredArr;
      var selectedId = parseInt($scope.ngDialogData.codeId),
        comment = $scope.ngDialogData.comment;

      $scope.disable = false;

      $scope.data = {
        "selectedReason": null,
        "selectedSubOption": null,
        "comment": (comment) ? comment : ''
      };

      // Check for each item if it has been selected
      _.forEach($scope.absenceCodes, function(item) {
        if (item.id === selectedId) {
          item.isSelected = true;
          $scope.data.selectedReason = item;
          $scope.checkIfSubOptions(item);
        } else {
          item.isSelected = false;
        }
        _.forEach(item.subCodes, function(subCode) {
          if (subCode.id === selectedId) {
            $scope.checkIfSubOptions(item);
            item.isSelected = true;
            $scope.data.selectedReason = item.code;
            subCode.isSelected = true;
            $scope.data.selectedSubOption = subCode;
            $scope.showSubOptions = true;
            $scope.absenceSubOptions = item.subCodes;
          } else {
            subCode.isSelected = false;
          }
        });
      });
    }

    $scope.resetDialog = function() {
      if (!$scope.ngDialogData.locked) {
        _.forEach($scope.absenceCodes, function(absenceCode) {
          absenceCode.isSelected = false;
        });
        _.forEach($scope.absenceSubOptions, function(subCode) {
          subCode.isSelected = false;
        });
        $scope.data.comment = '';
        $scope.data.selectedReason = null;
        $scope.data.selectedSubOption = null;
      }
    };

    $scope.checkIfSubOptions = function(absenceCode) {
      if (!$scope.ngDialogData.locked) {
        _.forEach($scope.absenceCodes, function(absence) {
          absence.isSelected = false;
        });
        $scope.data.selectedReason = absenceCode;
        $scope.data.selectedSubOption = {};
        absenceCode.isSelected = true;
        $scope.showSubOptions = true;
        $scope.absenceSubOptions = absenceCode.subCodes;
        $scope.checkIfValid();
      }
    };

    $scope.selectSubOption = function(subOption) {
      if (!$scope.ngDialogData.locked) {
        _.forEach($scope.absenceSubOptions, function(subCode) {
          subCode.isSelected = false;
        });
        $scope.data.selectedSubOption = subOption;
        subOption.isSelected = true;
        $scope.checkIfValid();
      }
    };

    $scope.addAbsence = function() {
      //TODO send $scope.data to the service
      var code = $scope.data.selectedReason.id,
        isSubcode = false;
      if ($scope.data.selectedSubOption && $scope.data.selectedSubOption.id) {
        code = $scope.data.selectedSubOption.id;
        isSubcode = true;
      }
      spinnerService.show('modalSpinner');

      $scope.ngDialogData.dialogHasSelectedAbsence();
      $scope.ngDialogData.postMethod(
        100,
        $scope.ngDialogData.date,
        $scope.ngDialogData.student.id,
        $scope.ngDialogData.absenceTimeId,
        code,
        $scope.data.comment,
        function() {}, // empty error callback. Ain't pretty, but it works.
        function(result) {
          spinnerService.hide('modalSpinner');
          $scope.closeThisDialog();
          $scope.ngDialogData.dialogSuccessCalback(result);
        }
      );
    };

    $scope.clearOptionValue = function() {
      $scope.resetDialog();
      $scope.ngDialogData.dialogIsCanceled();
      $scope.closeThisDialog();
    };

    $scope.valid = false;
    $scope.checkIfValid = function() {
      $scope.disable = false;
      if ($scope.ngDialogData.locked)  {
        $scope.valid = false;
        $scope.disable = true;
        return false;
      }
      if ($scope.data.selectedReason === null) {
        $scope.valid = false;
      } else {
        if ($scope.data.selectedReason.id === 0) {
          $scope.disable = true;
        }
        $scope.valid = true;
      }
    };
    init();
    $scope.checkIfValid();
  }
];
