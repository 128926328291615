module.exports = ['$scope', 'ngDialog', 'lodash', 'activities', 'spinnerService', '$rootScope', 'feedback', function($scope, ngDialog, lodashService, activityService, spinnerService, $rootScope, feedbackService)  {
  var _ = lodashService._;
  $scope.data = {};
  _.forEach($scope.activities, function(activity) {
    if (activity.hash === $scope.ngDialogData.activity.hash) {
      $scope.selectedActivity = activity;
    }
  });

  $scope.data.newName = $scope.selectedActivity.name;
  $scope.deleteActivity = function() {
    spinnerService.show('modalSpinner');
    activityService.removeActivity($scope.selectedActivity.id, $scope.selectedActivity.name, moment($scope.selectedActivity.date)).then(
      function(result) {
        feedbackService.createFeedback(result.data, 'Activiteiten', 'De activiteit werd verwijderd');

        spinnerService.hide('modalSpinner');
        $rootScope.$broadcast('update-dashboard');
        ngDialog.close();
      },
      function(error) {
        feedbackService.createFeedback(error.data, 'Activiteiten', 'De activiteit kon niet worden verwijderd');

        spinnerService.hide('modalSpinner');
        console.log('error edit activity: ', error);
      }
    );
  };

  $scope.isValid = function() {
    if ($scope.data.newName && ($scope.data.newName !== $scope.selectedActivity.name)) {
      return true;
    }
    return false;
  };
  $scope.showConfirmation = function() {
    ngDialog.openConfirm({
      className: 'ngdialog-theme-default ngdialog--width400 no-padding-top',
      template: '<p>Ben je zeker dat je deze activiteit wilt verwijderen?</p>' +
        '<div class="modal__footer">' +
        '<div class="btn btn--black btn--content-width btn-small btn--rounded-corner" ng-click="closeThisDialog(0)"><h4>Nee</h4></div>' +
        '<div class="btn btn--red btn--rounded-corner btn--content-width btn-small" ng-click="deleteActivity()"><h4>Ja, verwijderen</h4></div>' +
        '</div>',
      plain: true,
      scope: $scope
    });
  };
  $scope.updateActivity = function() {
    if ($scope.isValid) {
      spinnerService.show('modalSpinner');
      activityService.editActivity($scope.selectedActivity.id, $scope.data.newName, moment($scope.selectedActivity.date), $scope.selectedActivity.name).then(
        function(result) {
          feedbackService.createFeedback(result.data, 'Activiteiten', 'De naam werd aangepast');

          spinnerService.hide('modalSpinner');
          $rootScope.$broadcast('update-dashboard');
          ngDialog.close();

        },
        function(error) {
          feedbackService.createFeedback(error.data, $scope.selectedActivity.name, 'De naam werd niet aangepast');

          spinnerService.hide('modalSpinner');
          console.log('error edit activity: ', error);
        }
      );
    }
  };
}];
