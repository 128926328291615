module.exports = ['lodash', function(lodashService) {
  return {
    restrict: "E",
    templateUrl: "partials/widgets/dropdownModal",
    scope: {
      placeholder: "@",
      icon: "@",
      openedImage: "@",
      dropdownModal: "@",
      opened: "=",
      property: "@",
      selected: "=",
      showLabel: "=",
      emptyMessage: "@",
      typeddl: "@",
      type: "@"
    },
    link: function(scope, element, attributes) {
      var _ = lodashService._;
      scope.hasValue = false;
      if (scope.type !== 'checklist') {
        scope.$watch('selected', function(newValue, oldValue) {
          if (scope.type !== "")
            if (_.isEmpty(newValue)) {
              scope.selected[scope.property] = scope.emptyMessage;
            } else {
              if (newValue.id !== oldValue.id) {
                scope.selected = newValue;
                if (scope.typeddl === 'filter') {
                  scope.hasValue = true;
                }
                if (scope.type === "daterange") {
                  scope.selected = moment(scope.selected, "DD.MM.YYYY");
                }
              }
            }
        });
      }
      element.bind('click', function() {
        var $overlay = $('.ngdialog-overlay');
        setTimeout(function(){
          // temp set a style to fix rendering issues in safari
          $('.ngdialog-overlay').css('opacity', '1');
        }, 500);
        setTimeout(function(){
          // remove the inline style
          $('.ngdialog-overlay').css('opacity', '');
        }, 1000);
      });
    }

  };
}];
