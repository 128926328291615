module.exports = ['$q', 'students', 'lodash', 'ngDialog', 'feedback',
  function($q, studentService, lodashService, ngDialog, feedbackService) {
    var _ = lodashService._,
      self = {},
      activityRegistrations = {};

    // This triggers the start of a series (or chain) of dialogs, one for each student
    // with multiple address options. Once all of these are resolved, the callback
    // is called. This callback should expect ann array of objects of the following
    // format: { 'studentId': GUID, 'date': date, 'addressId': GUID }
    // For each day, for each student, we're attempting to find the desired address.
    self.startAddressDialogChain = function(students, dates, callback) {
      var studentsWithMultipleAddresses = [],
        index = 0,
        chosenAddresses = [];
      _.forEach(students, function(student)  {
        if (student.addresses.length > 1) {
          _.forEach(dates, function(date) {
            studentsWithMultipleAddresses.push({
              'student': student,
              'date': date
            });
          });
        }
        else {
          // Student doesn't have multiple addresses. Add the single address as
          // the default choice.
          _.forEach(dates, function(date)  {
            chosenAddresses.push({
              'studentId': student.id,
              'date': date,
              'addressId': student.addresses[0].id
            });
          });
        }
      });

      // If there are no students with multiple addresses on any date, we can just
      // call the callback. (ussually the POST method)
      if (studentsWithMultipleAddresses.length === 0)  {
        callback(chosenAddresses);
        return;
      }

      // Returns a promise surrounding the opening of an address selection dialog.
      function createNextPromise() {
        return $q(function(resolve, reject) {
          openAddressChoiceModal(
            studentsWithMultipleAddresses[index].student,
            studentsWithMultipleAddresses[index].date,
            resolve,
            reject
          );
        });
      }

      // Recursive function which creates a dialog in the address chain, and handles
      // the closing of that dialog: If it succesfully closes after the user has selected
      // an address, the next dialog in the chain is opened, unless it was the last one.
      // If it was the last one, it calls the callback.
      // If the dialog was cancelled, the entire process was cancelled and an Error
      // message is shown.
      function resolveAddressDialog(promise) {
        promise.then(
          function(result) {
            chosenAddresses.push({
              'studentId': studentsWithMultipleAddresses[index].student.id,
              'date': studentsWithMultipleAddresses[index].date,
              'addressId': result
            });

            index += 1;
            if (index < studentsWithMultipleAddresses.length) {
              //continue with next dialog
              resolveAddressDialog(createNextPromise());
            } else {
              // Stop chain of dialogs, call callback
              callback(chosenAddresses);
            }
          },
          function(error) {
            // Stop chain of dialogs, don't call callback
            showErrorMessage();
          }
        );
      }

      // Initialize first dialog in array
      resolveAddressDialog(createNextPromise());
    };
    self.setRegistrations = function(registrations) {
      activityRegistrations = registrations;
    };

    // Opens a dialog with the student's address options. Upon completion of the
    // dialog, the promise is resolved, triggering either the completion of the entire
    // chain of address dialogs, or a new address dialog (calling this same method)
    function openAddressChoiceModal(student, date, resolvePromise, rejectPromise) {
      var foundRegistration = {};

      if (activityRegistrations) {
        _.forEach(activityRegistrations, function(registration, key) {
          if (student.id === key && registration.addressId !== 0) {
            foundRegistration = registration;
          }
        });
      }

      ngDialog.open({
        template: 'partials/modals/addressChoice',
        appendTo: '#modal',
        trapFocus: false,
        showClose: true,
        closeByDocument: false,
        data: {
          'student': student,
          'date': moment(date).format('DD.MM.YYYY'),
          'resolvePromise': resolvePromise,
          'rejectPromise': rejectPromise,
          'foundRegistration': foundRegistration
        }
      });
    }

    function showErrorMessage() {
      feedbackService.createFeedback(null, 'Fout', 'U moet voor elke leerling met gesplitste rekeningen, een rekening  selecteren voor de ingave voltooid kan worden', 'error');

    }

    return self;
  }
];
