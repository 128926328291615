var app = angular.module('informatApp');

app.directive('dropdown', require('./dropdownDirective'));
app.directive('clickOutside', require('./clickoutsideDirective'));
app.directive('onLongPress', require('./longpressDirective'));
app.directive('daterangePicker', require('./daterangePicker'));
app.directive('numberPicker', require('./numberpickerDirective'));
app.controller('timepickerController', require('./timepickerController'));
app.controller('numberpickerController', require('./numberpickerController'));
app.controller('dropDownController', require('./dropDownController'));
app.controller('multipickerController', require('./multipickerController'));
app.directive('horizontalScroll', require('./horizontalScrollDirective'));
app.directive('scrollbarOffset', require('./scrollbarOffset'));
app.directive('rowHover', require('./rowHover'));
app.directive('checkRetinaImage', require('./checkRetinaImage'));
app.directive('captureImageErrors', require('./captureImageErrors'));
app.directive('keyButton', require('./keyButtonDirective'));
require('./checklistModelDirective');
require('./scrollTogether');
require('./ui-bootstrap');
require('./hamburgerMenu');
