module.exports = ['$scope', '$rootScope', '$timeout', '$q', 'columns', 'groups', 'lodash', 'absences', 'activities', 'CONFIG', 'spinnerService', 'ngDialog', 'students', 'feedback',
  function ($scope, $rootScope, $timeout, $q, columnService, groupService, lodashService, absenceService, activityService, CONFIG, spinnerService, ngDialog, studentService, feedbackService) {
    "use strict";

    var waitingOnServer = false;

    // The columns visible in the view, apart from the standard first column
    // filled with students and the last column used to add activities to the view.
    $scope.activityColumns = columnService.columns;
    $scope.showDetails = true;
    $scope.imageBaseURL = CONFIG.apiBasePath;

    $scope.toggleDetails = function (bool, $event) {
      $scope.showDetails = bool;
    };

    $scope.hasCalendar = function (columnHash) {
      var columnDetails = columnService.getColumnDetails(columnHash);
      if (columnDetails && columnDetails.options) {
        return columnDetails.options.showCalendar;
      }
      return false;
    };

    // Cached absence toggle logic

    var _ = lodashService._,
      toggleAbsenceTimeout,
      toggleRegistrationTimeout,
      updatedAbsences = {
        'absences': []
      },
      absenceErrorCallbacks = [], // If absence error, call these functions to reset necessary scopes
      absenceSuccessCallbacks = [], // If absence success, call these functions to reset necessary scopes
      dialogCb,
      registrationErrorCallbacks = [],
      updatedRegistrations = [];

    $scope.cancelUpdateAbsenceCode = function () {
      $timeout.cancel(toggleAbsenceTimeout);

      absenceErrorCallbacks = [];
      absenceSuccessCallbacks = [];

      updatedAbsences = {
        'absences': []
      };
    };
    $scope.cancelUpdateRegistration = function (valueToCancel) {
      if (!valueToCancel) {
        $timeout.cancel(toggleRegistrationTimeout);
        updatedRegistrations = [];
      } else {
        $timeout.cancel(toggleRegistrationTimeout);

        updatedRegistrations = _.filter(updatedRegistrations, function (registration) {
          return !(registration.personId === valueToCancel.studentId &&
            registration.date === moment(valueToCancel.date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ssZ') &&
            registration.activityId === activityService.registrations[valueToCancel.hash].id &&
            registration.label === activityService.registrations[valueToCancel.hash].label &&
            registration.articles[0].id === valueToCancel.articleId);
        });

        if (updatedRegistrations.length !== 0) {
          toggleRegistrationTimeout = $timeout(function () {
            postRegistrationData(resolve, reject);
          }, 1000);
        }
      }
    };

    $scope.updateAbsenceCode = function (delay, date, studentId, absenceTimeId, absenceCodeId, comment, errorCallback, dialogCallback, reset, successCallback) {
      $timeout.cancel(toggleAbsenceTimeout);

      if (errorCallback) {
        absenceErrorCallbacks.push(errorCallback);
      }
      if (successCallback) {
        absenceSuccessCallbacks.push(successCallback);
      }

      if (dialogCallback) {
        dialogCb = dialogCallback;
      }

      var absence;
      if (date && studentId && absenceTimeId) {
        absence = {
          'date': moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ssZ'),
          'personId': studentId,
          'absenceTimeId': absenceTimeId,
          'absenceCodeId': absenceCodeId,
          'comment': comment
        };
      }

      if (absence) {
        if (reset) {
          _.remove(updatedAbsences.absences, {
            'date': absence.date,
            'personId': absence.personId,
            'absenceTimeId': absence.absenceTimeId
          });
        }
        else {
          if (updatedAbsences.absences.length === 0) {
            updatedAbsences.absences.push(absence);
          } else {
            var found = false;
            _.forEach(updatedAbsences.absences, function (updatedAbsence) {
              if (updatedAbsence.date === absence.date &&
                updatedAbsence.personId === absence.personId &&
                updatedAbsence.absenceTimeId === absence.absenceTimeId) {
                updatedAbsence.absenceCodeId = absence.absenceCodeId;
                updatedAbsence.comment = absence.comment;
                found = true;
                return false; // break loop
              }
            });

            if (!found) {
              updatedAbsences.absences.push(absence);
            }
          }
        }
      }
      toggleAbsenceTimeout = $timeout(function () {
        postAbsenceData();
      }, delay);
    };

    $scope.updateSingleRegistration = function (delay, date, studentId, activityHash, addressId, articleId, articleValues) {
      return $q(function (resolve, reject) {
        var found = false;
        var dateTime = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
        $timeout.cancel(toggleRegistrationTimeout);
        _.forEach(updatedRegistrations, function (registration) {
          if (registration.date === dateTime) {
            _.forEach(registration.articles, function (article) {
              if (registration.personId === studentId && article.id === articleId && articleValues[0].id === article.articleValues[0].id) {
                found = true;
                article.articleValues[0].value = articleValues[0].value;
                return false;
              }
            });

            if (found) {
              return false;
            }
          }
        });

        if (!found) {
          updatedRegistrations.push({
            'date': dateTime,
            'personId': studentId,
            'activityId': activityService.registrations[activityHash].id,
            'label': activityService.registrations[activityHash].label,
            'addressId': addressId,
            'articles': [{
              'id': articleId,
              'articleValues': articleValues
            }]
          });
        }

        toggleRegistrationTimeout = $timeout(function () {
          postRegistrationData(resolve, reject);
        }, 1000);

      });
    };

    function postRegistrationData(resolve, reject) {
      var updatedRegistrationsLabels = [];
      if (!updatedRegistrations || updatedRegistrations.length === 0) {
        reject('No registrations to post.');
      }
      else {
        activityService.postRegistrations(updatedRegistrations).then(
          function (result) {
            feedbackService.createFeedback(result.data, 'Activiteiten', 'Registratie werd bewaard');

            spinnerService.hide('modalSpinner');
            ngDialog.close();
            resolve(result);
          },
          function (error) {
            var failedActivities = [];
            _.forEach(updatedRegistrationsLabels, function (updatedRegistrationsLabel) {
              failedActivities.push(updatedRegistrationsLabel);
            });
            failedActivities = _.uniq(failedActivities);

            var failedActivitiesString;
            if (failedActivities.length > 1) {
              failedActivitiesString = ' voor activiteiten ';
            } else {
              failedActivitiesString = ' voor de activiteit ';
            }

            if (failedActivities.length === 2) {
              failedActivitiesString += failedActivities[0] + ' en ' + failedActivities[1];
            } else {
              failedActivitiesString += failedActivities.join(', ');
            }

            feedbackService.createFeedback(error.data, 'Activiteiten', 'Registraties konden niet worden bewaard' + failedActivitiesString, 'error');
            spinnerService.hide('modalSpinner');
            ngDialog.close();

            createReloadDataTimeout();
            reject();
          }
        );

        // Prepare for error handler :(
        _.forEach(updatedRegistrations, function(updatedRegistration) {
          updatedRegistrationsLabels.push(updatedRegistration.label);
        });

        updatedRegistrations = []; // reset
      }
    }

    function postAbsenceData() {
      waitingOnServer = true;
      if (updatedAbsences.absences.length > 0) {
        absenceService.postAbsenceData(updatedAbsences).then(
          function (result) {
            waitingOnServer = false;
            feedbackService.createFeedback(result.data, 'Afwezigheden', 'De code is aangepast');

            _.forEach(absenceSuccessCallbacks, function (cb) {
              cb(result);
            });
            if (dialogCb) {
              dialogCb(result);
              dialogCb = undefined;
            }

            absenceErrorCallbacks = [];
            absenceSuccessCallbacks = [];
          },
          function (error) {
            waitingOnServer = false;
            feedbackService.createFeedback(error.data, 'Afwezigheden', 'De code kon niet worden aangepast');

            _.forEach(absenceErrorCallbacks, function (cb) {
              cb();
            });
            if (dialogCb) {
              dialogCb();
              dialogCb = undefined;
            }

            absenceErrorCallbacks = [];
            absenceSuccessCallbacks = [];

            console.log('error posting absence: ', error);
            // Reload data because we're unsure if it's correct.
            createReloadDataTimeout();
          }
        );

        updatedAbsences = {
          'absences': []
        }; // reset
      }
    }

    var reloadDataTimeout;
    function createReloadDataTimeout() {
      $timeout.cancel(reloadDataTimeout);
      reloadDataTimeout = $timeout(function() {
        if (!waitingOnServer) {
          $scope.showConnectivityProblemSpinner('Even geduld...', false);
          $rootScope.$broadcast('update-dashboard-from-error');
        }
        else {
          createReloadDataTimeout();
        }
      }, 2000);
    }
  }
];
