// Read https://blog.codecentric.de/en/2014/08/angularjs-browserify/ for better browserify structure
var app = angular.module('informatApp', [
  // Drag and drop dependency
  // require('angular-dragula')(angular),
  // Single page routing dependency
  'ui.router',
  // Cookie storage dependency
  require('angular-cookies'),
  //load spinner dependency
  require('angular-spinners'),
  //toaster pop-up dependency
  require('angularjs-toaster'),
  'ngDialog',
  'mightyDatepicker',
  'ngAnimate',
  'checklist-model',
  'SignalR',
  'ui.bootstrap'
]);

app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider',
  function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider) {
    // Define the routes inside the single page app.
    $urlRouterProvider.otherwise('/login');

    $stateProvider
            .state('login', {
              url: '/login',
              templateUrl: 'partials/login',
              data: {
                requireLogin: false
              }
            })

            .state('forgotPassword', {
              url: '/forgotPassword',
              templateUrl: 'partials/forgotPassword',
              data: {
                requireLogin: false
              }
            })

            .state('schoolSelection', {
              url: '/schoolSelection',
              templateUrl: 'partials/schoolSelection',
              data: {
                requireLogin: true
              }
            })

            .state('app', {
              url: '/app',
              templateUrl: 'partials/dashboard',
              data: {
                requireLogin: true
              }
            });

    // use the HTML5 History API to remove the hashtag from the urls
    // This gives a problem with page reload though, so commented out until there's a backend fix.
    // $locationProvider.html5Mode(true);

    // Intercepts all rest calls in order to add the authToken, if any.
    // If the server returns an authentication error, redirects to the login page.
    $httpProvider.interceptors.push('apiInterceptor');
    $httpProvider.interceptors.push('authenticationInterceptor');
  }
]);

app.run(['$rootScope', '$state', '$http', '$templateCache', 'authentication', 'restore',
  function ($rootScope, $state, $http, $templateCache, authenticationService, restoreService) {
    // Capture attempted state changes (i.e. route changes) and inspect them for the requireLogin param
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      var requireLogin = toState.data.requireLogin;

      // If the user wants to go to the login page, no need for further checking
      if (toState.name === 'login') {
        return;
      }

      // Check if the user has logged in this session using the authToken cookie
      if (requireLogin && !authenticationService.isAuthenticated()) {
        event.preventDefault();
        //Reroute to the login page.
        $state.go('login');
      }

      // If the user attempts to go to the dashboard, a school needs to be selected first.
      if (fromState.name === "") {
        // fromState is empty => page refresh!
        if (toState.url === "/app") {
          // refreshing /app route! User is attempting to go to the dashboard from a refresh or by manually inserting the url
          restoreService.restore();
        }
      }
    });
  }
]);

//Utility function
String.prototype.hashCode = function () {
  var hash = 0, i, chr, len;
  if (this.length === 0) return hash;
  for (i = 0, len = this.length; i < len; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
Number.prototype.isInteger = function() {
  return (this ^ 0) === +this;
};

// Load all controllers, directives, services using browserify folder syntax
require('../config');
require('./services');
require('./dashboard');
require('./directives');
require('./authentication');
require('./schoolSelection');
require('./activitybar');
require('./columnView');
require('./gridView');
// require('./draggables');
require('./dashboard');
require('./header');
require('./services');
require('./studentColumn');
require('./modals');
require('./datepicker');
require('./footer');
require('./libraries');
