module.exports = ['$scope', 'lodash', function($scope, lodashService) {
  var _ = lodashService._;
  $scope.student = $scope.ngDialogData.student;
  $scope.date = $scope.ngDialogData.date;
  $scope.resolvePromise = $scope.ngDialogData.resolvePromise;
  $scope.rejectPromise = $scope.ngDialogData.rejectPromise;
  $scope.foundRegistration = $scope.ngDialogData.foundRegistration;
  $scope.valid = false;
  $scope.data = {
    selectedAddress: undefined
  };
  if ($scope.foundRegistration) {
    var index = 0;
    _.forEach($scope.student.addresses, function(address) {
      if (address.id === $scope.foundRegistration.addressId) {
        $scope.data.selectedAddress = index + "";
      }
      index++;
    });
  }
  $scope.checkIfValid = function()  {
    if ($scope.data.selectedAddress) {
      $scope.valid = true;
    } else {
      $scope.valid = false;
    }
    return $scope.valid;
  };

  $scope.chooseAddress = function() {
    if ($scope.data.selectedAddress || $scope.data.selectedAddress === 0) {
      $scope.closeThisDialog();
      $scope.resolvePromise($scope.student.addresses[$scope.data.selectedAddress].id);
    } else {
      $scope.closeThisDialog();
      $scope.rejectPromise();
    }
  };
  $scope.checkIfValid();
}];
