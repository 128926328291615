module.exports = ['$http', '$q', 'lodash', 'schools', 'groups', 'period', function($http, $q, lodashService, schoolService, groupService, periodService)  {
  "use strict";

  var _ = lodashService._,
    baseUrl = '/api',
    students = [],
    selectedStudentFilter = {},
    selectedStudentSort = {},
    amountOfTeachers = 0; //necessary for numbers overview

  var studentFilter = [];
  var studentSorts = [{
    "id": "56sdg5ds41g",
    "name": "Voornaam",
    "filter": 'firstNameNoWhiteSpace'
  }, {
    "id": "sd5g4sd5g4",
    "name": "Naam",
    "filter": 'lastNameNoWhiteSpace'
  }, {
    "id": "julkgu4k54",
    "name": "Klasnummer",
    "filter": 'klasNr'
  }, {
    "id": "qsdg848",
    "name": "Bijnaam",
    "filter": 'nickNameNoWhiteSpace'
  }];

  function getFilters() {
    return studentFilter;
  }

  function getSorts() {
    return studentSorts;
  }

  function getPersons() {
    return students;
  }

  function getPerson(id) {
    return _.find(students, {
      'id': id
    });
  }

  function setSelectedStudentFilter(filter, saveData) {
    selectedStudentFilter = filter;
    if (saveData) {

    }
  }

  function clearSelectedStudentFilter() {
    selectedStudentFilter = {};
  }

  function getSelectedStudentFilter() {
    return selectedStudentFilter;
  }

  function setSelectedStudentSort(sort, saveData) {
    selectedStudentSort = sort;
    if (saveData) {

    }
  }

  function getSelectedStudentSort() {
    return selectedStudentSort;
  }

  function updatePerson(id, changed) {
    var student = getPerson(id);
    _.merge(student, changed);
  }

  function getSelectedFilterResult() {
    ///api/groups/:groupId/filters/:filterId
    var group = groupService.getSelectedGroup();
    var filter = getSelectedStudentFilter();

    return $q(function(resolve, reject) {
      if (group && group.groupId) {
        var req = {
          method: 'GET',
          url: baseUrl + '/groups/' + group.groupId + '/filters/' + filter.id + '?startdate=' + periodService.getPeriod().startDate + '&enddate=' + periodService.getPeriod().endDate,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        $http(req).then(
          function(response) {
            students = response.data.students;
            resolve(students);
          },
          function(error) {
            console.log('error in studentService: ', error);
            reject(error);
          });
      } else {
        reject('No group defined!');
      }
    });
  }

  function updatePersons() {
    var group = groupService.getSelectedGroup();
    var self = this;
    return $q(function(resolve, reject) {
      if (group && group.groupId) {
        var req = {
          method: 'GET',
          url: baseUrl + '/groups/' + group.groupId + '/persons?startdate=' + periodService.getPeriod().startDate + '&enddate=' + periodService.getPeriod().endDate,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        $http(req).then(
          function(persons) {
            _.forEach(persons.data.students, function(student) {
              student.firstNameNoWhiteSpace = student.firstName.replace(/ /g, '').toLowerCase();
              student.lastNameNoWhiteSpace = student.lastName.replace(/ /g, '').toLowerCase();
              student.nickNameNoWhiteSpace = student.nickName.replace(/ /g, '').toLowerCase();
            });
            students = persons.data.students;
            self.amountOfTeachers = persons.data.teachers.length;
            _.forEach(persons.data.teachers, function(teacher) {
              teacher.isTeacher = true;
              teacher.firstNameNoWhiteSpace = teacher.firstName.replace(/ /g, '').toLowerCase();
              teacher.lastNameNoWhiteSpace = teacher.lastName.replace(/ /g, '').toLowerCase();
              teacher.nickNameNoWhiteSpace = teacher.nickName.replace(/ /g, '').toLowerCase();
              students.push(teacher);
            });
            resolve(students);
          },
          function(error) {
            console.log('error in studentService: ', error);
            reject(error);
          });
      } else {
        reject('No group defined!');
      }
    });
  }

  function updateFilters() {
    var location = schoolService.getSelectedSchool();
    return $q(function(resolve, reject) {
      if (location && location.id) {
        var req = {
          method: 'GET',
          url: baseUrl + '/locations/' + location.id + '/filters',
          headers: {
            'Content-Type': 'application/json'
          }
        };
        $http(req).then(
          function(response) {
            studentFilter = response.data.filters;
            resolve(studentFilter);
          },
          function(error) {
            console.log('error in studentService: ', error);
            reject(error);
          });
      } else {
        reject('No location defined!');
      }
    });
  }

  function getPersonDetails(personId) {
    return $q(function(resolve, reject) {
      if (personId) {
        var req = {
          method: 'GET',
          url: baseUrl + '/persons/' + personId,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        $http(req).then(
          function(response) {
            resolve(response);
          },
          function(error) {
            console.log('error in studentService: ', error);
            reject(error);
          }
        );
      } else {
        reject('No person id defined!');
      }
    });
  }

  return {
    getPersons: getPersons,
    getPerson: getPerson,
    getPersonDetails: getPersonDetails,
    updatePerson: updatePerson,
    updatePersons: updatePersons,
    getFilters: getFilters,
    getSelectedStudentFilter: getSelectedStudentFilter,
    setSelectedStudentFilter: setSelectedStudentFilter,
    getSorts: getSorts,
    getSelectedStudentSort: getSelectedStudentSort,
    setSelectedStudentSort: setSelectedStudentSort,
    updateFilters: updateFilters,
    getSelectedFilterResult: getSelectedFilterResult,
    clearSelectedStudentFilter: clearSelectedStudentFilter,
    amountOfTeachers: amountOfTeachers
  };
}];
