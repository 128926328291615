module.exports = ['$scope', 'ngDialog', 'period', 'lodash', 'schools', '$timeout', 'feedback', function($scope, ngDialog, periodService, lodashService, schoolService, $timeout, feedbackService) {
  var _ = lodashService._;

  var tempStartDate = moment($scope.ngDialogData.dateStart),
    tempEndDate = moment($scope.ngDialogData.dateEnd);
  //checks day at start when modal opens.
  $scope.$watch('absencePeriods', function(oldvalue, newvalue) {
    if ($scope.ngDialogData.hasPeriodCheck) {
      var tempDates = [tempStartDate, tempEndDate];
      _.forEach($scope.absencePeriods, function(period) {
        var isBetweenPeriod = false;
        _.forEach(tempDates, function(tempDate) {
          if (tempDate.isBetween(period.startDate, period.endDate) || tempDate.isSame(period.startDate) || tempDate.isSame(period.endDate)) {
            tempStartDate = period.startDate;
            tempEndDate = period.endDate;
            $scope.message = "Er bevindt zich een periode op de geselecteerde dag.";
            $scope.updateSelectedStart(period.startDate);
            $scope.updateSelectedEnd(period.endDate);
            $scope.updateSelectedPeriod(period);
            $scope.dateStart = period.startDate;
            $scope.dateEnd = period.endDate;
            return false;
          }
        });
        if (isBetweenPeriod) {
          return false;
        }
      });
    }
  });

  // Startdate of the datepicker
  $scope.dateStart = tempStartDate;

  // Enddate of the datepicker
  $scope.dateEnd = tempEndDate;

  // Wether to show one datepicker or several
  $scope.showOneDatePicker = $scope.ngDialogData.showOneDatePicker;

  $scope.onPeriodSelect = $scope.ngDialogData.onPeriodSelect;

  // Second datepicker should allow selecting the same day as startDate. So the 'after' option
  // in that directive should be equal to startDate +1 day.
  $scope.startDayMinOne = moment($scope.dateStart, "YYYY/MM/DD").subtract(1, 'days');

  // Same for end day
  $scope.endDayPlusOne = moment($scope.dateEnd, "YYYY/MM/DD").add(1, 'days');

  $scope.optionsDatepickerStart = {
    callback: function(day) {
      if (day.isAfter($scope.dateEnd)) {
        $scope.dateEnd = day;
      }

      // Days can only be 14 apart
      // Create a clone of the day, to avoid mutating the original
      if ($scope.onPeriodSelect) {
        var dayClone = moment(day);
        dayClone.add(14, 'days');
        if ($scope.dateEnd.isAfter(dayClone)) {
          $scope.dateEnd = dayClone;
        }
      }

      $timeout(function() {
        $scope.applyFiltersToDaterange($scope.filterObject);
      }, 100);
      return day;
    }
  };

  $scope.optionsDatepickerEnd = {
    callback: function(day) {
      if (day.isBefore($scope.dateStart)) {
        $scope.dateStart = day;
      }

      // Days can only be 14 apart
      // Create a clone of the day, to avoid mutating the original
      if ($scope.onPeriodSelect) {
        var dayClone = moment(day);
        dayClone.subtract(14, 'days');
        if ($scope.dateStart.isBefore(dayClone)) {
          $scope.dateStart = dayClone;
        }
      }

      $timeout(function() {
        $scope.applyFiltersToDaterange($scope.filterObject);
      }, 100);
      return day;
    }
  };

  function filterStartDays(day) {
    if ($scope.showOneDatePicker) {
      $scope.startDayMinOne = undefined;
      $scope.endDayPlusOne = undefined;
    } else {
      $scope.startDayMinOne = moment(day, "YYYY/MM/DD").subtract(1, 'days');
      if ($scope.selectedPeriod) {
        $scope.updateSelectedStart(day);
      }
    }
  }

  function filterEndDays(day) {
    $scope.endDayPlusOne = moment(day, "YYYY/MM/DD").add(1, 'days');
    if ($scope.selectedPeriod) {
      $scope.updateSelectedEnd(day);
    }
  }
  //datepicker options for period start (modal)
  $scope.optionsDatepickerPeriodStart = {
    callback: function(day) {
      //if a period is not selected, show periods and make them unclickable + show warning`
      var tempDate = {};
      if (!$scope.isPeriodSelected) {
        filterStartDays(day);
        //if selected startday is behind end date, match end date with start date
        if (day.isAfter(moment($scope.dateEnd))) {
          $scope.dateEnd = day;
          $scope.updateSelectedEnd(day);
          filterEndDays(day);
        }

        tempDate = day;
        $scope.updateSelectedPeriod(null);
        //loop trough all periods
        _.forEach($scope.absencePeriods, function(period) {
          var formattedDay = moment(day).format('DD.MM.YYYY');
          //if selected start day is between a period start / end date, or is the same as a period startdate or is the same as a period enddate. Then show a message and make it unclickable.
          if (day.isBetween(period.startDate, period.endDate) || day.isSame(period.startDate) || day.isSame(period.endDate)) {
            $scope.message = "Er bevindt zich een periode op de geselecteerde dag.";
            $scope.updateSelectedStart($scope.dateStart);
            $scope.updateSelectedEnd($scope.dateStart);
            tempDate = $scope.dateStart;

            return false;
            //if the enddate is the same as period enddate or enddate is the same as period startdate or the enddate is between start and enddate or the enddate is behind a period enddate AND the selected day is before the period startdate. Then warn the user that the selected days overlap an already made period.
          } else if (moment($scope.dateEnd).isSame(period.endDate) || moment($scope.dateEnd).isSame(period.startDate) || moment($scope.dateEnd).isBetween(period.startDate, period.endDate) || moment($scope.dateEnd).isAfter(period.endDate) && day.isBefore(period.startDate)) {
            $scope.message = "Er bevindt zich een periode tussen de geselecteerde datums.";
            tempDate = day;
            $scope.dateStart = tempDate;
            $scope.dateEnd = tempDate;
            $scope.updateSelectedStart(tempDate);
            $scope.updateSelectedEnd(tempDate);
          } else {
            $scope.message = "";
          }
        });
        filterStartDays(tempDate);
        filterEndDays($scope.dateEnd);
        return tempDate;
      } else {
        //if a period is selected then do this
        tempDate = day;
        _.forEach($scope.absencePeriods, function(period) {
          var formattedDay = moment(day).format('DD.MM.YYYY');
          //if the period id does not match the selected period id, then it means that its another period.
          if (period.periodId !== $scope.periodSelected.periodId) {
            //if selected day is between a period or is the same as start / end date then warn the user.
            if (day.isBetween(period.startDate, period.endDate) || day.isSame(period.startDate) || day.isSame(period.endDate)) {
              $scope.message = "De geselecteerde datum bevindt zich in een andere periode! ";
              $scope.updateSelectedStart($scope.dateStart);
              $scope.updateSelectedEnd($scope.dateEnd);
              tempDate = $scope.dateStart;
            } else if (moment(day).isBefore(period.startDate) && $scope.dateEnd.isAfter(period.endDate)) {
              $scope.message = "Periodes mogen elkaar niet overlappen!";
              tempDate = $scope.dateStart;
            } else {
              $scope.message = "";
            }
          }
        });
        $scope.updateSelectedStart(tempDate);
        $scope.dateStart = tempDate;
        filterStartDays(tempDate);
        filterEndDays($scope.dateEnd);
        return tempDate;
      }
    }
  };

  $scope.optionsDatepickerPeriodEnd = {
    //same as optionsDatepickerPeriodStart but for the end date.
    // TODO maybe make one function of optionsDatepickerPeriodEnd and optionsDatepickerPeriodStart with parameters?
    callback: function(day) {
      var tempDate = {};
      if (!$scope.isPeriodSelected) {
        filterEndDays(day);
        if (day.isBefore(moment($scope.dateStart))) {
          $scope.dateStart = day;
          $scope.updateSelectedStart(day);
        }
        tempDate = day;
        _.forEach($scope.absencePeriods, function(period) {
          var formattedDay = moment(day).format('DD.MM.YYYY');
          if (day.isBetween(period.startDate, period.endDate) || day.isSame(period.startDate) || day.isSame(period.endDate)) {
            $scope.message = "Er bevindt zich een periode op de geselecteerde dag.";
            $scope.updateSelectedEnd($scope.dateEnd);
            tempDate = $scope.dateEnd;

            return false;
          } else if (moment($scope.dateStart).isSame(period.endDate) || moment($scope.dateStart).isSame(period.startDate) || moment($scope.dateStart).isBetween(period.startDate, period.endDate) || moment($scope.dateStart).isBefore(period.startDate) && day.isAfter(period.endDate)) {
            $scope.message = "";
            tempDate = day;
            $scope.dateStart = tempDate;
            $scope.dateEnd = tempDate;
            $scope.updateSelectedStart(tempDate);
            $scope.updateSelectedEnd(tempDate);
          } else {
            $scope.message = "";
          }

        });
        filterStartDays($scope.dateStart);
        filterEndDays(tempDate);
        return tempDate;
      } else {
        tempDate = day;
        _.forEach($scope.absencePeriods, function(period) {
          var formattedDay = moment(day).format('DD.MM.YYYY');
          if (period.periodId !== $scope.periodSelected.periodId) {
            if (day.isBetween(period.startDate, period.endDate) || day.isSame(period.startDate) || day.isSame(period.endDate)) {
              $scope.message = "De geselecteerde datum bevindt zich in een andere periode! ";
              $scope.updateSelectedStart($scope.dateStart);
              $scope.updateSelectedEnd($scope.dateEnd);
              tempDate = $scope.dateEnd;
            } else if (moment(day).isAfter(period.endDate) && $scope.dateStart.isBefore(period.startDate)) {
              $scope.message = "Periodes mogen elkaar niet overlappen!";
              tempDate = $scope.dateEnd;
            } else {
              $scope.message = "";
            }
          }
        });
        $scope.updateSelectedEnd(tempDate);
        $scope.dateEnd = tempDate;
        filterStartDays($scope.dateStart);
        filterEndDays($scope.dateEnd);
        return tempDate;
      }
    }
  };

  $scope.closeThis = function() {
    ngDialog.close('#dropdown-modal--datepicker');
  };

  $scope.updateDaterange = function() {
    // Daterange can't cross the start of a schoolyear
    // Create the first of september of the selected year.
    var firstOfSeptember = moment($scope.dateStart).month(8).date(1);
    var lastOfAugust = moment($scope.dateStart).month(7).date(31);
    if (
      firstOfSeptember.isAfter($scope.dateStart) &&
      lastOfAugust.isBefore($scope.dateEnd)
    ) {
      feedbackService.createFeedback(null, 'Fout', 'Een periode mag zich maar in één schooljaar bevinden.', 'error');
    } else if (periodService.useDateRangeArray) {
      // Check if daterangeArray is empty
      if (!periodService.dateRangeArray || periodService.dateRangeArray.length === 0) {
        feedbackService.createFeedback(null, 'Fout', 'U moet tenminste één datum selecteren.', 'error');
      } else {
        sendPeriodData();
      }
    } else {
      if ($scope.showOneDatePicker) {
        $scope.dateEnd = $scope.dateStart;
        sendPeriodData();
        filterStartDays($scope.dateStart);
      } else {
        //geen 2 weken limiet op desktop en tablet (feedback)
        /*
                var twoWeeksBeforeEnd = moment($scope.dateEnd);
                twoWeeksBeforeEnd.subtract(2, 'weeks');
                if ($scope.dateStart.isBefore(twoWeeksBeforeEnd)) {
                  toasterService.pop({
                    type: 'error',
                    title: 'Fout',
                    body: 'Een periode kan maximaal twee weken lang zijn',
                    showCloseButton: true
                  });
                }
                else {
                  sendPeriodData();
                }
                */
        sendPeriodData();
      }
    }
  };

  function sendPeriodData() {
    periodService.setPeriod($scope.dateStart, $scope.dateEnd, true);
    $scope.closeThis();
    $scope.onPeriodSelect();
  }

  // Datepicker selection options: List of options in dropdown that manipulate the datepickers.
  // ie: select next week, select today, etc...
  var daterangeSelectionOptions = [{
    'id': 1,
    'name': 'vandaag',
    'selectFunction': function() {
      var now = moment();
      return {
        'dateStart': now,
        'dateEnd': now
      };
    }
  }, {
    'id': 2,
    'name': 'vorige schooldag',
    'selectFunction': function() {
      var prevSchoolDay = moment();
      do {
        prevSchoolDay.subtract(1, 'days');
      } while (prevSchoolDay.day() === 6 || prevSchoolDay.day() === 0); // 6 = saturday, 0 = sunday
      return {
        'dateStart': prevSchoolDay,
        'dateEnd': prevSchoolDay
      };
    }
  }, {
    'id': 3,
    'name': 'volgende schooldag',
    'selectFunction': function() {
      var nextSchoolDay = moment();
      do {
        nextSchoolDay.add(1, 'days');
      } while (nextSchoolDay.day() === 6 || nextSchoolDay.day() === 0); // 6 = saturday, 0 = sunday
      return {
        'dateStart': nextSchoolDay,
        'dateEnd': nextSchoolDay
      };
    }
  }, {
    'id': 4,
    'name': 'vorige week',
    'selectFunction': function() {
      return {
        'dateStart': moment().day(-6), // last monday ( 1 - 7 )
        'dateEnd': moment().day(-2) // last friday ( 5 - 7 )
      };
    }
  }, {
    'id': 5,
    'name': 'deze week',
    'selectFunction': function() {
      return {
        'dateStart': moment().day(1), // this monday
        'dateEnd': moment().day(5) // this friday
      };
    }
  }, {
    'id': 6,
    'name': 'volgende week',
    'selectFunction': function() {
      return {
        'dateStart': moment().day(8), // next monday ( 1 + 7 )
        'dateEnd': moment().day(12) // next friday ( 5 + 7 )
      };
    }
  }];

  $scope.filterMarkers = [];
  // set this to false initially, meaning it will use the global periodService in
  // the below functions. Controllers which are nested deeper, like periodRegistrationController,
  // can overwrite $scope.filterObject with an object more suited to that particular modal.
  $scope.filterObject = periodService;

  function toggleFiltersToDateRange(add, days, filterObject) {
    if (add) {
      _.forEach(days, function(day) {
        filterObject.daterangeFilters.push(day);
      });
    } else {
      _.forEach(days, function(day) {
        _.pull(filterObject.daterangeFilters, day);
      });
    }
    _.uniq(filterObject.daterangeFilters);
    $scope.applyFiltersToDaterange(filterObject);
  }

  function addHolidayFilter(add, filterObject) {
    if (add) {
      filterObject.daterangeFilters.push('holidays');
    } else {
      _.remove(filterObject.daterangeFilters, function(filter) {
        return filter === 'holidays';
      });
    }
    $scope.applyFiltersToDaterange(filterObject);
  }

  $scope.applyFiltersToDaterange = function(filterObject) {
    var holidays = schoolService.getHolidaysAsMoment();
    filterObject.dateRangeArray = getDatesInPeriod();

    if (filterObject.daterangeFilters && filterObject.daterangeFilters.length > 0) {
      filterObject.useDateRangeArray = true;
      filterObject.reversedDateRangeArray = _.remove(filterObject.dateRangeArray, function(day) {
        return _.find(filterObject.daterangeFilters, function(filter) {
          if (filter === 'holidays') {
            var match = false;
            _.forEach(holidays, function(holiday) {
              if (day.moment.date() === holiday.date() && day.moment.month() === holiday.month() && day.moment.year() === holiday.year()) {
                match = true;
                return false; //stop loop prematurely
              }
            });
            return match;
          } else {
            return day.moment.day() === filter;
          }
        }) !== undefined;
      });
      $scope.selectedDatepickerFilter.name = 'opties*';
    } else {
      filterObject.useDateRangeArray = false;
      filterObject.dateRangeArray = [];
      filterObject.reversedDateRangeArray = [];
      $scope.selectedDatepickerFilter.name = 'opties';
    }

    if (filterObject.useDateRangeArray) {
      var tempFilterMarkerArray = [];
      _.forEach(filterObject.reversedDateRangeArray, function(date) {
        tempFilterMarkerArray.push({
          'day': date.moment,
          'marker': 'filtered'
        });
      });
      $scope.filterMarkers = tempFilterMarkerArray;
    } else {
      $scope.filterMarkers = [];
    }

    if (filterObject.registrationsCallback && typeof filterObject.registrationsCallback === 'function') {
      filterObject.registrationsCallback();
    }
  }

  var daterangeFilterOptions = [{
    'id': 1,
    'filters': [6, 0],
    'name': 'weekends niet',
    'callback': function(add, filterObject) {
      toggleFiltersToDateRange(add, [6, 0], filterObject);
    }
  }, {
    'id': 2,
    'filters': [1],
    'name': 'maandagen niet',
    'callback': function(add, filterObject) {
      toggleFiltersToDateRange(add, [1], filterObject);
    }
  }, {
    'id': 3,
    'filters': [2],
    'name': 'dinsdagen niet',
    'callback': function(add, filterObject) {
      toggleFiltersToDateRange(add, [2], filterObject);
    }
  }, {
    'id': 4,
    'filters': [3],
    'name': 'woensdagen niet',
    'callback': function(add, filterObject) {
      toggleFiltersToDateRange(add, [3], filterObject);
    }
  }, {
    'id': 5,
    'filters': [4],
    'name': 'donderdagen niet',
    'callback': function(add, filterObject) {
      toggleFiltersToDateRange(add, [4], filterObject);
    }
  }, {
    'id': 6,
    'filters': [5],
    'name': 'vrijdagen niet',
    'callback': function(add, filterObject) {
      toggleFiltersToDateRange(add, [5], filterObject);
    }
  }, {
    'id': 7,
    'filters': [],
    'name': 'lesvrije dagen niet',
    'callback': function(add, filterObject) {
      addHolidayFilter(add, filterObject);
    }
  }];

  $scope.selectedSelectionOption = {
    'name': 'selectie'
  };

  $scope.selectedDatepickerFilter = {
    'name': periodService.useDateRangeArray ? 'opties*' : 'opties'
  };

  var tempArray = [];
  if (periodService.useDateRangeArray) {
    _.forEach(periodService.daterangeFilters, function(filter) {
      if (filter === 'holidays') {
        tempArray.push(_.find(daterangeFilterOptions, function(filterOption) {
          return filterOption.id === 7;
        }));
      } else {
        _.forEach(daterangeFilterOptions, function(filterOption) {
          if (filterOption.filters.indexOf(filter) >= 0) {
            tempArray.push(filterOption);
          }
        });
      }
    });
  }
  $scope.selectedSelectionOptions = tempArray;

  $scope.updateDateOptions = function(filterOption, datepickerOption, filterObject) {
    var filteredDays = datepickerOption.callback(filterOption, filterObject);
  };

  var openedMiniDialog;
  $scope.openDateRangeSelectionOptions = function(id) {
    $('.ngdialog--dropdown-theme--list').remove();
    $scope.daterangeSelectionDropdownOpened = true;
    openedMiniDialog = ngDialog.open({
      template: 'partials/modals/dropdownListModal',
      appendTo: '#' + id,
      className: 'ngdialog--dropdown-theme--list',
      overlay: true,
      showClose: false,
      trapFocus: false,
      scope: $scope,
      data: {
        'list': daterangeSelectionOptions,
        'selected': 'selectie',
        'property': 'name',
        'id': id
      },
      preCloseCallback: function() {
        $scope.daterangeSelectionDropdownOpened = false;
      }
    });
  };

  $scope.openDateRangeOptions = function(id) {
    $('.ngdialog--dropdown-theme--list').remove();
    $scope.daterangeOptionDropdownOpened = true;
    openedMiniDialog = ngDialog.open({
      template: 'partials/modals/dropdownListModalOptions',
      appendTo: '#' + id,
      className: 'ngdialog--dropdown-theme--list no-overflow',
      overlay: true,
      showClose: false,
      trapFocus: false,
      scope: $scope,
      data: {
        'list': daterangeFilterOptions,
        'selected': 'opties',
        'property': 'name',
        'id': id
      },
      preCloseCallback: function() {
        $scope.daterangeOptionDropdownOpened = false;
      }
    });
  };

  $scope.select = function(item, property, id, sendImmediate) {
    openedMiniDialog.close();
    openedMiniDialog = undefined;

    var type = id.replace('dropdown-modal--', '');
    if (type === 'datepicker__selections') {
      $scope.selectedSelectionOption = item;
      if (typeof item.selectFunction === 'function') {
        var dateObject = item.selectFunction();
        $scope.dateStart = dateObject.dateStart;
        $scope.dateEnd = dateObject.dateEnd;
        $scope.applyFiltersToDaterange($scope.filterObject);
      }
    } else {
      $scope.selectedDatepickerFilter = item;
    }

    if (sendImmediate && type !== 'datepicker__selections') {
      $scope.updateDaterange();
    } else {
      if (item.id < 4) {
        $scope.updateDaterange();
      }
    }
  };

  $scope.safeApply = function(fn) {
    var phase = this.$root.$$phase;
    if (phase == '$apply' || phase == '$digest') {
      if (fn && (typeof(fn) === 'function')) {
        fn();
      }
    } else {
      this.$apply(fn);
    }
  };

  function getPeriodAsMoment() {
    return {
      startDate: moment($scope.dateStart, "YYYY/MM/DD"),
      endDate: moment($scope.dateEnd, "YYYY/MM/DD")
    };
  }

  function getDatesInPeriod() {
    var dates = [],
      periodMoment = getPeriodAsMoment(),
      newDate = periodMoment.startDate;
    if (periodMoment.startDate.isSame(periodMoment.endDate)) {
      return [createPeriodDate(periodMoment.startDate)];
    }

    function addNewDate(date) {
      dates.push(createPeriodDate(date));
    }

    addNewDate(periodMoment.startDate);
    do {
      newDate = moment(newDate).add(1, 'days');
      addNewDate(newDate);
    } while (newDate.isBetween(periodMoment.startDate, moment(periodMoment.endDate)));
    return dates;
  }

  function createPeriodDate(date, prependDisplay) {
    prependDisplay = (prependDisplay) ? prependDisplay + ' ' : '';
    var formattedDate = convertMoment(date);
    if (!moment.isMoment(date)) {
      date = moment(date, 'YYYY/MM/DD');
    }
    return {
      'formatted': formattedDate,
      'moment': date,
      'display': prependDisplay + ' ' + date.format("DD.MM.YYYY"),
      'day': date.format('ddd')
    };
  }

  function convertMoment(date) {
    if (moment.isMoment(date)) {
      date = date.format('YYYY/MM/DD');
    }
    return date;
  }
  filterStartDays($scope.dateStart);
  $scope.applyFiltersToDaterange($scope.filterObject);
  $scope.safeApply();
}];
