module.exports = ['$scope', '$http', 'authentication', 'spinnerService', '$state', '$rootScope', '$window', 'feedback', 'CONFIG',
  function($scope, $http, authenticationService, spinnerService, $state, $rootScope, $window, feedbackService, CONFIG) {
    "use strict";

    $scope.logoUrl = CONFIG.logoUrl;

    $scope.user = {
      username: ''
    };
    spinnerService.hide('mainSpinner');
    // spinnerService.show('mainSpinner');
    $scope.login = function(isValid, user) {
      spinnerService.show('mainSpinner');
      authenticationService.login(user.username, user.password);
      var gaData = {
        eventCategory: 'authentication',
        eventAction: 'login',
        eventLabel: 'User loging in'
      };
      $scope.$broadcast('send-ga-event', gaData);
    };
    $scope.$on('login_failed', function(event, data) {
      spinnerService.hide('mainSpinner');
      feedbackService.createFeedback(data.data, 'Aanmelden', 'Uw logingegevens zijn fout');
    });
    $scope.goToForgotPassword = function() {
      $scope.$broadcast('send-ga-pageview', '/forgotPassword');
      //$state.go('forgotPassword');
      window.location.href = '#/forgotPassword';
    };

    $scope.goToContact = function() {
      $scope.$broadcast('send-ga-pageview', '/contact');
    };
    $scope.goToLogin = function() {
      $scope.$broadcast('send-ga-pageview', '/login');
      //$state.go('login');
      window.location.href = '#/login';
      $("input").trigger("keypress");
    };
    $scope.requestPassword = function(isValid, user) {
      var gaData = {
        eventCategory: 'authentication',
        eventAction: 'Request password',
        eventLabel: 'User forgot password and requests it.'
      };
      $scope.$broadcast('send-ga-event', gaData);
      spinnerService.show('mainSpinner');
      authenticationService.forgotPassword(user.username).then(
        function(response) {
          spinnerService.hide('mainSpinner');
          if (response.data.success) {
            feedbackService.createFeedback(response.data, 'Wachtwoord vergeten', 'Er is een e-mail verstuurd naar de gebruiker');
          } else {
            feedbackService.createFeedback(response.data, 'Wachtwoord vergeten', 'Gebruikersnaam niet gevonden');
          }

        },
        function(error) {
          spinnerService.hide('mainSpinner');
          //feedbackService.createFeedback('error', response.data, 'Wachtwoord vergeten', 'Gebruikersnaam niet gevonden');
          console.log('error in loginControllers, forgot password: ', error);
        });
    };
    $scope.$on('send-ga-pageview', function(event, location) {
      $window.ga('send', 'pageview', {
        page: location
      });
    });
    $scope.$on('send-ga-event', function(event, data) {
      //[eventCategory], [eventAction], [eventLabel]
      $window.ga('send', 'event', data.eventCategory, data.eventAction, data.eventLabel);
    });
    // Several possible $scope variables here:
    // Error messages on incorrect form input
    // Error message on authentication error / access token timeout
  }
];
