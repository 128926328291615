module.exports = ['$scope', '$state', '$timeout', '$q', '$window', 'restore', 'storage', 'students',
  'schools', 'groups', 'period', 'absences', 'lodash', 'spinnerService', 'authentication', 'activities',
  'columns', 'api', 'ngDialog', '$rootScope', 'feedback', 'CONFIG',
  function($scope, $state, $timeout, $q, $window, restoreService, storageService, studentService,
    schoolService, groupService, periodService, absenceService, lodashService, spinnerService,
    authenticationService, activityService, columnService, apiService, ngDialog, $rootScope, feedbackService, CONFIG) {
    "use strict";
    spinnerService.hideAll();
    var _ = lodashService._;
    var ready = {
      dataLoaded: false,
      socketsInitialised: false
    };
    $scope.addableActivities = false;
    $scope.showDashboard = false;
    $scope.isNumbers = false;
    $scope.firstTimeSetup = true;
    $('html').removeClass('no-js');

    $scope.showLogout = CONFIG.showLogout;

    // https://coderwall.com/p/ngisma/safe-apply-in-angular-js
    // to prevent '$apply already in progress' error
    $scope.safeApply = function(fn) {
      var phase = (this.$root && this.$root.$$phase) ? this.$root.$$phase : null;
      if (phase == '$apply' || phase == '$digest') {
        if (fn && (typeof(fn) === 'function')) {
          fn();
        }
      } else {
        this.$apply(fn);
      }
    };
    $rootScope.$on('ngDialog.opened', function(e, $dialog) {
      $(':input').blur(function() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          $('#mainId').css('margin-top', '10000000px');
          $timeout(function() {
            $('#mainId').css('margin-top', '0px');
          }, 100);
        }
      });
    });
    $rootScope.$on('ngDialog.closing', function(e, $dialog) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('#mainId').css('margin-top', '10000000px');
        $timeout(function() {
          $('#mainId').css('margin-top', '0px');
        }, 100);
      }
    });
    // Change view (columnview, gridview, numberview)

    $scope.viewSrc = 'columns/columnView';
    $scope.changeView = function(type) {
      if (type === 'column') {
        switchToColumnView();
      } else if (type === 'grid') {
        $scope.isNumbers = false;
        switchToGridView();
      } else if (type === 'numbers') {
        $scope.isNumbers = true;
        $scope.viewSrc = 'numberOverview/numberOverview';
        $scope.$broadcast('send-ga-pageview', '/number-overview');
      } else if (!type) {
        $scope.isNumbers = false;
        // No type, so just switch views
        if ($scope.viewSrc === 'columns/columnView') {
          switchToGridView();
        } else {
          switchToColumnView();
        }
      } else {
        $scope.isNumbers = false;
        switchToColumnView();
      }
      $timeout(function() {
        $(window).trigger('resize');
      }, 100);
    };

    function switchToColumnView() {
      $scope.isNumbers = false;
      $scope.$broadcast('send-ga-pageview', '/columnView');
      $scope.viewSrc = 'columns/columnView';
      $scope.singleDayMode = false;
      periodService.setSinglePeriod(false);
      updateSelectedPeriod();
      $scope.safeApply();
    }

    function switchToGridView() {
      $scope.$broadcast('send-ga-pageview', '/gridView');
      $scope.viewSrc = 'grid/gridView';
      $scope.singleDayMode = true;
      periodService.setSinglePeriod(true);
      updateSelectedPeriod();
    }

    function checkWindowSize() {
      // NOTE max-width media queries in css include the size given so let's use <=
      if ($window.innerWidth <= 1000) {
        $scope.mobileView = true;
        if ($scope.viewSrc === 'columns/columnView') {
          switchToGridView();
        }

        if ($window.innerWidth < 700) {
          $scope.phoneView = true;
        } else {
          $scope.phoneView = false;
        }
      } else {
        $scope.mobileView = false;
        $scope.phoneView = false;
      }
      // If onInit is true, it's the first time calling this function.
      $scope.safeApply();

      // The filthiest of hackses.
      // In grid view, on orientation change, a large right margin was randomly computed
      // on the grid__container (flexbox). This 'fixes' that.
      var grid = $('#grid__container');
      $timeout(function() {
        grid.css('margin-right', '1px');
      }, 50);
      $timeout(function() {
        grid.css('margin-right', '0px');
      }, 100);
    }

    // Jquery window resize Event
    $(window).on("resize.doResize", _.debounce(checkWindowSize, 150));

    $scope.$on("$destroy", function() {
      $(window).off("resize.doResize"); //remove the handler added earlier
    });
    checkWindowSize();

    // Helper method to return an array for ng-repeats if you want to loop a set
    // amount of times.
    // When doing this, don't forget to add a track by in the ng-repeat to avoid
    // duplicates error, i.e.
    // <li ng-repeat="i in getNumber(15) track by $index">
    $scope.getNumber = function(num) {
      return new Array(num);
    };

    // TODO show loading spinner etc... on dashboard
    function deactivateDashboard() {
      console.log('deactivating dashboard');
    }

    /* ////////////// SEARCHBAR ////////// */
    $scope.searchText = '';

    $scope.searchTextCallback = function(searchText) {
      var gaData = {
        eventCategory: 'Search',
        eventAction: 'searching',
        eventLabel: 'Searching student'
      };
      $scope.$broadcast('send-ga-event', gaData);
      $scope.searchText = searchText;
    };

    $scope.emptyFilter = function(cb) {
      $scope.searchText = '';
      $scope.filteredResult = undefined;

      var selectedFilter = document.getElementById('dropdown--student-filter').getElementsByClassName('dropdown__text');
      var filterIcon = document.getElementById('dropdown--student-filter').getElementsByClassName('fa-filter');
      $(filterIcon[0]).removeClass("orange");
      selectedFilter[0].innerHTML = 'Filter';

      studentService.clearSelectedStudentFilter();
      updateSelectedStudentFilter();
      if (cb)  {
        cb();
      }
    };

    /* ////////////// API Service ////////// */
    apiService.setCallbacks({
      syncRegistrations: function( activities ) {
        //console.log("SOCKET INNVOKED: ", activities);
        if ($scope.registrations) {
          $scope.registrations = activityService.updateRegistration(activities);

          $timeout(function () {
            $rootScope.$broadcast('registrationSocketUpdate');
            $scope.safeApply();
          }, 300);
        }
      },

      syncAbsencesCallback: function (absences) {
        //console.log("syncAbsencesCallback invoked");
        if ($scope.absences && absences) {
          absenceService.createAbsenceStructure(absences, true); // last param indicates this is a websocket update
          $rootScope.$broadcast('absencesSocketUpdate');
          $scope.safeApply();
        }
      },

      lockActivitiesCallback: function (activities) {
        console.log("--- lockActivitiesCallback invoked ---");
        if ($scope.activities) {
          activityService.lockActivities(activities);
          $scope.safeApply();

        }
      },

      lockAbsenceListCallback: function (absences) {
        console.log("lockAbsenceListCallback invoked");
        if ($scope.absences && absences) {
          absenceService.mergeLockAbsenceData(absences);
          $scope.safeApply();
          var lockedAbsences = _.where(absences, {
            'lock': true
          });
          _.forEach(lockedAbsences, function (lockedAbsence) {
            var message = 'De lijst van ',
              messageEnd = ' is bevestigd',
              absenceTime = _.find($scope.absenceTimes, {
                'id': lockedAbsence.absenceTimeId
              });

            message += moment(lockedAbsence.date, 'YYYY-MM-DDTHH:mm:ssZ').format('DD.MM.YYYY');
            if (absenceTime) {
              if (absenceTime.time) {
                message += ' , ' + absenceTime.time;
              } else {
                message += ' ,  ' + absenceTime.dayPart;
              }
            }

            message += ' is bevestigd';
            feedbackService.createFeedback(null, 'Afwezigheden', message, 'success');
          });
        }
      }
    });

    // Get user name for logout button (and remove email part)
    var user = authenticationService.getUser();
    if (!user) {
      user = storageService.retrieveData('user');
    }
    if (user) {
      $scope.userName = user.slice(0, user.indexOf('@'));
    }

    /*////////////// SCHOOLS //////////////*/
    $scope.schools = [];
    $scope.selectedSchool = {
      name: '',
      fullName: ''
    };

    function updateSchools(resetGroups) {
      // Get schools data from the service (with a GET request)

      var schools = schoolService.getSchools();
      schoolService.updateSchools().then(
        function(response) {
          $scope.schools = response;
          // Compare new schools list with previous schools list.
          if ($scope.schools.length > 0) {
            if (!compareSchoolsList(schools)) {
              schoolService.setDefaultSchool(true);
              feedbackService.createFeedback(response.data, 'Info', 'Lijst met locaties kan gewijzigd zijn');
            }
            updateSelectedSchool(resetGroups);
          } else {
            schoolService.clearSchool();
            updateSelectedSchool(resetGroups);
            updateSelectedGroup(resetGroups);
            spinnerService.hide('mainSpinner');
          }
        },
        function(error) {
          console.log('error in dashboardController, schools: ', error);
          feedbackService.createFeedback(error.data, 'Fout', 'De gegevens konden niet worden geladen');

          $state.go('login');
          // Clear storage cookie so user isn't stuck in a loop of errors
          storageService.removeStorageCookie();
        }
      );
    }

    function compareSchoolsList(previousSchoolsList) {
      if (previousSchoolsList.length === 0) {
        // The schools list initially is empty. Return true because in that case,
        // We don't want to show a message and we want to get the selected school
        // from cookie.
        return true;
      }
      if (previousSchoolsList.length !== $scope.schools.length) {
        return false;
      }
      var result = true;
      _.forEach(previousSchoolsList, function(school) {
        var foundSchool = _.find($scope.schools, function(scopeSchool) {
          return scopeSchool.id === school.id;
        });
        if (!foundSchool) {
          result = false;
          return result;
        }
      });
      return result;
    }

    function updateSelectedSchool(resetGroups) {
      var selectedSchool = schoolService.getSelectedSchool();
      $scope.selectedSchool = selectedSchool;
      $scope.groups = [];
      $scope.selectedGroup = '';
      if (!_.isEmpty(selectedSchool.id)) {
        updateGroups(resetGroups, false);
      }
      var gaData = {
        eventCategory: 'School dropdown',
        eventAction: 'changing school',
        eventLabel: 'changed schoolselection'
      };
      $scope.$broadcast('send-ga-event', gaData);
    }

    /*////////////// GROUPS //////////////*/
    $scope.groups = [];
    $scope.selectedGroup = {
      groupname: ''
    };

    function updateGroups(reset, showNotification) {
      // Get groups data from the service (with a GET request)
      var groups = groupService.getGroups();
      groupService.updateGroups(reset).then(
        function(response) {
          $scope.groups = response;
          if (!compareGroupList(groups)) {
            groupService.setSelectedGroup($scope.groups[0], true);
            if (showNotification) {
              feedbackService.createFeedback(response.data, 'Info', 'Lijst met groepen kan gewijzigd zijn', 'info');
            }
          }
          updateSelectedGroup();
        },
        function(error) {
          console.log('error in dashboardController, groups: ', error);
          $state.go('login');
          feedbackService.createFeedback(error.data, 'Fout', 'De gegevens konden niet worden geladen', 'error');

          // Clear storage cookie so user isn't stuck in a loop of errors
          storageService.removeStorageCookie();
        }
      );
    }

    function compareGroupList(previousGroupList) {
      if (previousGroupList.length === 0) {
        // The schools list initially is empty. Return true because in that case,
        // We don't want to show a message and we want to get the selected school
        // from cookie.
        return true;
      }
      if (previousGroupList.length !== $scope.groups.length) {
        return false;
      }
      var result = true;
      _.forEach(previousGroupList, function(group) {
        var foundGroup = _.find($scope.groups, function(scopeGroup) {
          return scopeGroup.id === group.id;
        });
        if (!foundGroup) {
          result = false;
          return result;
        }
      });
      return result;
    }

    function updateSelectedGroup() {
      if ($scope.groups.length !== 0) {
        $scope.selectedGroup = groupService.getSelectedGroup();
        $scope.safeApply();
        updateDashboardData();

        $q(function(resolve, reject) {
          apiService.setSocketPromiseCallbacks({
            resolve: resolve,
            reject: reject
          });
          apiService.init();
        }).then(
          function() {
            console.log('connectToGroup invoked');
            apiService.setConnectionState(false);
            apiService.connectToGroup($scope.selectedGroup, periodService.getPeriodAsMoment());
          },
          function() {
            console.log("dashboard controller broadcasting error!");
            $scope.$broadcast('socket-connection-error');
          });
        var gaData = {
          eventCategory: 'Group dropdown',
          eventAction: 'changing group',
          eventLabel: 'changed groupselection'
        };
        $scope.$broadcast('send-ga-event', gaData);
      } else {
        $scope.studentList = [];
        columnService.reset();
        activityService.activities.length = 0;
        absenceService.absences.length = 0;
        feedbackService.createFeedback(null, 'Info', 'Er zijn geen groepen voor de geselecteerde locatie');

      }
    }

    /*////////////// PERIOD //////////////*/

    // When dashboard is in grid view mode, only a single datepicker can be
    // shown and the period must be one day.

    $scope.onPeriodSelect = function() {
      updateSelectedPeriod(true);
      // updateDashboardData();
    };

    var isInitialPageLoad = true;

    function updateSelectedPeriod(triggerSchoolUpdate) {
      var period = periodService.getPeriod();
      schoolService.updateHolidays().then(
        function(result) {
          $scope.holidays = result;
        },
        function(error) {
          console.log('error: ', error);
        }
      );

      if (_.isEmpty(period.startDate) && _.isEmpty(period.endDate)) {
        $scope.period = {};
      }
      else {
        if (period.startDate === period.endDate) {
          $scope.period = {
            parsedDate: moment(period.startDate, "YYYY/MM/DD").format('DD.MM.YYYY'),
            gridDate: period.startDate, // for grid data binding, expects YYYY/MM/DD format
            isPeriod: false // true if period, false if single day
          };
        }
        else {
          $scope.period = {
            parsedDate: moment(period.startDate, "YYYY/MM/DD").format('DD.MM.YYYY') + ' - ' + moment(period.endDate, "YYYY/MM/DD").format('DD.MM.YYYY'),
            isPeriod: true
          };
        }
      }
      if (!isInitialPageLoad && triggerSchoolUpdate) {
        updateSchools(false);
        setDashboardLoadingState();
      }
      else {
        isInitialPageLoad = false;
        apiService.resetGroupConnectionAttempts();
        apiService.setConnectionState(false);
        apiService.connectToGroup($scope.selectedGroup, periodService.getPeriodAsMoment());
      }
      var gaData = {
        eventCategory: 'Period dropdown',
        eventAction: 'changing period',
        eventLabel: 'changed current period'
      };
      $scope.$broadcast('send-ga-event', gaData);
    }

    /*////////////// DASHBOARD DATA //////////////*/

    // If a school, group and period have been selected, we have to update all the
    // data in the dashboard:
    // 1. Students
    // 2. Filters
    // 3. Absences, with times and codes
    // 4. Registrations/activities

    //added updateDashboard on the scope so other scopes can use it.
    $scope.$on('update-dashboard', function() {
      updateDashboardData();
    });

    $scope.$on('update-dashboard-from-error', function() {
      updateDashboardData(true);
    });

    function setDashboardLoadingState(hideSpinner) {
      $scope.dataLoaded = false;
      $scope.showDashboard = false;
      $scope.addActivityButtonTop = 0;
      $scope.activitiesLoaded = false;
      if (!hideSpinner) {
        spinnerService.show('mainSpinner');
      }
    }

    function updateDashboardData(hideSpinner) {
      setDashboardLoadingState(hideSpinner);

      var rights = groupService.getSelectedGroup().applications,
        absenceRight = _.find(rights, {
          'application': 'absences'
        }),
        registrationRight = _.find(rights, {
          'application': 'registrations'
        });


      $scope.absenceRight = (absenceRight && absenceRight.right) ? absenceRight.right : 0;
      $scope.registrationRight = (registrationRight && registrationRight.right) ? registrationRight.right : 0;
      if ($scope.registrationRight && $scope.registrationRight > 1) {
        activityService.getActivitiesForGroup().then(
          function(response) {
            var hasAddable = false;
            _.forEach(response.data.activities, function(activity) {
              if (activity.canAdd) {
                hasAddable = true;
                return false;
              }
            });
            $scope.addableActivities = hasAddable;
          },
          function(error) {
            console.log('error in getting addable activities, ', error);
          });
      }

      // Reset activities and absences
      // Do not use activities = [] here to reset the array! This will break the
      // reference, resulting in dashboard controller just having an empty array
      // to work with, uncoupled from the array in the service.
      activityService.activities.length = 0;
      absenceService.absences.length = 0;

      var promises = [updateStudents()];

      if ($scope.absenceRight > 1) {
        promises.push(updateAbsences());
      }

      if ($scope.registrationRight > 1) {
        promises.push(updateRegistrations());
      }

      if ($scope.registrationRight < 3) {
        $scope.hideAddActivityButton = true;
      }

      function renderSidebar() {
        // Insert the new content and calculate the add button height
        if (!hideSpinner) {
          spinnerService.show('mainSpinner');
        }
        $timeout(function() {
          $scope.activities = activityService.activities;
          $scope.addActivityButtonTop = $scope.activities.length * 96;

        }, 300);
        // animate the activity bar in and possible load grid data
        $timeout(function() {
          $scope.activitiesLoaded = true;
          $scope.dataLoaded = true;
          spinnerService.hide('mainSpinner');

          if ($scope.mobileView || $scope.viewSrc === 'grid/gridView') {
            // TODO Attempt to get correct activity here
            // activityService.setActivityActive(0, true, 'grid');
          }
        }, 350);
        // trigger a resize event to correctly position the add button. Bit of a hack,
        // but the alternative would either include costly $broadcast or a time-consuming
        // rewrite of the directive.
        $timeout(function() {
          $(window).trigger('resize');
        }, 400);
      }
      columnService.reset();
      $q.all(promises).then(
        function(response) {
          // Reset scope.
          $scope.studentList = undefined;
          $scope.studentFilters = undefined;
          $scope.absences = undefined;

          updateFilters().then(
            function(response) {
              $scope.studentFilters = response.filters;
            },
            function(error) {
              feedbackService.createFeedback(error.data, 'Fout', 'De filters konden niet worden geladen');

            }
          );

          _.forEach(response, function(responseObject) {
            if (responseObject.students) {
              $scope.studentList = responseObject.students;

            }
            if (responseObject.absences) {
              $scope.absences = responseObject.absences;
              activityService.createAbsenceActivity($scope.firstTimeSetup);
              $scope.firstTimeSetup = false;
              activityService.addAbsenceTimes();

              $scope.absenceCodes = absenceService.getAbsenceCodes();
              $scope.toggleableCodes = _.filter($scope.absenceCodes, function(absenceCode) {
                return absenceCode.toggle;
              });
            }
            if (responseObject.registrations) {
              $scope.registrations = responseObject.registrations;
            }
          });
          columnService.createColumnDetails();

          // Everything is present in the scope.
          // Finish the loading animation.
          spinnerService.hide('mainSpinner');
          $scope.showDashboard = true;

          // Show the student and activity column
          renderSidebar();

          $timeout(function() {
            $scope.safeApply();
            $scope.$broadcast('updated-dashboard-data');
            $scope.$broadcast('activity-updated');
            spinnerService.hideAll();
          }, 500);
        },
        function(error) {
          console.log('Error in updateDashboardData: ', error);
          spinnerService.hide('mainSpinner');
          feedbackService.createFeedback(error.data, 'Fout', 'Er kan geen verbinding gemaakt worden met de server');

          // Clear storage cookie so user isn't stuck in a loop of errors
          storageService.removeStorageCookie();
        }
      );
    }

    /*////////////// STUDENTS //////////////*/

    function updateStudents() {
      return $q(function(resolve, reject) {
        // console.log('DASHBOARD -- Updating students');
        studentService.updatePersons().then(
          function(response) {
            resolve({
              'students': response
            });
          },
          function(error) {
            console.log('error in dashboardController, students: ', error);
            reject(error);
          }
        );
      });
    }

    $scope.showStudentDetails = function(student) {
      ngDialog.open({
        template: 'partials/modals/personInfoModal',
        appendTo: '#modal',
        className: 'ngdialog-theme-default',
        overlay: true,
        showClose: true,
        scope: $scope,
        trapFocus: false,
        data: {
          "student": student
        }
      });

      $scope.$broadcast('send-ga-pageview', '/studentDetails');
    };

    /*////////////// FILTERS //////////////*/
    function updateFilters() {
      return $q(function(resolve, reject) {
        // console.log('DASHBOARD -- Updating filters');
        studentService.updateFilters().then(
          function(response) {
            // console.log('DASHBOARD -- Got filters: ', response);
            resolve({
              'filters': response
            });
          },
          function(error) {
            console.log('error in dashboardController, Filters: ', error);
            reject(error);
          }
        );
      });
    }

    /*////////////// ABSENCES //////////////*/

    function updateAbsences() {
      $scope.absenceCodes = undefined;
      $scope.absenceTimes = undefined;

      return $q(function(resolve, reject) {
        absenceService.updateAbsenceInfo().then(
          function(response) {
            $scope.absenceCodes = response.absenceCodes;
            $scope.absenceTimes = response.absenceTimes;

            absenceService.updateAbsences().then(
              function(response) {
                // Create a new structure for the absences on the scope. This structure
                // allows us to directly reference the absence code value in the html
                // interpolation.
                resolve({
                  'absences': absenceService.absences
                });
                $scope.absenceSubcolumnData = absenceService.absenceSubcolumnData;
              },
              function(error) {
                console.log('error in dashboardController, absences: ', error);
                reject(error);
              }
            );
          },
          function(error) {
            console.log('error in dashboardController, absenceInfo: ', error);
            reject(error);
          }
        );
      });
    }

    $scope.getToggleCodes = function(student, codes) {
      var rightCodes = [];
      _.forEach(codes, function(code) {
        _.forEach(code.types, function(type) {
          if (type === student.type) {
            rightCodes.push(code);
          }
        });
      });
      return rightCodes;
    };

    // Returns the absenceCode for a given id. If the absencecode is a subcode,
    // it returns the code of the parent absencecode, and sets the 'isSubcode'
    // value to true. (this must then display a small 'i' next to the button
    // in the view)
    function getAbsenceCode(id) {
      var result = {
        'code': '',
        'isSubcode': false
      };

      var found = false;
      _.forEach($scope.absenceCodes, function(absenceCode) {
        if (absenceCode.id === id) {
          result.code = absenceCode.code;
          found = true;
        } else {
          _.forEach(absenceCode.subCodes, function(subCode) {
            if (subCode.id === id) {
              result.code = absenceCode.code;
              result.isSubcode = true;
              found = true;
              return false; //breaks the inner forEach loop
            }
          });
        }
        if (found) {
          return false; //breaks the outer forEach loop
        }
      });

      return result;
    }

    /*////////////// ACTIVITIES //////////////*/
    function updateRegistrations() {
      return $q(function(resolve, reject) {
        activityService.updateRegistrations($scope.firstTimeSetup, $scope.viewSrc).then(
          function(response) {
            resolve({
              'registrations': activityService.registrations
            });
          },
          function(error) {
            console.log('error in dashboardController, registrations: ', error);
            reject(error);
          }
        );
      });
    }

    /*////////////// MENU //////////////*/

    $scope.openMobileMenu = false;

    $scope.toggleHamburgerState = function() {
      $scope.openMobileMenu = !$scope.openMobileMenu;
      $(window).trigger('resize');
      var gaData = {
        eventCategory: 'hamburgermenu',
        eventAction: 'Toggle hamburger',
        eventLabel: 'Toggled hamburgermenu'
      };
      $scope.$broadcast('send-ga-event', gaData);
    };

    /*////////////// DROPDOWNS //////////////*/
    $scope.$on('dropdownSelected', function(event, data) {
      if (data.type === 'schools') {
        updateSchools(true);
      } else if (data.type === 'groups') {
        updateSelectedGroup();
      } else if (data.type === 'student-filter') {
        updateSelectedStudentFilter();
      } else if (data.type === 'student-sort') {
        updateSelectedStudentSort();
      }
      event.stopPropagation();
    });

    $scope.filteredList = function(student) {
      if (!$scope.filteredResult || $scope.filteredResult.length === 0) {
        return true;
      }

      return _.find($scope.filteredResult, function(filterResult) {
        return student.id === filterResult.id;
      });

    };

    function updateSelectedStudentFilter() {
      var selectedStudentFilter = studentService.getSelectedStudentFilter();

      if (!selectedStudentFilter || _.isEmpty(selectedStudentFilter)) {
        $scope.selectedStudentFilter = {
          name: "Filter"
        };
      } else {
        $scope.selectedStudentFilter = selectedStudentFilter;
        var filterIcon = document.getElementById('dropdown--student-filter').getElementsByClassName('fa-filter');
        $(filterIcon[0]).addClass("orange");
        studentService.getSelectedFilterResult().then(
          function(response) {
            //reponse contains the result of the filter request to the API. In this situation it contains id's of the students that suite the
            //filter request.
            $scope.filteredResult = response;
            var gaData = {
              eventCategory: 'StudentFilter',
              eventAction: 'select',
              eventLabel: 'Filtered students on: ' + $scope.selectedStudentFilter.name
            };
            $scope.$broadcast('send-ga-event', gaData);
          },
          function(error) {
            console.log('error in dashboardController, filtered Students: ', error);
          });
      }
    }

    function updateSelectedStudentSort() {
      var selectedStudentSort = studentService.getSelectedStudentSort();
      if (!selectedStudentSort || _.isEmpty(selectedStudentSort)) {
        $scope.selectedStudentSort = {
          name: "Sorteer"
        };
      } else {
        if ($scope.selectedStudentSort.name === selectedStudentSort.name) {
          $scope.studentSortReverse = !$scope.studentSortReverse;
        } else {
          $scope.studentSortReverse = false;
        }
        $scope.selectedStudentSort = selectedStudentSort;
        var gaData = {
          eventCategory: 'StudentsSort',
          eventAction: 'select',
          eventLabel: 'Sorting students on: ' + $scope.selectedStudentSort.name
        };
        $scope.$broadcast('send-ga-event', gaData);
      }

    }

    /*////////////// INTERNET CONNECTION CHECK //////////////*/
    // IF socket returns error, do an internet connection check, if fails, let user know

    // If on safari browser, set a low limit of reconnections.
    var maxAmountOfReconnections = 10;
    var reconnectionCount = 0;
    var checkOnlineInterval;
    var intervalFunction = function() {
      if (navigator.onLine) {
        window.clearInterval(checkOnlineInterval);
        location.reload();
      }
      else {
        $rootScope.$broadcast('socket-connection-error');
      }
    };
    $scope.$on('socket-connection-error', function(event) {
      var online = navigator.onLine;
      if (!online && reconnectionCount < maxAmountOfReconnections) {
        reconnectionCount++;
        $scope.showConnectivityProblemSpinner("U heeft geen internet verbinding", true);
        window.clearInterval(checkOnlineInterval);
        checkOnlineInterval = setInterval(intervalFunction, 1000);
      }
      else {
        window.clearInterval(checkOnlineInterval);
        $scope.showConnectivityProblemSpinner("Er kan geen verbinding gemaakt worden met de server", true);
      }
    });
    $rootScope.$on('socket-reconnected', function(event) {
      spinnerService.hide('internetSpinner');
    });

    $scope.reload = function() {
      $state.reload();
    };

    $scope.showConnectivityProblemSpinner = function(errorMessage, showReloadBtn) {
      spinnerService.hideAll();
      spinnerService.hide('mainSpinner');
      spinnerService.show('internetSpinner');
      $scope.showReloadButton = showReloadBtn;
      $scope.errorMessage = errorMessage;
    };

    /*////////////// EDIT ACTIVITY //////////////*/
    // Change label of activty and delete (in dialog)

    $scope.checkAddableActivities = function() {
      if ($scope.registrationRight && $scope.registrationRight > 1 && $scope.addableActivities) {
        return true;
      }
      return false;
    };
    $scope.editActivity = function(activity) {
      ngDialog.open({
        template: 'partials/modals/editActivity',
        appendTo: '#modal',
        className: 'ngdialog-theme-default',
        overlay: true,
        showClose: true,
        scope: $scope,
        trapFocus: false,
        data: {
          'activity': activity
        }
      });
    };
    $scope.checkEditable = function(columnOrActivity) {
      if (!columnOrActivity || columnOrActivity.absenceColumn) {
        // absence column labels are not editable
        return false;
      }
      var editable = false;
      var tempActivity = null;
      if (columnOrActivity.hasOwnProperty('canEditLabel') && columnOrActivity.hasOwnProperty('canDelete')) {
        // check if it is an activity
        tempActivity = columnOrActivity;
      } else {
        // else find the activity
        _.forEach($scope.activities, function(activity) {
          if (activity && activity.hash) {
            if (activity.hash === columnOrActivity.hash) {
              tempActivity = activity;
              return false; // break the loop
            }
          }
        });
      }
      if (tempActivity) {
        if (tempActivity.canEditLabel || tempActivity.canDelete) {
          editable = true;
        }
      }
      return editable;
    };

    /*////////////// INITIALIZE //////////////*/
    // Check if group has been filled in by cookie
    var selectedGroup = groupService.getSelectedGroup(),
      reset = false;

    if (!selectedGroup || _.isEmpty(selectedGroup)) {
      reset = true;
    }


    $scope.$on('send-ga-pageview', function(event, location) {
      $window.ga('send', 'pageview', {
        page: location
      });
    });
    $scope.$on('send-ga-event', function(event, data) {
      $window.ga('send', 'event', data.eventCategory, data.eventAction, data.eventLabel);
    });

    updateSchools(reset);
    updateSelectedPeriod();
    updateSelectedStudentSort();
    updateSelectedStudentFilter();
  }
];
