module.exports = ['$scope', '$timeout', '$controller', 'activities', 'lodash', 'spinnerService', 'ngDialog', '$q', 'addresses', 'multiRegistration', 'period', 'feedback', '$rootScope',
  function($scope, $timeout, $controller, activityService, lodashService, spinnerService, ngDialog, $q, addressService, multiRegistrationService, periodService, feedbackService, $rootScope) {
    "use strict";

    var _ = lodashService._;

    $controller('dropdownDatepickerController', {
      $scope: $scope
    });

    $scope.page = 1;

    var period = periodService.getPeriodAsMoment();
    $scope.dateStart = period.startDate;
    $scope.dateEnd = period.endDate;

    $scope.data = {
      selectedPersons: [],
      articles: {}
    };
    $scope.selectAll = true;
    $scope.students = $scope.ngDialogData.students;

    $scope.activity = _.find(activityService.activities, function(activity) {
      return activity.id === $scope.ngDialogData.column.id;
    });

    _.forEach($scope.activity.articles, function(article) {
      if (!$scope.data.articles[article.id]) {
        $scope.data.articles[article.id] = {};
      }
      if (article.type === 'multi') {
        _.forEach(article.articleValues, function(articleValue) {
          if (articleValue.type === 'bool') {
            if (!$scope.data.articles[article.id][articleValue.id]) {
              $scope.data.articles[article.id][articleValue.id] = false;
            }
          } else if (articleValue.type === 'num') {
            if (!$scope.data.articles[article.id][articleValue.id]) {
              $scope.data.articles[article.id][articleValue.id] = 0;
            }
          }
        });
      } else {
        if (!$scope.data.articles[article.id][article.articleValues[0].id]) {
          $scope.data.articles[article.id][article.articleValues[0].id] = {};
        }
        if (article.type === 'num') {
          $scope.data.articles[article.id][article.articleValues[0].id] = 0;
        } else if (article.type === 'bool') {
          $scope.data.articles[article.id][article.articleValues[0].id] = false;
        } else if (article.type === 'time') {
          $scope.data.articles[article.id][article.articleValues[0].id].checked = false;
          $scope.data.articles[article.id][article.articleValues[0].id].date = null;
        }
      }
    });

    $scope.isValidPerson = function() {
      // TODO for some reason the person is valid check does not work instantly, look into this
      $timeout($scope.isValid, 10);
    };
    $scope.unselectAllStudents = function() {
      $scope.selectAll = !$scope.selectAll;
      if ($scope.selectAll) {
        checkAll();
      } else {
        $scope.data.selectedPersons = [];
        $scope.isValid();
      }
    };

    function checkRegistrationValueValid(value) {
      return typeof(value) === 'boolean' || value === 0 || multiRegistrationService.isValidRegistration(value);
    }
    $scope.isValid = function() {
      if ($scope.data.selectedPersons.length === 0) {
        $scope.valid = false;
      } else {
        $scope.valid = false;
        _.forEach($scope.data.articles, function(value, key) {
          if (typeof(value) === 'object') {
            _.forEach(value, function(valueProperty)  {
              if (checkRegistrationValueValid(valueProperty)) {
                $scope.valid = true;
                return false; // stop inner loop
              }
            });
            if ($scope.valid) {
              return false; // stop outer loop
            }
          } else {
            if (checkRegistrationValueValid(value)) {
              $scope.valid = true;
              return false; // stop outer loop
            }
          }
        });
      }
      return $scope.valid;
    };

    $scope.isValid(); // make sure the scope is validated when modal is opened

    $scope.checkTimePicker = function(articleId, articleValueId) {
      if (articleValueId) {
        $scope.data.articles[articleId][articleValueId].checked = true;
      } else {
        $scope.data.articles[articleId].checked = true;
      }
    };


    $scope.nextPage = function() {
      $scope.page += 1;
    };

    $scope.previousPage = function() {
      $scope.page -= 1;
    };

    // Returns an array of moment objects between dateStart and dateEnd, inclusive
    // TODO maybe add this to moment.prototype so we can use it throughout the app?
    function createPeriod(dateStart, dateEnd) {
      var dates = [],
        newDate = dateStart;

      if (dateStart.isSame(dateEnd)) {
        return [dateStart];
      } else {
        dates.push(newDate);
        do {
          newDate = moment(newDate).add(1, 'days');
          dates.push(newDate);
        } while (newDate.isBetween(dateStart, dateEnd) && !newDate.isSame(dateEnd));
        return dates;
      }
    }

    $scope.postMultiDatePersonRegistration = function() {
      if ($scope.isValid()) {
        // We need to create a http POST for each date in the period. Because we
        // also need to find the addresses of the students, we're delaying the
        // execution of these promises untill the user has chosen all addresses
        // when necessary in a series of dialogs.
        var promises = [],
          daysArray = createPeriod($scope.dateStart, $scope.dateEnd);

        if ($scope.useDateRangeArray)  {
          daysArray = $scope.dateRangeArray;
        }

        // Now, initialize the address selection popups to create the correct person data for each promise.
        var allZero = true;
        _.forEach($scope.data.articles, function(value, key) {
          _.forEach(value, function(item, itemKey) {
            if ((typeof(item) === 'boolean' && item) || (typeof(item) === 'object' && item.checked) || (typeof(parseInt(item)) === 'number' && item > 0)) {
              allZero = false;
              return false;
            } else if (typeof(item) === 'object') {
              _.forEach(item, function(objectvalue, objectkey) {
                if (objectvalue > 0) {
                  allZero = false;
                  return false;
                }
              });
            }
          });
        });
        var articles = multiRegistrationService.createArticleData($scope.activity, $scope.data.articles);

        if (allZero) {
          _.forEach(daysArray, function(day) {
            // Create a persons array containing the id and chosen address id for each person
            var persons = [];
            _.forEach($scope.data.selectedPersons, function(person) {
              var tempPerson = {
                "id": person.id,
                "addressId": null
              };
              persons.push(tempPerson);
            });

            promises.push(activityService.postMultiPersonRegistration(
              persons,
              $scope.activity.id,
              $scope.activity.name,
              moment(day).format('YYYY-MM-DDTHH:mm:ssZ'),
              articles
            ));
            spinnerService.show('modalSpinner');
            $q.all(promises).then(
              function(response) {
                var tempValue = '';
                _.forEach($scope.data.articles, function(value, key) {
                  _.forEach(value, function(item, key) {
                    tempValue = item;
                  });
                });
                feedbackService.createFeedback(response.data, 'Activiteiten', 'Registraties zijn bewaard', 'success');

                spinnerService.hide('modalSpinner');
                ngDialog.close();
              },
              function(error) {
                feedbackService.createFeedback(error.data, 'Activiteiten', 'Registraties konden niet worden bewaard', 'error');

                spinnerService.hide('modalSpinner');
                ngDialog.close();
                console.log('error posting multi-date/persons registration: ', error);
              }
            );
          });
        } else {
          addressService.startAddressDialogChain($scope.data.selectedPersons, daysArray, function(chosenAddresses) {
            _.forEach(daysArray, function(day) {
              // Create a persons array containing the id and chosen address id for each person
              var persons = [];
              _.forEach($scope.data.selectedPersons, function(person) {

                var address = _.find(chosenAddresses, function(addressData) {
                  return addressData.studentId === person.id && addressData.date.isSame(day);
                });
                var tempPerson = {};
                if (articles[0].articleValues.length === 0) {
                  tempPerson = {
                    "id": person.id,
                    "addressId": null
                  };
                } else {
                  tempPerson = {
                    "id": person.id,
                    "addressId": (address && address.id) ? address.id : person.addresses[0].id
                  };
                }

                persons.push(tempPerson);
              });

              promises.push(activityService.postMultiPersonRegistration(
                persons,
                $scope.activity.id,
                $scope.activity.name,
                moment(day).format('YYYY-MM-DDTHH:mm:ssZ'),
                articles
              ));
              spinnerService.show('modalSpinner');
              $q.all(promises).then(
                function(response) {
                  var tempValue = '';
                  _.forEach($scope.data.articles, function(value, key) {
                    _.forEach(value, function(item, key) {
                      tempValue = item;
                    });
                  });
                  feedbackService.createFeedback(response.data, 'Activiteiten', 'Registraties zijn bewaard');
                  spinnerService.hide('modalSpinner');
                  //$rootScope.$broadcast('update-dashboard');
                  ngDialog.close();
                },
                function(error) {
                  feedbackService.createFeedback(error.data, 'Activiteiten', 'Registraties konden niet worden bewaard', 'error');

                  spinnerService.hide('modalSpinner');
                  ngDialog.close();
                  console.log('error posting multi-date/persons registration: ', error);
                }
              );
            });
          });
        }
      }
    };

    $scope.useDateRangeArray = false;
    $scope.dateRangeArray = [];

    function createDefaultDateRangeArray() {
      var tempPeriodArray = createPeriod($scope.dateStart, $scope.dateEnd);
      var actualArray = [];
      _.forEach(tempPeriodArray, function(tempDate) {
        actualArray.push(periodService.createPeriodDate(tempDate));
      });
      return actualArray;
    }

    $scope.filterObject = {
      'registrationsCallback': function() {
        if (this.useDateRangeArray) {
          $scope.useDateRangeArray = true;
          $scope.dateRangeArray = _.map(this.dateRangeArray, function(dateRangeItem) {
            return dateRangeItem.moment;
          });
        } else {
          $scope.useDateRangeArray = false;
          $scope.dateRangeArray = '';
        }
      },
      'daterangeFilters': $scope.filterObject.daterangeFilters,
      'useDateRangeArray': $scope.filterObject.useDateRangeArray,
      'reversedDateRangeArray': $scope.filterObject.reversedDateRangeArray,
      'dateRangeArray': createDefaultDateRangeArray()
    };

    $scope.$watch('dateStart', function() {
      $scope.filterObject.dateRangeArray = createDefaultDateRangeArray();
    });

    $scope.$watch('dateEnd', function() {
      $scope.filterObject.dateRangeArray = createDefaultDateRangeArray();
    });

    function checkAll() {
      $scope.data.selectedPersons = angular.copy($scope.students);
    }
    checkAll();
    $scope.isValid();

    $scope.applyFiltersToDaterange($scope.filterObject);
  }
];
