module.exports = function () {
    return {
      restrict: "E",
      templateUrl: "partials/widgets/dropdownModal",
      scope: {
        placeholder: "@",
        image: "@",
        openedImage: "@",
        dropdownModal: "@",
        opened: "=",
        property: "=",
        selected: "@"
      }
    };
  };
