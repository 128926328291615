module.exports = ['$scope', '$state', 'storage', 'schools', 'spinnerService', '$timeout','feedback', 'CONFIG',
  function($scope, $state, storageService, schoolService, spinnerService, $timeout, feedbackService, CONFIG) {
    "use strict";

    $scope.logoUrl = CONFIG.logoUrl;

    $scope.schools = [];
    $scope.showEmptyMessage = false;
    $scope.showErrorMessage = false;

    spinnerService.hide('mainSpinner');
    loadingSchools();

    function loadingSchools() {
      if ($scope.schools.length === 0) {
        spinnerService.show('mainSpinner');
      }
      else if ($scope.schools.length === 1) {
        $scope.selectSchool($scope.schools[0]);
      }
      else {
        spinnerService.hide('mainSpinner');
        // check if there is a default school
        var defaultSchool = schoolService.getDefaultSchool();
        if(defaultSchool){
          $scope.selectSchool(defaultSchool);
        }
      }
    }

    // Call on the schoolService to send a GET request for schools data
    schoolService.updateSchools().then(
      function(response) {
        $scope.schools = response;
        loadingSchools();
      },
      function(error) {
        console.log('error in schoolSelectionController: ', error);
        feedbackService.createFeedback(error.data,  'Fout', 'De gegevens konden niet worden geladen');

        spinnerService.hide('mainSpinner');
        $state.go('login');
      }
    );

    $scope.selectSchool = function(school) {
      // Reset cookie (except for user name)
      storageService.remove(['schoolId', 'schoolName', 'schoolName2', 'groupId', 'groupName', 'applications']);
      // strip $$hashkey parameter from school, not necessary
      schoolService.setSelectedSchool({
        id: school.id,
        name: school.name,
        name2: school.name2,
        fullName: school.fullName
      }, true);

      $state.go('app');
    };
  }
];
