// Takes care of boilerplate require code for browserify
var app = angular.module('informatApp');

app.controller('newActivityController', require('./newActivityController'));
app.controller('registerAbsenceController', require('./registerAbsenceController'));
app.controller('dropdownDatepickerController', require('./dropdownDatepickerController'));
app.controller('multiAddPersonController', require('./multiAddPersonController'));
app.controller('periodAbsenceController', require('./periodAbsenceController'));
app.controller('studentInfoController', require('./studentInfoController'));
app.controller('addressChoiceController', require('./addressChoiceController'));
app.controller('periodRegistrationController', require('./periodRegistrationController'));
app.controller('duplicateActivityController', require('./duplicateActivityController'));
app.controller('multiDatePersonRegistrationController', require('./multiDatePersonRegistrationController'));
app.controller('editActivityController', require('./editActivityController'));
