module.exports = ['$rootScope', '$http', '$cookies', '$state', 'storage', '$q', '$window',
  function($rootScope, $http, $cookies, $state, storageService, $q, $window)  {
    var baseUrl = '/api';
    var user = '';

    function setUser(userName) {
      user = userName;
    }

    function getUser() {
      return user;
    }

    function login(username, password) {
      var req = {
        method: 'POST',
        url: baseUrl + '/token',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          username: username,
          password: password
        }
      };

      $http(req).then(
        function(token) {
          $cookies.putObject(
            'auth', {
              'token': token.data.access_token,
              'user': username,
              'tokenExpiry': moment().add(token.data.expires_in, 'seconds').subtract(60, 'seconds'),
              'refreshToken': token.data.refresh_token
            }
          );

          user = username;
          storageService.saveData({
            'user': username
          });

          $state.go('schoolSelection');
        },
        function(error) {
          console.log(error);
          $rootScope.$broadcast("login_failed", error);
        });
    }

    function forgotPassword(username) {
      return $q(function(resolve, reject) {
        var req = {
          method: 'POST',
          url: '/persons/forgotPassword',
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            userName: username
          }
        };
        $http(req).then(
          function(response) {
            resolve(response);
          },
          function(error) {
            console.log('error in authentication service: ', error);
            reject(error);
          });

      });
    }

    function logout() {
      user = '';
      storageService.clear();
      $window.location.reload();
    }

    function isAuthenticated() {
      var authCookie = $cookies.getObject('auth'),
        token = '',
        user = '';

      if (authCookie) {
        token = authCookie.token;
        user = authCookie.user;
      }

      if (!token || !user) {
        return false;
      } else {
        $rootScope.user = user;
        return true;
      }
    }

    return {
      login: login,
      logout: logout,
      isAuthenticated: isAuthenticated,
      getUser: getUser,
      setUser: setUser,
      forgotPassword: forgotPassword
    };
  }
];
