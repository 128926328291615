module.exports = ['$scope', 'ngDialog', 'schools', 'groups', 'period', 'lodash', 'CONFIG',
  function($scope, ngDialog, schoolService, groupService, periodService, lodashService, CONFIG) {
    "use strict";

    var _ = lodashService._;

    $scope.logoUrl = CONFIG.logoUrl;

    // Dropdowns are closed initially
    $scope.schoolDropdownOpened = false;
    $scope.groupDropdownOpened = false;
    $scope.daterangeDropdownOpened = false;
    $scope.daterangeSelectionDropdownOpened = false;

    function unselectAllDropdowns() {
      $scope.schoolDropdownOpened = false;
      $scope.groupDropdownOpened = false;
      $scope.daterangeDropdownOpened = false;
      $scope.daterangeSelectionDropdownOpened = false;
    }

    function openModalDropdown(id, listData, property) {
      $('.ngdialog--dropdown-theme--list').remove();
      $('.dropdown-list').remove();
      var selected = '';
      if (id === 'dropdown-modal--schools') {
        selected = $scope.selectedSchool;
      } else if (id === 'dropdown-modal--groups') {
        selected = {
          'id': $scope.selectedGroup.groupId,
          'name': $scope.selectedGroup.groupName
        };
      }

      ngDialog.open({
        template: 'partials/modals/dropdownListModal',
        appendTo: '#' + id,
        className: 'ngdialog--dropdown-theme--list',
        overlay: true,
        showClose: false,
        trapFocus: false,
        preCloseCallback: function() {
          unselectAllDropdowns();
        },
        scope: $scope,
        data: {
          'list': listData,
          'property': property,
          'id': id,
          'selected': selected
        }
      });
    }

    $scope.openSchoolSelectionPicker = function(id, listData, property) {
      ngDialog.close();
      if (!this.schoolDropdownOpened) {
        unselectAllDropdowns();
        openModalDropdown(id, listData, property);
        this.schoolDropdownOpened = true;
      } else {
        unselectAllDropdowns();
      }
    };

    $scope.openGroupSelectionPicker = function(id, listData, property) {
      ngDialog.close();
      if (!this.groupDropdownOpened) {
        unselectAllDropdowns();
        openModalDropdown(id, listData, property);
        this.groupDropdownOpened = true;
      } else {
        unselectAllDropdowns();
      }
    };

    $scope.openDateRangePicker = function(id) {
      var period = periodService.getPeriodAsMoment();
      if (!this.daterangeDropdownOpened) {
        unselectAllDropdowns();
        ngDialog.close();
        ngDialog.open({
          template: 'partials/modals/dateRangePickerModal',
          appendTo: '#' + id,
          className: 'ngdialog--dropdown-theme no-overflow dialog--width--600',
          overlay: true,
          showClose: false,
          trapFocus: false,
          data: {
            dateStart: period.startDate,
            dateEnd: period.endDate,
            onPeriodSelect: $scope.onPeriodSelect,
            showOneDatePicker: $scope.singleDayMode
          },
          preCloseCallback: function() {
            $scope.daterangeDropdownOpened = false;
          }
        });
        this.daterangeDropdownOpened = true;
      } else {
        ngDialog.close();
      }
    };

    $scope.select = function(item, property, id) {
      ngDialog.close('#' + id);

      var type = id.replace('dropdown-modal--', '');

      if (type === 'schools') {
        schoolService.setSelectedSchool(item, true);
      } else if (type === 'groups') {
        groupService.setSelectedGroup(item, true);
      }

      unselectAllDropdowns();
      $scope.$emit('dropdownSelected', {
        type: type
      });
    };

    // Listen for period changes to update the dropdown text of the date picker.
    // There's probably a better solution, but using this now due to time constraints.
    // TODO: find a solution internal to the directive!
    // periodService.listen(function() {
    //   var period = periodService.getPeriod();
    //   $scope.selectedDaterange = period.startDate + ' - ' + period.endDate;
    // });
  }
];
