// Intercepts all rest calls in order to add the authToken, if any.
// If the server returns an authentication error, redirects to the login page.

module.exports = ['$q', '$location', '$cookies', 'storage', '$injector', '$window', function($q, $location, $cookies, storageService, $injector, $window) {
  return {
    // 'request': function(config) {
    //   config.headers = config.headers || {};
    //   var token = storageService.getAuthToken();
    //
    //   if (config.url.indexOf('/api') === 0 && token) {
    //     config.headers.Authorization = 'BEARER ' + token;
    //     if (config.url.indexOf('?') !== -1) {
    //       config.url += '&cacheBuster=' + (new Date()).getTime();
    //     }
    //     else {
    //       config.url += '?cacheBuster=' + (new Date()).getTime();
    //     }
    //   }
    //   else if (token === false && config.url.indexOf('forgotPassword') === -1) {
    //     //$location.path('/login');
    //   }
    //
    //
    //   return config;
    // },
    'responseError': function(response) {
      if (response.status === 401 || response.status === 403) {
        // TODO when selecting "VTI" we get an unauthorized error, which in turn tries to re-authenticate, but api/token returns 400 bad request when sending refresh token
        // so there's probably something wrong with this one

        var refreshToken = storageService.getAuthRefreshToken();
        if (refreshToken) {
          var req = {
            method: 'POST',
            url: '/api/token',
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              grant_type: "refresh_token",
              refresh_token: refreshToken
            }
          };

          var $http = $injector.get("$http");
          $http(req).then(
            function(token) {
              $cookies.putObject(
                'auth', {
                  'token': token.data.access_token,
                  'tokenExpiry': moment().add(token.data.expires_in, 'seconds').subtract(60, 'seconds'),
                  'refreshToken': token.data.refresh_token
                }
              );
            },
            function(error) {
              // make sure everything is cleared
              storageService.clear();
              $location.path('/login');
              $window.location.reload();
            });
        } else {
          // make sure everything is cleared
          storageService.clear();
          $location.path('/login');
          $window.location.reload();
        }
      }
      return $q.reject(response);
    }
  };
}];
