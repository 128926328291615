module.exports = ['$http', '$q', 'storage', 'schools', 'lodash', 'period',
  function($http, $q, storageService, schoolService, lodashService, periodService)  {
    "use strict";

    var _ = lodashService._,
      groups = [],
      baseUrl = '/api',
      selectedGroup = {}; // {groupId, groupName}

    function getGroups() {
      return groups;
    }

    function getSelectedGroup() {
      return selectedGroup;
    }

    // Savedata should be false when the group is set from local storage/cookie,
    // and true whenever the user or the rest api sets the selected group.
    function setSelectedGroup(group, saveData) {
      if (group && group.id && group.name && group.applications) {
        // map the id and name to groupId and groupName keys, for clarity throughout the app
        group.groupId = group.id;
        group.groupName = group.name;
        group.applications = group.applications;
      }
      if (group && group.groupId && group.groupName && group.applications) {
        selectedGroup = group;
        if (saveData) {
          storageService.saveData({
            'groupId': group.groupId,
            'groupName': group.groupName,
            'applications': group.applications
          });
        }
      }
    }

    function clearSelectedGroup() {
      selectedGroup = {};
    }

    function resetSelectedGroup() {
      // Commented part just removed the group.
      // selectedGroup = {};
      // storageService.remove(['groupId', 'groupName']);
      setSelectedGroup(groups[0], true);
    }

    function updateGroups(reset) {
      ///api/groups/:groupId/activities/add
      var req = {
        method: 'GET',
        url: baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/groups?startdate=' + periodService.getPeriod().startDate + "&enddate=" + periodService.getPeriod().endDate,
        headers: {
          'Content-Type': 'application/json'
        }
      };

      return $q(function(resolve, reject) {
        $http(req).then(
          function(response) {
            groups = response.data.groups;
            if (reset) {
              resetSelectedGroup();
            }
            resolve(groups);
          },
          function(error) {
            console.log('error in groupService: ', error);
            reject(error);
          });
      });
    }

    return {
      getGroups: getGroups,
      updateGroups: updateGroups,
      getSelectedGroup: getSelectedGroup,
      setSelectedGroup: setSelectedGroup,
      resetSelectedGroup: resetSelectedGroup,
      clearSelectedGroup: clearSelectedGroup
    };
  }
];
