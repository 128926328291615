module.exports = ['$timeout', 'columns', function ($timeout, columnService) {
    return {
      restrict: "A",
      template: '<div id="horizontal-scrollbar-filler" style="width: {{scrollWidth}}px;"></div>',
      link: function (scope, element, attributes) {
        var activityColumnContainer = angular.element(document.getElementById('activityColumnContainer'));
        scope.scrollWidth = 0;
        function callback() {
          if (activityColumnContainer) {
            activityColumnContainer = angular.element(document.getElementById('activityColumnContainer'));
          }
          $timeout(function() {
            // TODO link this to loading of column!
            if (scope.viewSrc === 'columns/columnView') {
              scope.scrollWidth = activityColumnContainer[0].scrollWidth;
            }
          }, 1000);
        }
        columnService.subscribeCallback(callback);
      }
    };
  }];
