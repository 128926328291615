module.exports = ['toaster', '$timeout', '$rootScope',
  function(toasterService, $timeout, $rootScope)  {
    "use strict";

    var toasterObject = {
      showCloseButton: true,
      type: 'info'
    };

    function createFeedback(response, title, message, type) {
      if (type) {
        toasterObject.type = type;
      } else if (response && response.messageType) {
        toasterObject.type = response.messageType;
      }
      
      toasterObject.title = title;
      checkIfServerMessage(response, message);
      var myNode = $('#toast-container');
      if (myNode.children().length > 1) {
        myNode.children(':not(first-child)').remove();
      }
      toasterService.pop(toasterObject);
    }

    function checkIfServerMessage(response, message) {
      if (response && response.userMessage) {
        toasterObject.body = response.userMessage;
      } else {
        toasterObject.body = message;
      }
    }

    return {
      createFeedback: createFeedback
    };
  }
];
