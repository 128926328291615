module.exports = ['$scope', 'spinnerService', '$timeout', 'students', 'CONFIG','feedback',
  function($scope, spinnerService, $timeout, studentService, CONFIG, feedbackService) {
    "use strict";
    $scope.showInfo = false;
    $scope.student = $scope.ngDialogData.student;
    // NOTE TODO FIXME api base path recieved by informat ends with / and student.photo starts with a / double slashes in URLs are not good
    $scope.imageBaseURL = CONFIG.apiBasePath;
    spinnerService.show('studentInfoSpinner');

    studentService.getPersonDetails($scope.student.id).then(
      function(response) {
        $scope.birthDate = moment(response.data.birthDate).format('DD.MM.YYYY');
        $scope.emails = response.data.emails;
        $scope.relations = response.data.relations;
        $scope.telephones = response.data.telephones;
        $scope.addresses = response.data.addresses;

        spinnerService.hide('studentInfoSpinner');
        $scope.showInfo = true;
      },
      function(error)  {
        feedbackService.createFeedback(error.data,  'Fout', 'De gegevens konden niet worden geladen');

        spinnerService.hide('studentInfoSpinner');
      }
    );
  }
];
