// Controller for the custom directive optionbuttonDirective
module.exports = ['$scope', 'lodash', 'spinnerService', '$timeout', 'students', 'absences', '$controller', 'ngDialog', 'feedback',
  function($scope, lodashService, spinnerService, $timeout, studentService, absenceService, $controller, ngDialog, feedbackService) {
    "use strict";
    $controller('dropdownDatepickerController', {
      $scope: $scope
    }); //This works

    var _ = lodashService._;

    $scope.collapsed = false;

    $scope.message = "Selecteer start- en einddatum";
    $scope.student = $scope.ngDialogData.student;
    $scope.showSubOptions = false;
    $scope.absenceSubOptions = {};
    $scope.periodLoaded = false;
    $scope.isPeriodSelected = false;
    $scope.selectedPeriod = {
      "startDate": '',
      "endDate": ''
    };
    $scope.startAbsenceTimeVM = false;
    $scope.startAbsenceTimeNM = false;
    $scope.endAbsenceTimeVM = false;
    $scope.endAbsenceTimeNM = false;
    $scope.markers = [];
    $scope.absenceTimes = absenceService.getAbsenceTimes();
    $scope.data = {
      "selectedReason": null,
      "selectedSubOption": null,
      "comment": '',
      "student": '',
      "period": {
        'startDate': moment(),
        'endDate': moment()
      },
      "absenceTime": {
        "startDay": {
          "timeId": ''
        },
        "endDay": {
          "timeId": ''
        }
      },
      voormiddag: true,
      namiddag: true,
      ontkoppelen: false,
      bewijs: false
    };

    $scope.periodSelected = {};
    var test = 'before promise';
    var tempAbsenceCodes = [];
    _.forEach($scope.ngDialogData.absenceCodes, function(absenceCode) {
      tempAbsenceCodes.push(absenceCode);
    });
    $scope.absenceCodes = tempAbsenceCodes;
    $scope.data.student = $scope.student;
    resetOptions();
    $scope.periode = 0;
    $scope.colors = ['period1', 'period2', 'period3', 'period4'];

    $scope.spinnerLoaded = function(mySpinner) {
      console.log("spinner loaded: ", mySpinner);
    };

    $scope.loadPeriods = function() {
      //spinnerService.show('periodSpinner');
      absenceService.getAbsencePeriods($scope.student).then(
        function(response) {
          $scope.absencePeriods = response.data.periods;
          _.forEach($scope.absencePeriods, function(period) {
            _.forEach($scope.absenceCodes, function(absenceCode) {
              if (absenceCode.id === period.absenceCodeId) {
                period.absenceCodeName = absenceCode.description;
                period.absenceCodeCode = absenceCode.code;
              } else {
                _.forEach(absenceCode.subCodes, function(subCode) {
                  if (subCode.id === period.absenceCodeId) {
                    period.absenceSubCodeName = subCode.description;
                    period.absenceSubCode = subCode.code;
                    period.absenceCodeName = absenceCode.description;
                    period.absenceCodeCode = absenceCode.code;
                  }
                });
              }
            });
          });
          loadPeriodsInCalendar();
          //spinnerService.hide('periodSpinner');

          //NOTE fixes IE where ng-class does not get updated on mightydatepicker calendars.
            $timeout(function () {
              $scope.periodLoaded = true;
              $scope.safeApply();
            }, 200);
        },
        function(error) {
          console.log('error in periodAbsenceController, added Period: ', error);
        });
    };
    $scope.collapse = function() {
      $scope.collapsed = !$scope.collapsed;
    };



    var filteredArr = [];
    _.forEach($scope.absenceCodes, function(reason) {
      //bool to check if student type is in the array of the absence reason types.
      var validReason = true;
      //check if the unique type is also available in the reason type array.
      validReason = _.includes(reason.types, $scope.ngDialogData.student.type);
      //if the array of reason types contains all the unique student types, add the reason to the filtered array.
      if (validReason) {
        if (reason.period) {
          filteredArr.push(reason);
        }
      }
    });

    function loadPeriodsInCalendar() {
      $scope.markers = [];
      _.forEach($scope.absencePeriods, function(period) {
        $scope.number++;
        _.forEach(getDatesInPeriod(period.startDate, period.endDate), function(periodDate) {
          var tempMarker = {
            day: periodDate.moment,
            marker: moment(period.startDate).format('DD.MM.YYYY') + ' - ' + moment(period.endDate).format('DD.MM.YYYY')
          };
          $scope.markers.push(tempMarker);
        });
      });

    }

    function getPeriodAsMoment() {
      return {
        startDate: moment(period.startDate, "YYYY/MM/DD"),
        endDate: moment(period.endDate, "YYYY/MM/DD")
      };
    }

    function getDatesInPeriod(startDate, endDate) {
      var dates = [],
        periodMoment = {
          startDate: startDate,
          endDate: endDate
        },
        newDate = moment(periodMoment.startDate);

      function addNewDate(date) {
        var formattedDate = convertMoment(date);
        dates.push({
          'formatted': formattedDate,
          'moment': date
        });
      }

      addNewDate(newDate);
      while (newDate.isBefore(periodMoment.endDate)) {
        newDate = moment(newDate).add(1, 'days');
        addNewDate(newDate);
      }
      return dates;
    }

    function convertMoment(date) {
      if (moment.isMoment(date)) {
        date = date.format('YYYY/MM/DD');
      }
      return date;
    }

    $scope.absenceCodes = filteredArr;
    $scope.resetDialog = function(mode) {
      $scope.message = '';
      if (mode === "hard") {

        resetPeriodSelection();
        _.each($scope.absenceCodes, function(absenceCode) {
          absenceCode.isSelected = false;
        });
        _.each($scope.absenceSubOptions, function(subCode) {
          subCode.isSelected = false;
        });
        $scope.data.comment = '';
        $scope.data.selectedReason = null;
        $scope.data.selectedSubOption = null;
        $scope.data.period = {
          'startDate': moment(),
          'endDate': moment()
        };
        $scope.data.voormiddag = false;
        $scope.data.namiddag = false;
        $scope.data.ontkoppelen = false;
        $scope.data.bewijs = false;
        $scope.periodSelected = null;
        $scope.dateStart = moment();
        $scope.updateSelectedStart($scope.dateStart);
        $scope.dateEnd = moment();
        $scope.updateSelectedEnd($scope.dateEnd);
        resetEndAbsenceTimeButtons();
        resetStartAbsenceTimeButtons();
        filterStartDays(moment());
        filterEndDays(moment());
      } else {
        _.each($scope.absenceCodes, function(absenceCode) {
          absenceCode.isSelected = false;
        });
        _.each($scope.absenceSubOptions, function(subCode) {
          subCode.isSelected = false;
        });
        $scope.data.comment = '';
        $scope.data.selectedReason = null;
        $scope.data.selectedSubOption = null;
        $scope.data.period = {};
        $scope.data.voormiddag = false;
        $scope.data.namiddag = false;
        $scope.data.ontkoppelen = false;
        $scope.data.bewijs = false;
        $scope.dateStart = moment();
        $scope.updateSelectedStart($scope.dateStart);
        $scope.dateEnd = moment();
        $scope.updateSelectedEnd($scope.dateEnd);
        resetEndAbsenceTimeButtons();
        resetStartAbsenceTimeButtons();
      }
    };

    function resetStartAbsenceTimeButtons() {
      $scope.startAbsenceTimeVM = false;
      $scope.startAbsenceTimeNM = false;
    }

    function resetEndAbsenceTimeButtons() {
      $scope.endAbsenceTimeVM = false;
      $scope.endAbsenceTimeNM = false;
    }

    $scope.updateSelectedStart = function(start) {
      $scope.data.period.startDate = start;
    };
    $scope.updateSelectedEnd = function(end) {
      $scope.data.period.endDate = end;
    };
    $scope.updateSelectedPeriod = function(period) {
      if (period !== null) {
        $scope.data.period.id = period.periodId;
      } else {
        $scope.data.period.id = null;
      }
    };
    $scope.checkIfSubOptions = function(absenceCode) {
      _.each($scope.absenceCodes, function(absence) {
        absence.isSelected = false;
      });
      $scope.data.selectedReason = absenceCode;
      absenceCode.isSelected = true;
      $scope.showSubOptions = true;
      $scope.absenceSubOptions = absenceCode.subCodes;
      resetSubOption();
    };
    $scope.selectSubOption = function(subOption) {
      resetSubOption();
      $scope.data.selectedSubOption = subOption;
      subOption.isSelected = true;
    };
    $scope.addPeriod = function() {
      if ($scope.checkIfValid()) {
        ///api/groups/:groupId/absences/periods/:personId
        //personId, startDate, endDate, startAbsTimeId, endAbsTimeId, absenceCodeId, comment
        spinnerService.show('modalSpinner');
        var absenceId = $scope.data.selectedReason.id;
        if ($scope.data.selectedSubOption !== null) {
          absenceId = $scope.data.selectedSubOption.id;
        }
        if ($scope.periodSelected) {
          $scope.data.period.periodId = $scope.periodSelected.periodId;
        }

        absenceService.postAbsencePeriodData($scope.data.student.id, $scope.data.period, $scope.data.absenceTime.startDay.timeId, $scope.data.absenceTime.endDay.timeId, absenceId, $scope.data.comment, $scope.data.voormiddag, $scope.data.namiddag, $scope.data.ontkoppelen, $scope.data.bewijs).then(
          function(response) {

            spinnerService.hide('modalSpinner');
            if ($scope.periodSelected) {
              feedbackService.createFeedback(response.data, 'Afwezigheden', 'De periode is gewijzigd');

            } else {
              feedbackService.createFeedback(response.data, 'Afwezigheden', 'De periode is toegevoegd');

              $scope.closeThisDialog();
            }
            $scope.resetDialog('hard');
            $scope.loadPeriods();
            resetPeriodSelection();
          },
          function(error) {
            spinnerService.hide('modalSpinner');
            feedbackService.createFeedback(error.data, 'Afwezigheden', 'De periode kon niet worden toegevoegd');
            console.log('error in periodAbsenceController, added Period: ', error);
          });
      }
    };

    function resetOptions() {
      $scope.absenceCodes = _.each($scope.absenceCodes, function(item) {
        item.isSelected = false;
        _.each(item.subCodes, function(subCode) {
          subCode.isSelected = false;
        });
      });
    }

    function resetSubOption() {
      _.each($scope.absenceSubOptions, function(item) {
        item.isSelected = false;
      });
    }

    $scope.setStartAbsTime = function(timeInDay, absenceTime) {
      resetStartAbsenceTimeButtons();
      if (timeInDay === 'VM') {
        $scope.startAbsenceTimeVM = true;
        $scope.data.absenceTime.startDay.timeId = 1;
      } else {
        $scope.startAbsenceTimeNM = true;
        $scope.data.absenceTime.startDay.timeId = 6;
      }
    };
    $scope.setEndAbsTime = function(timeInDay, absenceTime) {
      resetEndAbsenceTimeButtons();
      if (timeInDay === 'VM') {
        $scope.endAbsenceTimeVM = true;
        $scope.data.absenceTime.endDay.timeId = 1;
      } else {
        $scope.endAbsenceTimeNM = true;
        $scope.data.absenceTime.endDay.timeId = 6;
      }
    };

    // set first day's absTime to VM and second day's to NM
    $scope.setStartAbsTime('VM', $scope.absenceTimes[0]);
    $scope.setEndAbsTime('NM', $scope.absenceTimes[1]);

    $scope.formatDate = function(date) {
      return moment(date).format('DD.MM.YYYY');
    };
    $scope.deletePeriod = function(period, studentId) {
      spinnerService.show('modalSpinner');
      absenceService.removeAbsencePeriod(period.periodId, studentId).then(
        function(response) {
          spinnerService.hide('modalSpinner');
          feedbackService.createFeedback(response.data, 'Afwezigheden', 'De periode is verwijderd');

          $scope.loadPeriods();
          resetPeriodSelection();
          loadPeriodsInCalendar();
          filterStartDays(moment());
          filterEndDays(moment());
        },
        function(error) {
          spinnerService.hide('modalSpinner');
          feedbackService.createFeedback(error.data, 'Afwezigheden', 'De periode kon niet worden verwijderd');

          console.log('error in periodAbsenceController, deleted Period: ', error);
        });
    };
    $scope.safeApply = function(fn) {
      var phase = (this.$root && this.$root.$$phase) ? this.$root.$$phase : null;
      if (phase == '$apply' || phase == '$digest') {
        if (fn && (typeof(fn) === 'function')) {
          fn();
        }
      } else {
        this.$apply(fn);
      }
    };

    function resetPeriodSelection() {
      _.forEach($scope.absencePeriods, function(abPeriod) {
        abPeriod.isSelected = false;
      });
      $scope.periodSelected = null;
      $scope.isPeriodSelected = false;
    }

    function filterStartDays(day) {
      $scope.startDayMinOne = moment(day, "YYYY/MM/DD").subtract(1, 'days');
      if ($scope.selectedPeriod) {
        $scope.updateSelectedStart(day);
      }
    }

    function filterEndDays(day) {
      $scope.endDayPlusOne = moment(day, "YYYY/MM/DD").add(1, 'days');
      if ($scope.selectedPeriod) {
        $scope.updateSelectedEnd(day);
      }
    }

    $scope.selectPeriod = function(period) {
      $scope.resetDialog('soft');
      if (!period.isSelected) {
        _.forEach($scope.absencePeriods, function(abPeriod) {
          abPeriod.isSelected = false;
        });
      }
      period.isSelected = !period.isSelected;

      if (period.isSelected) {
        $scope.data.comment = period.comment;
        $scope.periodSelected = period;
        $scope.isPeriodSelected = true;
        $scope.dateStart = moment(period.startDate);
        $scope.updateSelectedStart($scope.dateStart);
        $scope.dateEnd = moment(period.endDate);
        $scope.updateSelectedEnd($scope.dateEnd);

        filterStartDays($scope.dateStart);
        filterEndDays($scope.dateEnd);

        var selectedReason = null;
        var selectedSubOption = null;
        _.forEach($scope.absenceCodes, function(abcode) {
          if (abcode.id == period.absenceCodeId) {
            abcode.isSelected = true;
            selectedReason = abcode;
          }
          _.forEach(abcode.subCodes, function(subOption) {
            if (subOption.id == period.absenceCodeId) {
              abcode.isSelected = true;
              subOption.isSelected = true;
              selectedSubOption = subOption;
              selectedReason = abcode;
            }
          });
        });

        $scope.data.selectedReason = selectedReason;
        $scope.data.selectedSubOption = selectedSubOption;
        if ($scope.data.selectedReason) {
          $scope.checkIfSubOptions($scope.data.selectedReason);
        }
        if (selectedSubOption) {
          selectedSubOption.isSelected = true;
        }
        var startAbsenceTime = _.find($scope.absenceTimes, function(absencetime) {
          return absencetime.id === period.startAbsTimeId;
        });
        var endAbsenceTime = _.find($scope.absenceTimes, function(absencetime) {
          return absencetime.id === period.endAbsTimeId;
        });

        if (startAbsenceTime) {
          $scope.setStartAbsTime(startAbsenceTime.dayPart, startAbsenceTime);
        }
        if (endAbsenceTime) {
          $scope.setEndAbsTime(endAbsenceTime.dayPart, endAbsenceTime);
        }
        if (period.vm) {
          $scope.data.voormiddag = true;
        }
        if (period.nm) {

          $scope.data.namiddag = true;
        }
        if (period.ontkoppeld) {
          $scope.data.ontkoppelen = true;
        }
        if (period.proofDoc) {
          $scope.data.bewijs = true;
        }
        $scope.safeApply();
      } else {

        $scope.periodSelected = {};
        $scope.resetDialog('hard');
      }
    };

    $scope.checkIfValid = function() {
      if ($scope.ngDialogData.hasRight < 3) {
        return false;
      }
      if ($scope.data.selectedReason === null || $scope.data.period.startDate === undefined || $scope.data.period.endDate === undefined || $scope.data.absenceTime.endDay.timeId === '' || $scope.data.absenceTime.startDay.timeId === '' || ($scope.data.voormiddag === false && $scope.data.namiddag === false)) {
        return false;

      } else {
        return true;
      }
    };

    $scope.loadPeriods();
    resetPeriodSelection();

    // Overwrite the select function of the dropdown in dropdownDatepickerController
    $scope.select = function(item, property, id, sendImmediate) {
      var openedDialog = ngDialog.getOpenDialogs();
      ngDialog.close(openedDialog[openedDialog.length - 1]); // close last opened dialog, this one

      var type = id.replace('dropdown-modal--', '');
      if (type === 'datepicker__selections') {
        $scope.selectedSelectionOption = item;
        if (typeof item.selectFunction === 'function') {
          var dateObject = item.selectFunction();
          $scope.dateStart = dateObject.dateStart;
          $scope.dateEnd = dateObject.dateEnd;
        }
      }
    };
    $scope.checkIfValid();
  }
];
