module.exports = ['$scope', '$window', 'lodash','$timeout', function ($scope, $window, lodashService, $timeout) {
    var footer = angular.element(document.querySelector('footer'))[0],
            columnFooterWrapper = angular.element(document.querySelector('#column-footers-wrapper'))[0],
            columnView = angular.element(document.querySelector('#columnView')),
            _ = lodashService._,
            offsetHeight = 0;

    $scope.getNumber = function (num) {
      return new Array(num);
    };

    $scope.columnFooterOffset = '1000px';
    $scope.displayColumnFooter = 'block';

    function calculateFooterOffset() {
      offsetHeight = footer.offsetTop - (footer.offsetHeight) - columnFooterWrapper.offsetHeight;
      // Vertical offset of the element is the footers vertical offset minus
      // two times the footers height (i.e. footer's height + header's height)
      // minus the height of the element itself.
      $scope.columnFooterOffset = (offsetHeight + columnView[0].scrollTop - 60) + 'px';
      $scope.safeApply();
      columnFooterWrapper.style.display = 'block';
      columnFooterWrapper.style.opacity = '0';
      setTimeout(function () {
        columnFooterWrapper.style.opacity = '1';
      }, 20);
    }
    calculateFooterOffset();

    angular.element($window).bind('resize', _.debounce(calculateFooterOffset,150));

    columnView.bind('scroll', _.debounce(function () {
      columnFooterWrapper.style.display = 'none';
    }, 300, {'leading': true, 'trailing': false}));

    columnView.bind('scroll', _.debounce(function () {
      calculateFooterOffset();
    }, 300));

  }];
