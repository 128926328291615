module.exports = function () {
  "use strict";

  var _ = require('lodash');

/*
 * From: http://www.ericfeminella.com/blog/2012/08/18/determining-if-an-object-is-empty-with-underscore-lo-dash/
 *
 * Provides a convenience extension to _.isEmpty which allows for
 * determining an object as being empty based on either the default
 * implementation or by evaluating each property to undefined, in
 * which case the object is considered empty.
 */
  _.mixin( function() {
  	// reference the original implementation
  	var _isEmpty = _.isEmpty;
  	return {
          // If defined is true, and value is an object, object is considered
          // to be empty if all properties are undefined, otherwise the default
          // implementation is invoked.
      	isEmpty: function(value, defined) {
      		if (defined && _.isObject(value)) {
  				return !_.any( value, function(value, key) {
  					return value !== undefined || value !== '';
  				});
      		}
      		return _isEmpty(value);
      	}
     };
  }());

  return {
    _ : _
  };
};
