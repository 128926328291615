// This service encapsulates shared functions for multiAddPersonController,
// periodRegistrationController and multiDatePersonRegistrationController.

module.exports = ['lodash', function(lodashService) {
  var self = {},
    _ = lodashService._;

  self.isValidRegistration = function(value) {
    var valid = false;
    if (value || !_.isEmpty(value) || typeof value === 'boolean' || typeof (parseInt(value)) === 'number') {
      if (_.isObject(value) && typeof value !== 'boolean') {
        if (value.hasOwnProperty('checked')) {
          if (!value.date) {
            value.date = moment().format('YYYY-MM-DDTHH:mm:ssZ');
          }
          valid = true;

        } else {
          _.forEach(value, function(subValue, subKey) {
            if (self.isValidRegistration(subValue)) {
              valid = true;
            }
          });
        }
      } else {
        valid = true;
      }
    }
    return valid;
  };

  self.createArticleData = function(activity, scopeArticles) {
    var articles = [];
    // Create correct structure of articles/articleValues
    _.forEach(activity.articles, function(article) {
      var articleData = scopeArticles[article.id];
      if (articleData || typeof(articleData) === 'boolean' || articleData === 0) {
        var tempArticle = {
          'id': article.id,
          'articleValues': []
        };
        var stringValue = '';
        if (article.type === 'multi') {
          _.forEach(article.articleValues, function(articleValue) {
            if (articleData[articleValue.id] !== null && typeof articleData[articleValue.id] !== 'undefined') {
              var checked = true;
              if (articleValue.type === 'bool') {
                stringValue = articleData[articleValue.id] ? 'JA' : 'NEE';
              } else if (articleValue.type === 'time') {
                if(articleData[articleValue.id].checked){
                  stringValue = moment(articleData[articleValue.id].date).format('YYYY-MM-DDTHH:mm:ssZ');
                }else{
                  stringValue = "";
                }
                checked = true;

              } else {
                stringValue = articleData[articleValue.id];
              }
              if (checked) {
                tempArticle.articleValues.push({
                  'id': articleValue.id,
                  'value': stringValue
                });
              }
            }
          });
        } else {
          var checked = true;
          if (article.articleValues[0].type === 'bool') {
            if ((typeof(articleData) === 'boolean' && articleData) || (typeof(articleData) === 'object' && articleData[Object.keys(articleData)[0]])) {
              stringValue = 'JA';
            } else {
              stringValue = 'NEE';
            }
          } else if (article.articleValues[0].type === 'time' || article.type === 'time') {
            var isChecked = true;
            _.forEach(articleData, function(value, key) {
              if(key === 'checked'){
                isChecked = value;
              }else if(typeof(value) === 'object' && typeof value.checked  === 'boolean'){
                isChecked = value.checked;
              }
              if (key !== 'checked' && (typeof(value) === 'object' || (key === 'date' && value))) {
                if (value.date) {
                  stringValue = moment(value.date).format('YYYY-MM-DDTHH:mm:ssZ');
                } else {
                  stringValue = moment(value).format('YYYY-MM-DDTHH:mm:ssZ');
                }
                if(!isChecked){
                  stringValue = "";
                }
                checked = true;
              }
            });
          } else {
            if (parseInt(articleData).isInteger()) {
              stringValue = articleData;
            } else {
              stringValue = articleData[article.articleValues[0].id];
            }
          }

          if (checked) {
            tempArticle.articleValues.push({
              'id': article.articleValues[0].id,
              'value': stringValue
            });
          }
        }
        articles.push(tempArticle);
      }
    });
    return articles;
  };

  return self;
}];
