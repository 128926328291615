module.exports = [
  '$scope', 'lodash', '$rootScope', 'period', 'students', 'schools', 'groups', 'authentication',
  function($scope, lodashService, $rootScope, periodService, studentService, schoolService, groupService, authenticationService) {
    var _ = lodashService._;

    $scope.collapsed = [];
    $scope.amountOfTeachers = studentService.amountOfTeachers;

    var school = schoolService.getSelectedSchool();
    var group = groupService.getSelectedGroup();
    var user = authenticationService.getUser();
    var userName = user.slice(0, user.indexOf('@'));
    var rights = groupService.getSelectedGroup().applications;
    $scope.hasAbsenceRights = false;
    $scope.hasRegistrationRights = false;
    $scope.KlaslijstenURL = 'https://start.informatsoftware.be/informat/shared/leerlingen/Afdrukken/afdrukkenKlas.aspx?Locationid=' + school.id + '&groupId=' + group.groupId + '&username=' + user;

    _.forEach(rights, function(right) {
      if (right.application === "absences" && right.right > 1) {
        $scope.hasAbsenceRights = true;
      }
      if (right.application === "registrations" && right.right > 1) {
        $scope.KlaslijstenURL += '&registratie=1';
        $scope.hasRegistrationRights = true;
      }
    });

    function calculateAbsenceRows() {
      if ($scope.hasAbsenceRights) {
        var tempArray = [];

        // Loop over the amount of days in the period times the absence times.
        // For each absence time per day, a row should be added in the table,
        // unless it's show property is false.
        var days = periodService.useDateRangeArray ? periodService.dateRangeArray : periodService.getDatesInPeriod();
        _.forEach(days, function(day) {
          _.forEach($scope.absenceTimes, function(absenceTime, timeKey)  {
            // Check if entire column is shown and if entire column is locked
            var show = false,
              locked = true;

            _.forEach($scope.studentList, function(student) {
              if ($scope.absences[student.id] && $scope.absences[student.id][day.formatted][absenceTime.id].show) {
                show = true;
              }
              if ($scope.absences[student.id] && !$scope.absences[student.id][day.formatted][absenceTime.id].lock) {
                locked = false;
              }
              //return !(show && !locked); //potentially break out of loop
            });

            if (show) {
              tempArray.push({
                'show': true,
                'locked': locked,
                'day': day.display,
                'dateFormat': day.formatted,
                'time': absenceTime.time ? absenceTime.time : absenceTime.dayPart,
                'timeId': absenceTime.id,
                'isFuture': day.moment.isAfter(moment()),
                'isDayRow': timeKey === 0 ? true : false // only first time of day gets day in the date column
              });
            }
          });
        });

        return calculateAbsences(tempArray);
      }
    }

    function calculateAbsences(absenceRows) {
      _.forEach(absenceRows, function(absenceRow) {
        var precenses = 0;
        _.forEach($scope.studentList, function(student) {
          if ($scope.absences[student.id]) {
            var absenceCode = $scope.absences[student.id][absenceRow.dateFormat][absenceRow.timeId].code;
            if (student.type === 'K') {
              if (absenceCode === '|' || absenceCode === 'L') {
                precenses += 1;
              }
            } else if (!absenceCode || absenceCode === 'L') {
              precenses += 1;
            }
          }

        });
        absenceRow.precenses = precenses;
      });

      return absenceRows;
    }

    $scope.absenceRows = calculateAbsenceRows();


    function calculateRegistrationRows() {
      if ($scope.hasRegistrationRights) {
        var tempRegistrationRowsObject = {};

        _.forEach($scope.activities, function(activity) {
          if (!tempRegistrationRowsObject[activity.hash]) {
            tempRegistrationRowsObject[activity.hash] = {};
          }

          var tempArray = [];
          dates = periodService.getDatesInPeriod();

          _.forEach($scope.registrations[activity.hash], function(registrationDay, key) {
            // Check if it's multiple days
            if (!registrationDay.numbers) {
              return true; // go to next in loop if no numbers (absences registration)
            }
            if (dates.length > 1) {
              // The first row for each day in the registration table is a day row, with the date
              // and wether it's confirmed.

              tempArray.push({
                'title': moment(key, 'YYYY/MM/DD').format('DD.MM.YYYY'),
                'nrRegistrations': '',
                'nrPlanned': '',
                'confirmed': registrationDay.numbers.confirmed ? 'ja' : 'nee',
                'class': 'number-overview__table--row__title--date'
              });
            }

            // Loop over the articles on this day
            _.forEach(activity.articles, function(article) {
              var articleConfirmed = registrationDay.numbers.confirmed ? 'ja' : 'nee';
              if (dates.length > 1) {
                articleConfirmed = '';
              }
              var articleRow = {
                'title': article.name,
                'nrRegistrations': '',
                'nrPlanned': '',
                'confirmed': articleConfirmed
              };

              // Check if there are any number values for this article
              var numbersForArticle = registrationDay.numbers[article.id];
              if (numbersForArticle) {
                // The article has multiple articleValues
                // Add a row per articleValue
                if (article.articleValues.length > 1) {
                  tempArray.push(articleRow);
                  _.forEach(article.articleValues, function(articleValue) {
                    tempArray.push({
                      'title': articleValue.name,
                      'nrRegistrations': numbersForArticle.articleValueNumbers[articleValue.id].registrations,
                      'nrPlanned': numbersForArticle.articleValueNumbers[articleValue.id].planned,
                      'confirmed': '',
                      'class': 'number-overview__table--row__title--articleValue'
                    });
                  });
                }
                // else, add a row with the total numbers for this article
                else {
                  articleRow.title = articleRow.title + ' (' + article.articleValues[0].name + ')';
                  articleRow.nrRegistrations = numbersForArticle.totalRegistrations;
                  articleRow.nrPlanned = numbersForArticle.totalPlanned;
                  tempArray.push(articleRow);
                }
              }
            });
          });

          tempRegistrationRowsObject[activity.hash] = tempArray;
        });

        return tempRegistrationRowsObject;
      }
    }

    $scope.registrationRows = calculateRegistrationRows();

    _.forEach($scope.activities, function(activity)  {
      $scope.collapsed.push(false);
    });

    $scope.collapse = function(index) {
      $scope.collapsed[index] = !$scope.collapsed[index];
    };

    $scope.close = function() {
      $rootScope.$broadcast('close-numbers');
    };

    $scope.$on('absencesSocketUpdate', function(event) {
      $scope.absenceRows = calculateAbsenceRows();
      $scope.safeApply();
    });

    $scope.$on('registrationSocketUpdate', function(event) {
      $scope.registrationRows = calculateRegistrationRows();
      $scope.safeApply();
    });

    $scope.$on('updated-dashboard-data', function(){
      $scope.absenceRows = calculateAbsenceRows();
      $scope.registrationRows = calculateRegistrationRows();
      $scope.safeApply();
    });
  }
];
