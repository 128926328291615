module.exports = ['$timeout', 'activities', 'absences', 'addresses', 'ngDialog', 'lodash', '$rootScope', 'feedback',
  function($timeout, activityService, absenceService, addressService, ngDialog, lodashService, $rootScope, feedbackService) {
    // NOTE "semi-global" vars and functions here
    // declare vars and functions that are shared for all option buttons outside of link function
    // otherwise they are declared hundreds of times in some cases
    var _ = lodashService._;
    var longPressAnimationFrame = 0;
    if ($('#longpress-progress').length === 0) {
      $('body').on('mouseup touchend', _.debounce(longPressOverlayHide, 100));
      $('<div id="longpress-progress" class="progress-0"></div><div id="longpress-overlay"></div>').appendTo('body');
    }


    function addLongPress(scope, element) {
      element = $(element);
      // store stuff in an object to pass to events
      // it's a little extra work, but it will save memory because functions are declared only once
      // and not once per element
      var longPress = {
        scope: scope,
        busy: false,
        duration: 1000,
        animationDuration: 500,
        currentTimeout: 0,
        animationTimeout: 0,
        animationTriggered: false,
        cancelDistance: 20,
        startPosition: {}
      };
      // Correctly set index
      scope.animationInterval = 0;
      scope.wasLongPress = false;
      scope.index = getCodeIndex(scope, true);

      scope.$watch('value', function(newValue, oldValue) {
        if (newValue === 0 || newValue === '0') {
          newValue = '';
        }
        return newValue;
      });

      element.on('mousedown touchstart', longPress, longPressStart);
    }

    function longPressStart(evt) {
      var longPress = evt.data;
      longPress.downEvent = evt;
      var scope = longPress.scope;
      var target = $(evt.target);

      if (longPress.busy  ||  scope.wasLongPress) {
        return false;
      }
      if (scope.locked || !scope.editable || scope.isPeriod) {
        if (scope.showSpecial) {
          // open read-only modal
          ngDialog.close();
          var gaData = {
            eventCategory: 'closed optionbutton',
            eventAction: 'click',
            eventLabel: 'clicked a closed optionbutton'
          };
          $rootScope.$broadcast('send-ga-event', gaData);
          ngDialog.open({
            template: 'partials/modals/registerAbsence',
            appendTo: '#modal',
            trapFocus: false,
            data: {
              'student': scope.student,
              'absenceCodes': scope.absenceCodes,
              'absenceTimeId': scope.articleId,
              'date': scope.date,
              'comment': scope.comment,
              'codeId': scope.codeId,
              'locked': true
            }
          });
        }
        else if (!scope.editable) {
          if ($("#toast-container").children().length < 1) {
            feedbackService.createFeedback(result.data, 'Info', 'U heeft niet voldoende machtigingen om dit te wijzigen.');
          }
        }
        else {
          return false;
        }
      }

      scope.wasLongPress = true;
      longPress.busy = true;
      longPress.animationTriggered = false;
      longPress.startPosition = getEventPosition(evt);
      var targetPosition = target.offset();
      $('#longpress-progress').css({
        'left': targetPosition.left + target.width() / 2,
        'top': targetPosition.top + target.height() / 2
      });

      var body = $('body');
      body.off('.longPress');
      body.on('mouseup.longPress touchend.longPress', longPress, longPressUp);
      body.on('mousemove.longPress touchmove.longPress', longPress, longPressMove);

      // cancel previous timeouts
      $timeout.cancel(longPress.currentTimeout);
      $timeout.cancel(longPress.animationTimeout);
      // set new timeouts
      if (scope.type !== 'multi' && scope.type !== 'bool') {
        longPress.currentTimeout = $timeout(longPressComplete, longPress.duration, false, longPress, scope);
        longPress.animationTimeout = $timeout(longPressAnimationStart, longPress.duration - longPress.animationDuration, false, longPress, scope);
      }
    }

    function longPressAnimationStart(longPress, scope) {
      longPress.animationTriggered = true;
      longPressAnimationShow();

      var element = $('#longpress-progress');
      var step = 20;
      var total = 360;
      var stepTime = Math.floor(longPress.animationDuration * step / total);
      var stopAnimation = false;
      var progress = 0;
      (function animationStep() {
        $timeout.cancel(longPressAnimationFrame);
        if (longPress.busy) {
          progress += step;
          if (progress >= total) {
            progress = total;
          }
        } else {
          progress -= step;
          if (progress <= 0) {
            progress = 0;
            longPressAnimationHide();
            return;
          }
        }

        element.attr('class', 'progress-' + progress); // replace the class
        longPressAnimationFrame = $timeout(animationStep, stepTime);
      })();
    }

    function longPressAnimationShow() {
      $('#longpress-progress').show();
      $('#longpress-overlay').show();
    }

    function longPressAnimationHide() {
      $('#longpress-progress').hide();
    }

    function longPressOverlayHide() {
      $('#longpress-overlay').hide();
    }

    function getEventPosition(evt) {
      if (evt.originalEvent.touches) {
        return {
          x: evt.originalEvent.touches[0].pageX,
          y: evt.originalEvent.touches[0].pageY
        };
      }
      return {
        x: evt.originalEvent.pageX,
        y: evt.originalEvent.pageY
      };
    }

    function longPressMove(evt) {
      var longPress = evt.data;
      var scope = longPress.scope;

      var newPosition = getEventPosition(evt);
      var distance = Math.sqrt(Math.pow(newPosition.x - longPress.startPosition.x, 2) + Math.pow(newPosition.y - longPress.startPosition.y, 2));
      if (distance > longPress.cancelDistance) {
        longPressEnd(longPress, scope);
      }
    }

    function longPressUp(evt) {
      // evt.preventDefault();
      var longPress = evt.data;
      var scope = longPress.scope;

      // trigger regular click

      if (longPress.busy) {
        longPressEnd(longPress, scope);

        if (scope.locked || !scope.editable || scope.isPeriod) {
          return false;
        } else if (!longPress.animationTriggered) {
          longPressClick(longPress, scope);
          return false;
        }
      }
    }

    function longPressEnd(longPress, scope) {
      // stop timeouts and animations
      $timeout.cancel(longPress.currentTimeout);
      $timeout.cancel(longPress.animationTimeout);

      var body = $(document.querySelector('body'));
      body.off('.longPress');

      longPress.busy = false;
      longPress.currentTimeout = $timeout(function() {
        scope.wasLongPress = false;
        longPress.animationTriggered = false;
      }, longPress.duration / 2);
    }

    function longPressClick(longPress, scope) {
      // prevent infinite loop
      if (scope.index > 100) {
        return false;
      }

      if (scope.absenceColumn) {
        if (scope.toggleableCodes && scope.toggleableCodes.length > 0) {
          scope.index += 1;
          if (scope.index >= scope.toggleableCodes.length) {
            scope.index = 0;
          }

          if (scope.toggleableCodes[scope.index] && scope.toggleableCodes[scope.index].code) {
            var found = false;
            _.forEach(scope.toggleableCodes[scope.index].types, function(type) {
              if (type === scope.student.type) {
                found = true;
                return false;
              }
            });
            if (found) {
              scope.value = scope.toggleableCodes[scope.index].code;
              scope.codeId = scope.toggleableCodes[scope.index].id;
            }
            else {
              longPressClick(longPress, scope);
            }
          }
          else {
            // if student type === K (kleuter), value can not be empty.
            if (scope.student.type === 'K') {
              longPressClick(longPress, scope);
            }
            else {
              scope.value = '';
              scope.codeId = 0;
            }
          }
          sendAbsenceCode(scope);
        }
      }
      else {
        if (scope.type === 'multi') {
          multiRegistrationClickHandler(scope);
        }
        else {
          if (scope.type === 'bool') {
            boolRegistrationClickHandler(scope);
          }
          else if (scope.type === 'num') {
            if (scope.student.addresses.length > 1) {
              longPress.busy = true;
              longPressComplete(longPress, scope);
            }
            else {
              scope.value++;
              numberRegistrationClickHandler(scope, false);
            }
          }
          else if (scope.type === 'time') {
            var wasEmpty = true;
            if (scope.articleValues[0].value) {
              wasEmpty = false;
            }
            scope.timeValue = moment().format('HH:mm');
            scope.articleValues[0].value = moment();
            scope.articleValues[0].value.wasEmpty = wasEmpty;
            scope.value = 'clock-o';
            timeRegistrationClickHandler(scope, false);
          }
        }
      }
    }

    function longPressComplete(longPress, scope) {
      if (longPress.busy) {
        longPressEnd(longPress, scope);
        $timeout.cancel(longPressAnimationFrame);
        longPressAnimationHide();
        var dialogAbsenceSelected = false,
          dialogCanceled = false;

        scope.cancelPostMethod();
        ngDialog.close();
        var gaData = {
          eventCategory: 'optionbutton',
          eventAction: 'longpress',
          eventLabel: 'longpress completed on an optionbutton'
        };
        $rootScope.$broadcast('send-ga-event', gaData);

        if (scope.absenceColumn) {
          ngDialog.open({
            template: 'partials/modals/registerAbsence',
            appendTo: '#modal',
            trapFocus: false,
            data: {
              'student': scope.student,
              'absenceCodes': scope.absenceCodes,
              'absenceTimeId': scope.articleId,
              'date': scope.date,
              'comment': scope.comment,
              'codeId': scope.codeId,
              'postMethod': scope.postMethod,
              'dialogHasSelectedAbsence': function() {
                dialogAbsenceSelected = true;
              },
              'dialogIsCanceled': function() {
                dialogCanceled = true;
              },
              'dialogSuccessCalback': function(result) {
                if (result && result.data && result.data.absences[0] && result.data.absences[0].absenceCodeId) {
                  var newAbsenceCode;
                  var newCodeId = result.data.absences[0].absenceCodeId;

                  for(var i = 0; i < scope.absenceCodes.length; i++) {
                    if (scope.absenceCodes[i].id == newCodeId) {
                      newAbsenceCode = scope.absenceCodes[i];
                    }
                  }

                  if (newAbsenceCode || newAbsenceCode === '') {
                    scope.initialCode = newAbsenceCode.code;
                  }
                }
              }
            },
            preCloseCallback: function(data) {
              scope.animationInterval = 0;
              if (dialogCanceled || !dialogAbsenceSelected) {
                if (scope.initialCode) {
                  scope.value = scope.initialCode;
                  scope.index = getCodeIndex(scope);
                }
                else {
                  // scope.value = '';
                  // scope.index = 0;
                }
                if (scope.toggleableCodes.length && scope.index > 0 && scope.index < scope.toggleableCodes.length - 1) {
                  scope.codeId = scope.toggleableCodes[scope.index].id;
                }

                console.log('dialog cancelled: ', scope.index);
              }
              if (!dialogAbsenceSelected) {
                console.log('not cancelled: ', scope.index);
              }
              if (!dialogCanceled) {
                scope.initialCode = scope.value;
              }
            }
          });
        }
        else {
          if (!scope.editable || scope.isPeriod) {
            return false;
          }

          // always get the id, set the small dialog location and close other dialogs
          var id = '#' + scope.element[0].id;
          setSmallDialogLocation(longPress.downEvent, 30, 15);
          ngDialog.close();

          if (!scope.locked) {
            if (scope.type === 'num') {
              numberRegistrationClickHandler(scope, true);
            } else if (scope.type === 'time') {
              timeRegistrationClickHandler(scope, true);
            }
          }
        }
      }
    }

    function sendAbsenceCode(scope) {
      if (scope.codeId === null) {
        scope.codeId = 0;
      }
      // remember old code and set initial code
      var postedValue = scope.value;
      scope.postMethod(
        500,
        scope.date,
        scope.student.id,
        scope.articleId,
        scope.codeId,
        scope.comment,
        function() {
          //error callback
          // when error occurs, reset to inital code
          scope.value = scope.initalCode;
          scope.index = getCodeIndex(scope);
          scope.codeId = scope.toggleableCodes[scope.index].id;
        },
        null, // empty dialog callback
        scope.initalCode === scope.value, // reset flag
        function() {
          // success callback
          scope.initialCode = postedValue;
        }
      );
      var gaData = {
        eventCategory: 'absenceCode',
        eventAction: 'post',
        eventLabel: 'posted an absence'
      };
      $rootScope.$broadcast('send-ga-event', gaData);
    }

    // Sets the location of the small dialog to the position of the mouse
    function setSmallDialogLocation(event, xOffset, yOffset) {
      var smallDialogContainer = document.getElementById('small-dialog-container');
      var left = 0,
          top = 0;

      if (event.clientX && event.clientY) {
        left = event.clientX - xOffset;
        top = event.clientY - yOffset;
      }
      else {
        var touch = event.originalEvent.touches[0];
        left = touch.pageX - xOffset;
        top = touch.pageY - yOffset;
      }

      smallDialogContainer.style.left = left + 'px';
      smallDialogContainer.style.top = top + 'px';
    }

    // CLICK HANDLERS //
    function numberRegistrationClickHandler(scope, showDialog) {
      var changed = false;
      if (showDialog) {
        ngDialog.open({
          template: 'partials/modals/numberpickerModal',
          className: 'ngdialog--option-btn--num-theme',
          appendTo: '#small-dialog-container',
          overlay: false,
          showClose: true,
          trapFocus: false,
          data: {
            'setValue': function(value) {
              changed = true;
              scope.articleValues[0].value = value;
            },
            value: parseInt(scope.value)
          },
          preCloseCallback: function() {
            if (scope.articleValues[0].value === 0) {
              scope.articleValues[0].value = null;
            }

            function postRegistration(addresId, successString, errorString) {
              activityService.postRegistration(
                scope.student.id,
                scope.date,
                scope.activityHash,
                addresId,
                scope.articleId,
                scope.articleValues
              ).then(
                function(result) {
                  scope.value = scope.articleValues[0].value;
                  feedbackService.createFeedback(result.data, 'Activiteiten', successString);
                  ngDialog.close();
                },
                function(error) {
                  feedbackService.createFeedback(error.data, 'Activiteiten', errorString + scope.student.nickName + ' op ' + scope.date);

                  // Reset articleValues
                  scope.articleValues[0].value = scope.value;
                  console.log('error posting registration: ', error);
                }
              );
            }
            if (changed && scope.articleValues[0].value !== null && scope.articleValues[0].value !== scope.value) {
              changed = false;
              addressService.setRegistrations(scope.registrations);
              addressService.startAddressDialogChain([scope.student], [scope.date], function(chosenAddresses) {
                postRegistration(chosenAddresses[0].addressId, 'Registratie werd bewaard', 'Registratie kon niet worden bewaard voor ');
              });
            } else if (scope.articleValues[0].value === null && scope.articleValues[0].value !== scope.value) {
              postRegistration(null, 'Registratie werd bewaard', 'Registratie kon niet worden bewaard voor ');
            }
          }
        });
      } else {
        changed = false;
        scope.articleValues[0].value = scope.value;
        addressService.setRegistrations(scope.registrations);
        addressService.startAddressDialogChain([scope.student], [scope.date], function(chosenAddresses) {
          scope.postMethod(500, scope.date, scope.student.id, scope.activityHash, chosenAddresses[0].addressId, scope.articleId, scope.articleValues);
        });
      }
      var gaData = {
        eventCategory: 'optionbutton',
        eventAction: 'click',
        eventLabel: 'send number registration'
      };
      $rootScope.$broadcast('send-ga-event', gaData);
    }

    function boolRegistrationClickHandler(scope) {
      var gaData = {
        eventCategory: 'optionbutton',
        eventAction: 'click',
        eventLabel: 'send bool registration'
      };
      $rootScope.$broadcast('send-ga-event', gaData);
      var value = '';
      if (scope.value === 'check') {
        scope.value = 'times';
        value = "NEE";
      } else {
        scope.value = 'check';
        value = "JA";
      }
      //delay, date, studentId, activityHash, addressId, articleId, articleValues
      var articleValues = [];
      var articleValue = {
        id: scope.articleValues[0].id,
        value: value
      };
      articleValues.push(articleValue);

      $timeout.cancel(scope.addressTimeout);
      //TO DO: add check if original value is the same as the value that is going to be posted, if so , dont post it.
      console.log('scope.value: ', scope.value);
      console.log('scope.previousValue', scope.previousValue);

      if ((scope.value === 'check' && scope.previousValue === 'JA') || (scope.value === 'times' && scope.previousValue === 'NEE')) {

        var cancelObject = {
          'date': scope.date,
          'studentId': scope.student.id,
          'articleId': scope.articleValues[0].id,
          'articleValues': scope.articleValues,
          'hash': scope.activityHash
        };
        scope.cancelPostMethod(cancelObject);
      }
      else {
        // If a student has multiple addresses, and the user wants to click multiple times on a option button,
        // this would open the addresses on every click. This timeout prevents that.
        scope.addressTimeout = $timeout(getAddressesAndPost, 500, true, articleValues, scope, function() {
          if (scope.value === 'check') {
            scope.previousValue = 'JA';
          } else {
            scope.previousValue = 'NEE';
          }
        });
      }
    }

    function getAddressesAndPost(articleValues, scope, callback) {
      if (articleValues.length === 1 && articleValues[0].value === "NEE") {
        scope.postMethod(
          500,
          scope.date,
          scope.student.id,
          scope.activityHash,
          null,
          scope.articleId,
          articleValues
        ).then(
          function(result) {
            if (callback && typeof callback === 'function') {
              callback();
            }
          },
          function(error) {
            //console.log('Error posting a registration (optionbuttonDirective.getAddressesAndPost)');
          }
        );
      } else {
        addressService.setRegistrations(scope.registrations);
        addressService.startAddressDialogChain([scope.student], [scope.date], function(chosenAddresses) {
          scope.postMethod(
            500,
            scope.date,
            scope.student.id,
            scope.activityHash,
            chosenAddresses[0].addressId,
            scope.articleId,
            articleValues
          ).then(
            function(result) {
              if (callback && typeof callback === 'function') {
                callback();
              }
            },
            function(error) {
              // console.log('Error posting a registration (optionbuttonDirective.getAddressesAndPost)');
            }
          );
        });
      }
    }

    function timeRegistrationClickHandler(scope, showDialog) {
      var gaData = {
        eventCategory: 'optionbutton',
        eventAction: 'click',
        eventLabel: 'send time registration'
      };
      $rootScope.$broadcast('send-ga-event', gaData);
      if (showDialog) {
        ngDialog.open({
          template: 'partials/modals/timepickerModal',
          className: 'ngdialog--option-btn--time-theme',
          appendTo: '#small-dialog-container',
          overlay: false,
          showClose: true,
          trapFocus: false,
          data: {
            articleValues: scope.articleValues,
            student: scope.student,
            activityId: scope.activityId,
            articleId: scope.articleId,
            date: scope.date,
            activityHash: scope.activityHash,
            activityLabel: scope.activityLabel,
            postRegistration: function(delay, date, studentId, activityHash, articleId, articleValues) {
              checkAddressAndPostRegistration(scope, delay, date, studentId, activityHash, articleValues);
            }
          }
        });
      } else {
        checkAddressAndPostRegistration(scope, 500, scope.date, scope.student.id, scope.activityHash, scope.articleValues);
      }
    }

    function checkIfAdressChainNecessary(scope, articleValues) {
      // If scope.type === multi, we need to search the previous value array instead of simply comparing it.
      function findMatchingPreviousValue(articleId) {
        return _.find(scope.previousValue, function(previousValue) {
          return previousValue.id === articleId;
        });
      }

      var useAddressChain = false;
      _.forEach(articleValues, function(articleValue) {
        if (articleValue.value) {
          if (typeof articleValue === 'object' && articleValue.value) {
            var matchingPreviousValue = findMatchingPreviousValue(articleValue.id);

            if (!matchingPreviousValue || !matchingPreviousValue.value || articleValue.value === 'JA' || articleValue.value > 0) {
              useAddressChain = true;
              return false; //break from loop
            }

            if (moment(matchingPreviousValue.value).format('YYYY-MM-DDTHH:mm') !== moment(articleValue.value).format('YYYY-MM-DDTHH:mm')) {
              useAddressChain = true;
              return false; //break from loop
            }
          }
          else if (moment(scope.previousValue).format('YYYY-MM-DDTHH:mm') !== moment(articleValue).format('YYYY-MM-DDTHH:mm') && articleValue.value !== 'NEE' && (typeof articleValue.value === 'object' || articleValue.value === 'JA' || articleValue.value > 0)) {
            useAddressChain = true;
            return false; //break from loop
          }
        }
      });
      return useAddressChain;
    }

    function checkAddressAndPostRegistration(scope, delay, date, studentId, activityHash, articleValues) {
      function parseResultAndSetScopeValue(scope, result) {
        var articleValues = result.data.articleValues;
        for (var i = 0; i < articleValues.length; i++) {
          if (!(articleValues[i].activityId === scope.activityId && articleValues[i].articleId === scope.articleId)) {
            scope.value = articleValues[i].value;
            if (!articleValues[i].value) {
              scope.timeValue = null;
              scope.value = '';
            }
            else {
              scope.timeValue = moment(articleValues[i].value, ['HH:mm:ss', 'YYYY-MM-DDTHH:mm:ssZ']).format('HH:mm');
              scope.value = 'clock-o';
            }

            scope.safeApply();
            return false; // break loop
          }
        }
      }

      if (checkIfAdressChainNecessary(scope, articleValues)) {
        addressService.setRegistrations(scope.registrations);
        addressService.startAddressDialogChain([scope.student], [scope.date], function(chosenAddresses) {
          scope.postMethod(delay, date, studentId, activityHash, chosenAddresses[0].addressId, scope.articleId, articleValues)
            .then(
              function(result) {
                parseResultAndSetScopeValue(scope, result);
              },
              function(error) {
                console.log('Error: ', error);
              }
            );
        });
      } else {
        scope.postMethod(delay, date, studentId, activityHash, null, scope.articleId, articleValues)
          .then(
            function(result) {
              parseResultAndSetScopeValue(scope, result);
            },
            function(error) {
              console.log('Error: ', error);
            }
          );
      }
    }

    function multiRegistrationClickHandler(scope) {
      $rootScope.$broadcast('send-ga-pageview', '/multi-registration');
      ngDialog.open({
        template: 'partials/modals/multiValueModal',
        className: 'option-btn--multi-theme ngdialog-theme-default',
        appendTo: '#modal',
        overlay: true,
        showClose: true,
        trapFocus: false,
        data: {
          articleValues: scope.articleValues,
          container: '#modal',
          student: scope.student,
          activityId: scope.activityId,
          articleId: scope.articleId,
          date: scope.date,
          activityHash: scope.activityHash,
          ngDialogId: '#modal',
          activityLabel: scope.activityLabel,
          locked: scope.locked,
          postRegistration: function(studentId, date, activityHash, articleId, articleValues) {
            if (checkIfAdressChainNecessary(scope, articleValues)) {
              addressService.setRegistrations(scope.registrations);
              addressService.startAddressDialogChain([scope.student], [scope.date], function(chosenAddresses) {
                scope.postMethod(0, date, studentId, scope.activityHash, chosenAddresses[0].addressId, scope.articleId, articleValues);
              });
            }
            else {
              scope.postMethod(0, date, studentId, scope.activityHash, null, scope.articleId, articleValues);
            }
            var gaData = {
              eventCategory: 'optionbutton',
              eventAction: 'click',
              eventLabel: 'send multiple registrations'
            };
            $rootScope.$broadcast('send-ga-event', gaData);
          }
        }
      });
    }

    function getCodeIndex(scope, onlyToggleable) {
      var absenceCodes = onlyToggleable ? scope.toggleableCodes : scope.absenceCodes;
      return _.findIndex(absenceCodes, function(toggleableCode) {
        return toggleableCode.id == scope.codeId;
      });
    }

    //  EXPORTS  //
    return {
      restrict: 'EA',
      templateUrl: 'partials/columns/optionbuttonDirectiveView',
      replace: true,
      scope: {
        type: '=',
        student: '=',
        activityId: '@',
        articleId: '@',
        absenceColumn: '@',
        absenceCodes: "=",
        toggleableCodes: "=",
        date: '@',
        value: '@',
        codeId: '@',
        editable: '=',
        locked: '=',
        planned: '=',
        showSpecial: '=',
        comment: '@',
        articleValues: "=",
        activityHash: '@',
        postMethod: '=',
        cancelPostMethod: '=',
        parentId: '@',
        isPeriod: '=',
        activityLabel: '@',
        activityUpdate: '=', // Boolean changes when activity updates, this triggers watch function,,
        registrations: '='
      },
      link: function(scope, element, attributes) {
        scope.index = 0;
        scope.$watch("locked", changeColorOfBtn);
        scope.$watch("isPeriod", changeColorOfBtn);
        scope.$watch('planned', changeColorOfBtn);
        scope.element = element;

        scope.safeApply = function(fn) {
          var phase = (this.$root && this.$root.$$phase) ? this.$root.$$phase : null;
          if (phase == '$apply' || phase == '$digest') {
            if (fn && (typeof(fn) === 'function')) {
              fn();
            }
          } else {
            this.$apply(fn);
          }
        };

        // Set colors depending on editable, locked,...
        function changeColorOfBtn() {
          if (scope.locked) {
            scope.class = 'option-btn--green';
          } else if (scope.isPeriod && scope.editable) {
            scope.class = 'option-btn--purple';
          } else if (scope.editable) {
            if (scope.planned) {
              scope.class = 'option-btn--light-blue';
            } else {
              scope.class = 'option-btn--blue';
            }
          } else {
            scope.class = 'option-btn--gray';
          }
        }

        if (scope.absenceColumn) {
          scope.initialCode = scope.value;

          // if (scope.codeId) {
          //   for (var i = 0; i < scope.toggleableCodes.length; i++) {
          //     if (scope.toggleableCodes[i].id == scope.codeId) {
          //       scope.index = i;
          //       i = scope.toggleableCodes.length;
          //     }
          //   }
          // }
        }

        scope.$watch('activityUpdate', function(newValue, oldValue) {
          if (newValue) {
            updateActivityValue();
            // reset update boolean after value has been updated.
            scope.activityUpdate = false;
          }
        });

        scope.$watch('articleId', function(newValue, oldValue) {
          if (newValue) {
            updateActivityValue();
          }
        });

        // Only triggered from gridViewController line 35. Don't use.
        scope.$on('updateActivityValue', function() {
          updateActivityValue();
        });

        // Updates the scope.value attribute, which corresponds to the content of
        // a option button in the view, based on the type of the registration.
        function updateActivityValue() {
          if (scope.absenceColumn) {
            for (var i = 0; i < scope.toggleableCodes.length; i++) {
              if (scope.value === scope.toggleableCodes[i].code) {
                scope.index = i;
              }
            }
          }
          if (scope.type === 'num') {
            scope.timeValue = null;
            if (scope.articleValues) {
              scope.value = scope.articleValues[0].value;
            }
          } else if (scope.type === 'bool') {
            scope.timeValue = null;
            if (scope.articleValues) {
              if (scope.articleValues[0].value === 'JA') {
                scope.value = 'check';
                scope.previousValue = 'JA';
              } else if (scope.articleValues[0].value === 'NEE') {
                scope.value = 'times';
                scope.previousValue = 'NEE';
              } else {
                scope.value = '';
                scope.previousValue = null;
              }
            }
            else {
              scope.previousValue = null;
            }
          } else if (scope.type === 'time') {
            if (scope.articleValues) {
              scope.previousValue = scope.articleValues[0].value;
              if (scope.articleValues[0].value && scope.articleValues[0].value !== '') {
                scope.timeValue = moment(scope.articleValues[0].value, ['HH:mm:ss', 'YYYY-MM-DDTHH:mm:ssZ']).format('HH:mm');
                scope.value = 'clock-o';
              } else {
                scope.value = '';
                scope.timeValue = null;
              }
            }
          } else if (scope.type === 'multi') {
            var checked = false;
            scope.timeValue = null;
            _.forEach(scope.articleValues, function(articleValue) {
              if (articleValue.type === 'bool') {
                if (articleValue.value === "JA") {
                  checked = true;
                  return false;
                }
              } else if (articleValue.value !== null || articleValue.value > 0) {
                checked = true;
                return false;
              }
            });
            scope.previousValue = scope.articleValues;
            if (checked) {
              scope.value = 'check';
            } else {
              scope.value = '';
            }
          }
        }

        // Set values of the registrations
        if (!scope.absenceColumn) {
          updateActivityValue();
        }

        // If it's an absence button, bind the longPress handlers
        //UPDATE all option buttons have longpress, not the boolean
        addLongPress(scope, element);
      }
    };
  }
];
