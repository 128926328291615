// Controller for the custom directive columnDirective
module.exports = ['$scope', 'columns', 'ngDialog', 'students', 'lodash', '$rootScope', function($scope, columnService, ngDialog, studentService, lodashService, $rootScope) {
  "use strict";

  var columnDetails;
  var _ = lodashService._;

  function updateColumnDetails() {
    columnDetails = columnService.getColumnDetails($scope.column.hash);

    $scope.subColumns = columnDetails.subColumns;
    $scope.dates = columnDetails.dates;
    $scope.hasMultiLock = columnDetails.options.hasMultiLock;
  }

  updateColumnDetails();
  columnService.subscribeCallback(updateColumnDetails);

  $scope.hasEditAllBtn = function(subColumn, $index) {
    return ($index === 0 && columnDetails.options.showEditAllBtn && $scope.isCalendar);
  };

  $scope.isCalendar = function(subColumn) {
    return (subColumn.id === 'calendar');
  };
  $scope.isSingle = function(hashCode) {
    return ($scope.subColumns.length <= 1 || ($scope.hasCalendar(hashCode) && $scope.subColumns.length <= 2));
  };

  $scope.isSubcolumnHeader = function(subColumn) {
    return (subColumn.id !== 'calendar');
  };

  $scope.showSingleLock = function(subColumn) {
    return (!$scope.isCalendar(subColumn) && !$scope.hasMultiLock);
  };

  $scope.showPeriodModal = function(isAbsence, data) {
    if (isAbsence)  {
      showPeriodAbsenceModal(data.absenceCodes, data.student, data.column, data.subColumn);
    } else {
      showPeriodRegistrationModal(data.student, data.column);
    }
  };

  function showPeriodAbsenceModal(absenceCodes, student, column, subColumn) {
    $rootScope.$broadcast('send-ga-pageview', '/period-absence-modal');
    ngDialog.open({
      template: 'partials/modals/periodAbsenceModal',
      appendTo: '#modal',
      trapFocus: false,
      data: {
        "student": student,
        "absenceCodes": absenceCodes,
        "column": column,
        "dashBoardCallback": $scope.testCall,
        "hasPeriodCheck": true,
        'hasRight': $scope.absenceRight
      },
      className: 'ngdialog-theme-default ngdialog--width700 no-padding-top',
      preCloseCallback: function(value) {
        $rootScope.$broadcast('update-dashboard');
      }
    });
  }

  function showPeriodRegistrationModal(student, column) {
    $rootScope.$broadcast('send-ga-event', '/period-registration-modal');
    ngDialog.open({
      template: 'partials/modals/periodRegistrationModal',
      appendTo: '#modal',
      trapFocus: false,
      data: {
        'student': student,
        'activityId': column.id,
        'hasRight': $scope.registrationRight
      },
      className: 'ngdialog-theme-default ngdialog--width700'
    });
  }

  //generate a hash
  $scope.generateHash = function(studentId, date) {
    return (studentId + date).hashCode();
  };

  $scope.showActivityComment = function(event, comment, column) {
    setSmallDialogLocation(event, 30, 15);
    ngDialog.open({
      template: 'partials/modals/activityComment',
      appendTo: '#activity-comment',
      className: 'ngdialog--activity-comment',
      overlay: true,
      showClose: true,
      scope: $scope,
      trapFocus: false,
      data: {
        "comment": comment,
        "column": column
      }
    });
  };

  function setSmallDialogLocation(mouseEvent, xOffset, yOffset) {
    var smallDialogContainer = document.getElementById('activity-comment');
    var left = mouseEvent.clientX - xOffset,
      top = mouseEvent.clientY - yOffset;

    smallDialogContainer.style.left = left + 'px';
    smallDialogContainer.style.top = top + 'px';
  }

  $scope.openDuplicateRegistrationModal = function(column, date) {
    //self.postMultiPersonRegistration = function(persons, activityId, label, date, articles) {
    $rootScope.$broadcast('send-ga-pageview', '/duplicate-registration-modal');
    var tempStudents = studentService.getPersons();

    var students = [];
    _.forEach(tempStudents, function(student) {
      var objStudent = {
        id: student.id,
        addressId: student.addresses[0].id
      };
      students.push(objStudent);
    });

    ngDialog.open({
      template: 'partials/modals/duplicateModal',
      appendTo: '#modal',
      className: 'ngdialog-theme-default',
      overlay: true,
      showClose: true,
      scope: $scope,
      trapFocus: false,
      data: {
        "students": students,
        "activityId": column.id,
        "label": column.name,
        "date": moment(date.formatted, 'YYYY/MM/DD')
      }
    });
  };
}];
