module.exports = ['$scope', 'lodash', 'spinnerService', '$timeout', 'students', 'activities', '$controller', 'addresses', 'multiRegistration', 'ngDialog', 'schools', 'period', 'feedback',
  function($scope, lodashService, spinnerService, $timeout, studentService, activityService, $controller, addressService, multiRegistrationService, ngDialog, schoolService, periodService, feedbackService) {
    "use strict";

    var _ = lodashService._;

    $controller('dropdownDatepickerController', {
      $scope: $scope
    }); //This works

    $scope.student = $scope.ngDialogData.student;

    var period = periodService.getPeriodAsMoment();

    $scope.dateStart = period.startDate;
    $scope.dateEnd = period.endDate;

    $scope.activity = _.find(activityService.activities, function(activity) {
      return activity.id === $scope.ngDialogData.activityId;
    });

    $scope.data = {};
    _.forEach($scope.activity.articles, function(article) {
      if (!$scope.data[article.id]) {
        $scope.data[article.id] = {};
      }
      if (article.type === 'multi') {
        _.forEach(article.articleValues, function(articleValue) {
          if (articleValue.type === 'bool') {
            if (!$scope.data[article.id][articleValue.id]) {
              $scope.data[article.id][articleValue.id] = false;
            }
          }
          else if (articleValue.type === 'num') {
            if (!$scope.data[article.id][articleValue.id]) {
              $scope.data[article.id][articleValue.id] = 0;
            }
          }
        });
      }
      else {
        if (article.type === 'num') {
          $scope.data[article.id] = 0;
        }
        else if(article.type === 'bool'){
          $scope.data[article.id] = false;
        }
        else if(article.type === 'time'){
          $scope.data[article.id].checked = false;
          $scope.data[article.id].date = null;
        }
      }
    });


    $scope.showNumModal = function($event) {
      ngDialog.open({
        template: 'partials/modals/numberpickerModal',
        className: 'ngdialog--option-btn--num-theme',
        overlay: false,
        showClose: true,
        appendTo: $scope.ngDialogData.container
      });
    };
    $scope.showTimeModal = function() {
      ngDialog.open({
        template: 'partials/modals/timepickerModal',
        className: 'ngdialog--option-btn--time-theme',
        overlay: true,
        showClose: true
      });
    };
    $scope.toggleBool = function() {
      if (scope.value === 'check') {
        scope.value = 'times';
      } else {
        scope.value = 'check';
      }
    };

    $scope.postPeriodRegistration = function() {
      if ($scope.isValid()) {
        var articles = multiRegistrationService.createArticleData($scope.activity, $scope.data);
        var allZeros = true;

        _.forEach($scope.data, function(value, key) {
          if (key !== 'selectedPersons') {
            if ($scope.activity.articles[0].type === 'multi') {
              _.forEach(value, function(item, itemKey) {
                if ((typeof item === 'boolean' && item) || item.checked || (typeof(parseInt(item)) === 'number')) {
                  allZeros = false;
                }
              });
            } else {
              if (typeof value === 'object') {
                _.forEach(value, function(itemValue, key) {
                  if (typeof itemValue === 'object' || itemValue) {
                    allZeros = false;
                  }
                });
              } else {
                if (0 < parseInt(value) || value.checked || value === 'true' || (typeof value === 'boolean' && value)) {
                  allZeros = false;
                }
              }
            }
          }
        });
        if (allZeros) {
          spinnerService.show('modalSpinner');
          var promise;

          if ($scope.useDateRangeArray) {
            promise = activityService.postDatesArrayRegistration(
              $scope.student.id,
              $scope.dateRangeArray,
              $scope.activity.id,
              $scope.activity.name,
              null,
              articles);
          } else {
            promise = activityService.postPeriodRegistration(
              $scope.student.id,
              $scope.dateStart,
              $scope.dateEnd,
              $scope.activity.id,
              $scope.activity.name,
              null,
              articles);
          }
          promise.then(
            function(response) {
              spinnerService.hide('modalSpinner');
              $scope.closeThisDialog();
              feedbackService.createFeedback(response.data, $scope.activity.name, 'Registraties zijn bewaard');

            },
            function(error) {
              console.log('Error in periodRegistrationController: ', error);
              spinnerService.hide('modalSpinner');
              $scope.closeThisDialog();
              feedbackService.createFeedback(error.data, $scope.activity.name, 'Registraties konden niet worden bewaard');

            }
          );
        } else {
          addressService.startAddressDialogChain([$scope.student], createPeriod(), function(chosenAddresses) {
            spinnerService.show('modalSpinner');
            var promise;

            if ($scope.useDateRangeArray) {
              promise = activityService.postDatesArrayRegistration(
                $scope.student.id,
                $scope.dateRangeArray,
                $scope.activity.id,
                $scope.activity.name,
                chosenAddresses[0].addressId,
                articles);
            } else {
              promise = activityService.postPeriodRegistration(
                $scope.student.id,
                $scope.dateStart,
                $scope.dateEnd,
                $scope.activity.id,
                $scope.activity.name,
                chosenAddresses[0].addressId,
                articles);
            }

            promise.then(
              function(response) {
                spinnerService.hide('modalSpinner');
                $scope.closeThisDialog();
                feedbackService.createFeedback(response.data, $scope.activity.name, 'Registraties zijn bewaard');

              },
              function(error) {
                console.log('Error in periodRegistrationController: ', error);
                spinnerService.hide('modalSpinner');
                $scope.closeThisDialog();
                feedbackService.createFeedback(error.data, $scope.activity.name, 'Registraties konden niet worden bewaard', 'error');

              }
            );
          });
        }
      }
    };

    function createPeriod() {
      var dates = [],
        newDate = $scope.dateStart;

      if ($scope.dateStart.isSame($scope.dateEnd)) {
        return [$scope.dateStart];
      } else {
        dates.push(newDate);
        do {
          newDate = moment(newDate).add(1, 'days');
          dates.push(newDate);
        } while (newDate.isBetween($scope.dateStart, $scope.dateEnd));
        return dates;
      }
    }

    function getPeriodAsMoment() {
      return {
        startDate: moment($scope.dateStart, "YYYY/MM/DD"),
        endDate: moment($scope.dateEnd, "YYYY/MM/DD")
      };
    }

    function getDatesInPeriod() {
      var dates = [],
        periodMoment = getPeriodAsMoment(),
        newDate = periodMoment.startDate;

      if (periodMoment.startDate === periodMoment.endDate) {
        return [periodService.createPeriodDate(periodMoment.startDate)];
      }

      function addNewDate(date) {
        dates.push(periodService.createPeriodDate(date));
      }

      addNewDate(periodMoment.startDate);
      do {
        newDate = moment(newDate).add(1, 'days');
        addNewDate(newDate);
      } while (newDate.isBetween(periodMoment.startDate, periodMoment.endDate));
      return dates;
    }

    $scope.isValid = function() {
      var valid = false;
      _.forEach($scope.data, function(value, key) {
        if (key !== 'selectedPersons') {
          if (typeof(value) === 'boolean' || value === 0 || multiRegistrationService.isValidRegistration(value)) {
            valid = true;
          }
        }
      });
      $scope.valid = valid;
      return valid;
    };
    $scope.isValid(); // make sure the scope is validated when modal is opened

    $scope.checkTimePicker = function(articleId, articleValueId) {
      if (articleValueId) {
        $scope.data[articleId][articleValueId].checked = true;
        $scope.data[articleId][articleValueId].date = moment($scope.data[articleId][articleValueId].date).format('YYYY-MM-DDTHH:mm:ssZ');
      } else {
        $scope.data[articleId].checked = true;
        $scope.data[articleId].date = moment($scope.data[articleId].date).format('YYYY-MM-DDTHH:mm:ssZ');
      }
    };

    // Overwrite the select function of the dropdown in dropdownDatepickerController
    $scope.select = function(item, property, id, sendImmediate) {
      var openedDialog = ngDialog.getOpenDialogs();
      ngDialog.close(openedDialog[openedDialog.length - 1]); // close last opened dialog, this one

      var type = id.replace('dropdown-modal--', '');
      if (type === 'datepicker__selections') {
        $scope.selectedSelectionOption = item;
        if (typeof item.selectFunction === 'function') {
          var dateObject = item.selectFunction();
          $scope.dateStart = dateObject.dateStart;
          $scope.dateEnd = dateObject.dateEnd;
        }
      }
    };

    $scope.useDateRangeArray = false;
    $scope.dateRangeArray = ''; //backend implementation expects comma-seperated string instead of array.

    $scope.filterObject = {
      'registrationsCallback': function() {
        if (this.useDateRangeArray) {
          $scope.useDateRangeArray = true;

          var arrayString = '';
          _.forEach(this.dateRangeArray, function(dateRangeItem) {
            arrayString += dateRangeItem.moment.format('YYYY-MM-DD') + ',';
          });
          $scope.dateRangeArray = arrayString.slice(0, -1); //removes the last redundant comma from the string
        }
        else {
          $scope.useDateRangeArray = false;
          $scope.dateRangeArray = '';
        }
      },
      'daterangeFilters': $scope.filterObject.daterangeFilters,
      'useDateRangeArray': $scope.filterObject.useDateRangeArray,
      'reversedDateRangeArray': $scope.filterObject.reversedDateRangeArray,
      'dateRangeArray': getDatesInPeriod()
    };

    $scope.applyFiltersToDaterange($scope.filterObject);
  }
];
