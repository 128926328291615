module.exports = ['$http', '$q', 'schools', 'groups', 'period', 'lodash', 'students',
  function ($http, $q, schoolService, groupService, periodService, lodashService, studentService)  {
    var _ = lodashService._;

    /*
     * absenceCodes: determines which codes are applicable for which persons, periods
     * [
     *  { id: integer,
     *    period: bool, (this code has to be shown in calendar modal)
     *    onlyPeriod: bool, (this code can only be shown in calendar modal)
     *    code: String,
     *    description: String,
     *    types: [], list of types where abscenceCode is avaliable
     *    subCodes: [
     *      id: integer,
     *      code: String,
     *      description: String,
     *      types: [], list of types where abscenceCode is avaliable,
     *    ],
     *  }
     * ]
     */
    var absenceCodes = [],
      /*
       * absenceTimes: the times for which absences should be registered, i.e. subcolumns
       * [
       *  { id: integer,
       *    time: String
       *    dayPart: 'VM' | 'NM',
       *  }
       * ]
       */
      absenceTimes = [],
      // absences: A hashmap of all the absences for the persons in the selected period
      // {
      //  studentId: {
      //    hasAbsences: boolean, //wether this student has any absences in the selected period
      //    date: {
      //      absenceTimeId: {
      //        "code": String,
      //        "codeId": String,
      //        "showSpecial": boolean,
      //        "show": boolean,
      //        "edit": boolean,
      //        "lock": boolean,
      //        "comment": String
      //      },...
      //    },...
      //  },...
      // }
      absences = [],
      absenceSubcolumnData = {},
      baseUrl = '/api',
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      };

    function mergeAbsenceData(changed) {
      _.merge(returnObject.absences, changed);
    }

    // Used when the websocket updates the lock status of absences.
    function mergeLockAbsenceData(changed) {
      var temp = returnObject.absences;
      _.forEach(changed, function(absenceChanged) {
        var date = moment(absenceChanged.date).format('YYYY/MM/DD');
        absenceSubcolumnData[date][absenceChanged.absenceTimeId].locked = true;
        _.forOwn(temp, function(absence) {
          if (absence[date] && absence[date][absenceChanged.absenceTimeId]) {
            absence[date][absenceChanged.absenceTimeId].lock = absenceChanged.lock;

            // If any datapoint in the subcolumn is unlocked, the entire subcolumn
            // counts as unlocked
            if (!absence[date][absenceChanged.absenceTimeId].lock) {
              absenceSubcolumnData[date][absenceChanged.absenceTimeId].locked = false;

            }
            // If any button in the column is editable, the entire subcolumn is
            if (absence[date][absenceChanged.absenceTimeId].canEdit) {
              absenceSubcolumnData[date][absenceChanged.absenceTimeId].editable = true;
            }
          }
        });
      });
      returnObject.absences = temp;
    }

    function getAbsencePeriods(student) {
      ///api/groups/:groupId/absences/:personId/periods/
      var cacheBustingTimeStamp = Math.floor(Date.now() / 1000);

      req.url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId +
        '/absences/' + student.id + '/periods/?cachebusting=' + cacheBustingTimeStamp;

      return $q(function(resolve, reject) {
        $http(req).then(
          function(response) {
            resolve(response);
          },
          function(error) {
            console.log('error in absenceService, absences: ', error);
            reject(error);
          });
      });
    }
    // Requests new absence info (absence times/absence codes) from the backend,
    // ussually after a school/group/period update.
    function updateAbsenceInfo() {
      var cacheBustingTimeStamp = Math.floor(Date.now() / 1000);

      req.url = baseUrl + '/locations/' + schoolService.getSelectedSchool().id + '/absenceinfo' + periodService.getDatesUrlPart(periodService.useDateRangeArray) + '&cachebusting=' + cacheBustingTimeStamp;

      return $q(function(resolve, reject) {
        $http(req).then(
          function(response) {
            absenceCodes = response.data.absenceCodes;
            absenceTimes = response.data.absenceTimes;
            if (absenceCodes && absenceTimes) {
              resolve({
                'absenceCodes': absenceCodes,
                'absenceTimes': absenceTimes
              });
            } else {
              reject('No absencecodes or absencetimes defined!');
            }
          },
          function(error) {
            console.log('error in absenceService, absenceInfo: ', error);
            reject(error);
          });
      });
    }

    // Request new absence data for every student in the school/group for the
    // selected period.
    function updateAbsences() {
      // req.url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId +
      //   '/absences?startdate=' + periodService.getPeriod().startDate +
      //   '&enddate=' + periodService.getPeriod().endDate;
      var cacheBustingTimeStamp = Math.floor(Date.now() / 1000);

      req.url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId + '/absences' + periodService.getDatesUrlPart(periodService.useDateRangeArray) + '&cachebusting=' + cacheBustingTimeStamp;

      returnObject.absences = [];
      returnObject.absences.length = 0;
      return $q(function(resolve, reject) {
        $http(req).then(
          function(response) {
            returnObject.absences = createAbsenceStructure(response.data.absences);
            resolve(returnObject.absences);
          },
          function(error) {
            console.log('error in absenceService, absences: ', error);
            reject(error);
          });
      });
    }

    function postAbsenceData(absences) {
      var url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId + '/absences',
        data = absences;
      return $http.post(url, data);
    }

    function postAbsencePeriodData(personId, period, startAbsTimeId, endAbsTimeId, absenceCodeId, comment, voormiddag, namiddag, ontkoppelen, bewijs) {

      var url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId + '/absences/periods/' + personId;
      var data = '';
      data = {
        "startDate": moment(period.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        "endDate": moment(period.endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        "startAbsTimeId": startAbsTimeId,
        "endAbsTimeId": endAbsTimeId,
        "absenceCodeId": absenceCodeId,
        "comment": comment,
        "vm": voormiddag,
        "nm": namiddag,
        "ontkoppeld": ontkoppelen,
        "proofDoc": bewijs
      };

      if (period.periodId) {
        data.periodId = period.periodId;
      }
      return $http.post(url, data);
    }

    function postAbsenceMultiPerson(multiData) {

      var personIds = [];
      _.forEach(multiData.selectedPersons, function(person) {
        personIds.push(person.id);
      });
      var url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId + '/absences/multiperson/',
        data = {
          "personIds": personIds,
          "absenceTimeId": multiData.absenceTimeId,
          "absenceCodeId": multiData.absenceCodeId,
          "date": moment(multiData.date).format('YYYY-MM-DDTHH:mm:ssZ'),
          "comment": multiData.comment
        };

      return $http.post(url, data);
    }


    function lockAbsenceList(date, absenceTimeId, lock) {
      var url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId + '/absenceLists/lock',
        data = {
          'absenceLists': []
        };

      data.absenceLists.push({
        'date': moment(date).format('YYYY-MM-DDTHH:mm:ssZ'),
        'absenceTimeId': absenceTimeId,
        'lock': lock
      });

      return $http.post(url, data);
    }

    function clearObject(objectToClear){
      _.forEach(objectToClear, function(value, key){
        delete objectToClear[key];
      });
    }
    // Restructures the absences we get from the service, in a format which allows
    // easy two-way data-binding.
    // {
    //  studentId: {
    //    hasAbsences: boolean,
    //    date: {
    //      absenceTimeId: {
    //        "code": String,
    //        "codeId": String,
    //        "showSpecial": boolean,
    //        "show": boolean,
    //        "edit": boolean,
    //        "lock": boolean,
    //        "comment": String
    //      },...
    //    },...
    //  },...
    // }
    // Note that studentId, date and absenceTimeId are the actual values as a String
    // key, not the word 'studentId'. the Date key is of the format YYYY/MM/DD
    function createAbsenceStructure(newAbsenceData, isSocketCall) {
      // Reset editable absenceSubcolumnData, but not if it's a socket call
      if (!isSocketCall) {
        _.forEach(absenceSubcolumnData, function(subcolumnDataPerDate) {
          _.forEach(subcolumnDataPerDate, function(subColumnDataPerTime) {
            subColumnDataPerTime.editable = false;
          });
        });
      }

      var absences = returnObject.absences;
      if (!isSocketCall) {
        clearObject(absences);
        clearObject(absenceSubcolumnData);
      }
      _.forEach(newAbsenceData, function(absence) {
        if (!absences[absence.personId]) {
          absences[absence.personId] = {
            hasAbsences: false // initialize this default to false
          };
        }
        var date = moment(absence.date).format('YYYY/MM/DD');
        if (!absences[absence.personId][date]) {
          absences[absence.personId][date] = {};
        }

        if (!absences[absence.personId][date][absence.absenceTimeId]) {
          absences[absence.personId][date][absence.absenceTimeId] = {};
          addAbsenceSubcolumn(date, absence.absenceTimeId);
        }

        var absenceCode = getAbsenceCode(absence.absenceCodeId);
        absences[absence.personId][date][absence.absenceTimeId] = {
          'code': absenceCode.code,
          'codeId': absence.absenceCodeId,
          'showSpecial': (absenceCode.isSubcode || absence.comment),
          'canEdit': (absence.canEdit),
          'lock': absence.isLocked,
          'show': absence.show,
          'isPeriod': absence.period !== 0,
          'comment': absence.comment,
          'precenses': 0
        };

        if (absence.show) {
          absences[absence.personId].hasAbsences = true;
          absenceSubcolumnIsShown(date, absence.absenceTimeId);
        }

        // If any datapoint in the subcolumn is unlocked, the entire subcolumn
        // counts as unlocked
        if (!absence.isLocked) {
          absenceSubcolumnData[date][absence.absenceTimeId].locked = false;
        }
        // If any button in the column is editable, the entire subcolumn is
        if (absence.canEdit) {
          absenceSubcolumnData[date][absence.absenceTimeId].editable = true;
        }
      });

      return absences;
    }

    // Adds the basic structure of a subcolumn to absenceSubcolumnData. This set
    // the locked and editable values to their defaults every time a new date and
    // timeId is found. These values can be overwritten later if necessary.
    function addAbsenceSubcolumn(date, timeId, lock) {
      if (!absenceSubcolumnData[date]) {
        absenceSubcolumnData[date] = {};
      }

      if (!absenceSubcolumnData[date][timeId]) {
        absenceSubcolumnData[date][timeId] = {};
        absenceSubcolumnData[date][timeId].locked = true;
        absenceSubcolumnData[date][timeId].editable = false;
      }
    }

    // If any absence data in a subcolumn has show = true, then the footer elements
    // are shown
    function absenceSubcolumnIsShown(date, absenceTimeId) {
      absenceSubcolumnData[date][absenceTimeId].show = true;
    }

    // Returns the absenceCode for a given id. If the absencecode is a subcode,
    // it returns the code of the parent absencecode, and sets the 'isSubcode'
    // value to true. (this must then display a small 'i' next to the button
    // in the view)
    function getAbsenceCode(id) {
      var result = {
        'code': '',
        'isSubcode': false
      };

      var found = false;
      _.forEach(absenceCodes, function(absenceCode) {
        if (absenceCode.id === id) {
          result.code = absenceCode.code;
          found = true;
        } else {
          _.forEach(absenceCode.subCodes, function(subCode) {
            if (subCode.id === id) {
              result.code = absenceCode.code;
              result.isSubcode = true;
              found = true;
              return false; //breaks the inner forEach loop
            }
          });
        }
        if (found) {
          return false; //breaks the outer forEach loop
        }
      });

      return result;
    }

    function removeAbsencePeriod(periodId, studentId) {
      var url = baseUrl + '/groups/' + groupService.getSelectedGroup().groupId + '/absences/periods/' + studentId + '/remove',
        data = {
          "periodId": periodId
        };

      return $http.post(url, data);
    }

    // Instead of just returning, I'm making a returnObject to ensure references to
    // returnObject.absences are kept and not removed in garbage collection
    var returnObject = {
      getAbsenceCodes: function() {

        /*----------- Add empty absence code too absence codes ------------- */
        /*
        var firstObj = {
          code: 0,
          description: "",
          id: 0,
          onlyPeriod: false,
          subCodes: [],
          types: [{
              0: "L"
            }]
        };

        //Checks if array contains the empty code, if not, add it as first object
        var checkIfInArray = '';
        _.result(_.find(absenceCodes, function (item) {
          if (item.code === 0) {
            checkIfInArray = item;
          }
          return item.code === 0;
        }));
        if (!checkIfInArray) {
          absenceCodes.unshift(firstObj);
        }
*/
        return absenceCodes;
      },
      getAbsenceTimes: function() {
        return absenceTimes;
      },
      absences: absences,
      absenceSubcolumnData: absenceSubcolumnData,
      updateAbsenceInfo: updateAbsenceInfo,
      updateAbsences: updateAbsences,
      mergeAbsenceData: mergeAbsenceData,
      postAbsenceData: postAbsenceData,
      postAbsencePeriodData: postAbsencePeriodData,
      lockAbsenceList: lockAbsenceList,
      postAbsenceMultiPerson: postAbsenceMultiPerson,
      mergeLockAbsenceData: mergeLockAbsenceData,
      createAbsenceStructure: createAbsenceStructure,
      getAbsencePeriods: getAbsencePeriods,
      removeAbsencePeriod: removeAbsencePeriod
    };

    return returnObject;

  }
];
