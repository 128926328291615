module.exports = ['$rootScope', 'storage', 'lodash',
  function($rootScope, storageService, lodashService)  {
    "use strict";

    var _ = lodashService._,
      singleDayMode = false;

    // Period expects two dates of format YYYY/MM/DD
    // Initialize as today
    var period = {
      startDate: convertMoment(moment()),
      endDate: convertMoment(moment()),
      startDateOfSchoolYear: '',
      endDateOfSchoolYear: ''
    };

    function getPeriod() {
      return period;
    }

    function getPeriodAsMoment() {
      return {
        startDate: moment(period.startDate, "YYYY/MM/DD"),
        endDate: moment(period.endDate, "YYYY/MM/DD")
      };
    }

    function getDatesInPeriod() {
      var dates = [],
        periodMoment = getPeriodAsMoment(),
        newDate = periodMoment.startDate;

      if (period.startDate === period.endDate) {
        return [createPeriodDate(periodMoment.startDate)];
      }

      function addNewDate(date) {
        dates.push(createPeriodDate(date));
      }

      addNewDate(periodMoment.startDate);
      do {
        newDate = moment(newDate).add(1, 'days');
        addNewDate(newDate);
      } while (newDate.isBetween(periodMoment.startDate, periodMoment.endDate));
      return dates;
    }

    function createPeriodDate(date, prependDisplay) {
      prependDisplay = (prependDisplay) ? prependDisplay + ' ' : '';
      var formattedDate = convertMoment(date);
      if (!moment.isMoment(date)) {
        date = moment(date, 'YYYY/MM/DD');
      }
      return {
        'formatted': formattedDate,
        'moment': date,
        'display': prependDisplay + ' ' + date.format("DD.MM.YYYY"),
        'day': date.format('ddd')
      };
    }

    function convertMoment(date) {
      if (moment.isMoment(date)) {
        date = date.format('YYYY/MM/DD');
      }
      return date;
    }

    // Savedata should be false when the period is set from local storage/cookie,
    // and true whenever the user or the rest api sets the selected school.
    function setPeriod(startDate, endDate, saveData) {
      if (singleDayMode) {
        endDate = startDate;
      }

      if (startDate && endDate) {
        period.startDate = convertMoment(startDate);
        period.endDate = convertMoment(endDate);
        if (saveData) {
          storageService.saveData({
            'startDate': period.startDate,
            'endDate': period.endDate
          });
        }
        previousPeriod = period;
        getSchoolYearBounds();
      }
    }

    var previousPeriod;

    function setSinglePeriod(isSinglePeriod) {
      singleDayMode = isSinglePeriod;
      if (singleDayMode) {
        previousPeriod = _.assign({}, period);
        var temp = period.startDate;
        period.endDate = temp;
      } else {
        // If previousPeriod exists and single day mode has been set to false,
        // set the period as the previous selected one.
        // Example use case: User has switched to grid view, and thus to single day
        // mode. Day is set to first of period. When switching back to column view,
        // the data of the entire period is still available, so we want to display
        // the previously selected period instead of the single day mode, IF the user
        // hasn't overwritten this by selecting another day in grid view.
        if (previousPeriod) {
          // TODO This is weird. This code only works if these console logs are present.
          // Remove the logs and the code stops working. :( :(
          period = previousPeriod;
        }
      }
      getSchoolYearBounds();
    }

    function resetPeriod() {
      period = {
        startDate: convertMoment(moment()),
        endDate: convertMoment(moment())
      };
    }

    // If this is true, all POSTS from this controller should use daterangeArray,
    // not dateStart/dateEnd
    var useDateRangeArray = false,
      // Use this if above is true.
      dateRangeArray = [],
      // Daterange filters
      daterangeFilters = [],
      // Reversed daterangeArray, used to mark these days in gray
      reversedDateRangeArray = [];

    function getDatesUrlPart(useDateRangeArray) {
      var queryParams = '';

      if (useDateRangeArray) {
        queryParams += '?dates=';
        _.forEach(this.dateRangeArray, function(date) {
          queryParams += date.moment.format('YYYY-MM-DD') + ',';
        });

        queryParams = queryParams.slice(0, -1); //removes the last redundant comma from the string
      } else {
        queryParams += '?startdate=' + getPeriod().startDate + '&enddate=' + getPeriod().endDate;
      }

      return queryParams;
    }

    function getSchoolYearBounds() {
      var year = getPeriodAsMoment().startDate.year(),
          startYear, endYear = 0;

      if (getPeriodAsMoment().startDate.month() >= 8) {
        // Month >= 8 means it's after september. (month is zero indexed) So the year is the startYear.
        startYear = year;
        endYear = startYear + 1;
      }
      else {
        startYear = year - 1;
        endYear = year;
      }

      period.startDateOfSchoolYear = moment('01-09-' + startYear, 'DD-MM-YYYY').format('YYYY/MM/DD');
      period.endDateOfSchoolYear = moment('31-08-' + endYear, 'DD-MM-YYYY').format('YYYY/MM/DD');
    }

    return {
      getPeriod: getPeriod,
      getPeriodAsMoment: getPeriodAsMoment,
      setPeriod: setPeriod,
      getDatesInPeriod: getDatesInPeriod,
      setSinglePeriod: setSinglePeriod,
      createPeriodDate: createPeriodDate,
      resetPeriod: resetPeriod,
      useDateRangeArray: useDateRangeArray,
      dateRangeArray: dateRangeArray,
      daterangeFilters: daterangeFilters,
      reversedDateRangeArray: reversedDateRangeArray,
      getDatesUrlPart: getDatesUrlPart,
      getSchoolYearBounds: getSchoolYearBounds
    };
  }
];
