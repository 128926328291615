/**
 *  directive name: activityBar
 *  Responsible for positioning the elements in the activity bar correctly.
 *  The add activity button should sit right under the other activity buttons,
 *  but when the wrapper around those buttons becomes to small (and scrollable),
 *  the add button should remain visible in a fixed position.
 *
 *  This directive's scope contains a property representing the length of the
 *  activities array (note: perhaps it's possible to obtain this from a service?)
 *  and the initial top offset of the activity button.
 *
 *  Dependency: $window, to place a listener on the resize event.
 */

module.exports = ['$window', '$timeout', 'activities', 'schools', 'groups', 'period', 'lodash',
  function($window, $timeout, activityService, schoolService, groupService, periodService, lodashService) {
    var _ = lodashService._;

    return {
      restrict: 'A',
      scope: {
        numberOfActivities: '=numberOfActivities',
        addActivityButtonTop: '=addActivityButtonTop',
        showArrow: '='
      },
      link: function(scope, element, attrs) {
        // Dynamically position the add activity button
        // Get the wrapper for the activities
        var wrapper = element[0].querySelector('#activity-button-container__wrapper');

        // The add activity button's distance from the top of its container should
        // either be equal to the height of the activity buttons, or the height
        // of the scrollable wrapper around those buttons.
        function calculateActivityButtonTop() {
          var heightOfButtons = scope.numberOfActivities * 96  ||  0,
            wrapperHeight = angular.element(wrapper)[0].offsetHeight;

          scope.addActivityButtonTop = (heightOfButtons < wrapperHeight) ? heightOfButtons : wrapperHeight;
        }

        // Call the function once, to calculate the initial position of the add
        // activity button.
        calculateActivityButtonTop();
        // activityService.listen(function() {
        //   $timeout(calculateActivityButtonTop, 350);
        // });

        // Apply a listener to the resize event of th window element. The top
        // offset of the button should be recalculated on resize.
        $(window).on('resize.resizeActivityBar', _.debounce(function() {
          calculateActivityButtonTop();
          return scope.$apply();
        }, 200));
        // slightly more debounce than dashboardcontroller, to make sure this one is called last

        scope.$on("$destroy", function() {
          $(window).off("resize.resizeActivityBar"); //remove the handler added earlier
        });
      }
    };
  }
];
