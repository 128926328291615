module.exports = ['$scope', 'activities', 'spinnerService', '$timeout', 'lodash', '$rootScope','feedback','period', function($scope, activityService, spinnerService, $timeout, lodashService, $rootScope, feedbackService, periodService) {
  "use strict";
  var _ = lodashService._;
  $scope.activities = [];
  $scope.selectedDay = null;
  $scope.formattedDay = null;
  $scope.activityDateStart = moment(new Date(periodService.getPeriod().startDate));

  activityService.getActivitiesForGroup().then(
    function(response) {
      var tempArr = [];
      _.forEach(response.data.activities, function(activity) {
        if (activity.canAdd) {
          tempArr.push(activity);
        }
      });
      $scope.activities = tempArr;
    },
    function(error) {
      console.log('error in new activity controllers, ', error);
    }
  );


  function formatDate(day) {
    $scope.formattedDay = moment(day).format('YYYY-MM-DDTHH:mm:ssZ');
  }

  $scope.data = {
    selectedActivity: undefined,
    activityTitle: ''
  };
  $scope.startDayMinOne = moment($scope.activityDateStart, "YYYY/MM/DD").subtract(1, 'days');

  // Same for end day
  $scope.endDayPlusOne = moment($scope.activityDateEnd, "YYYY/MM/DD").add(1, 'days');

  $scope.checkIfValid = function() {
    if ($scope.data.selectedActivity === undefined) {
      return false;
    } else {
      return true;
    }
  };
  $scope.optionsDatepickerStart = {
    callback: function(day) {
      filterStartDays(day);
    }
  };

  function filterStartDays(day) {
    $scope.activityDateStart = day;
  }



  $scope.addActivity = function() {
    if ($scope.checkIfValid()) {
      $scope.data.selectedActivity = $scope.activities[$scope.data.selectedActivity];
      if ($scope.data.activityTitle === '') {
        $scope.data.activityTitle = null;
      }
      $scope.data.selectedDate = $scope.activityDateStart;

      spinnerService.show('modalSpinner');
      activityService.addNewActivity($scope.data).then(
        function(response) {
          spinnerService.hide('modalSpinner');
          feedbackService.createFeedback(response.data, 'Activiteiten', 'Activiteit is toegevoegd');

          $rootScope.$broadcast('update-dashboard');
          $scope.closeThisDialog();
        },
        function(error) {
          spinnerService.hide('modalSpinner');
          feedbackService.createFeedback(error.data, 'Activiteiten', 'Activiteit kon niet worden toegevoegd');

          console.log('error in newActivityController, schools: ', error);
        });
    }
  };
}];
