// Takes care of boilerplate require code for browserify
var app = angular.module('informatApp');

app.factory('authentication', require('./authenticationService'));
app.factory('students', require('./studentService'));
app.factory('activities', require('./activityService'));
app.factory('columns', require('./columnService'));
app.factory('authenticationInterceptor', require('./authenticationInterceptor'));
app.factory('apiInterceptor', require('./apiInterceptor'));
app.factory('schools', require('./schoolService'));
app.factory('groups', require('./groupService'));
app.factory('api', require('./apiService'));
app.factory('storage', require('./storageService'));
app.factory('restore', require('./restoreService'));
app.factory('lodash', require('./lodashService'));
app.factory('period', require('./periodService'));
app.factory('absences', require('./absenceService'));
app.factory('addresses', require('./addressService'));
app.factory('multiRegistration', require('./multiRegistrationService'));
app.factory('feedback', require('./feedbackService'));
