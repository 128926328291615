module.exports = [function () {
  // calculate the scrollbar width
  var scrollDiv = document.createElement("div");
  scrollDiv.className = "scrollbar-measure";
  document.body.appendChild(scrollDiv);
  // Get the scrollbar width
  var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  // Delete the DIV
  document.body.removeChild(scrollDiv);


  return {
    restrict: 'A',
    link: function(scope, element, attributes) {
      element.css('right',scrollbarWidth+'px');
    }
  };
}];
