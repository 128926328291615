module.exports = ['$scope', 'ngDialog', 'lodash', 'activities', 'spinnerService', function($scope, ngDialog, lodashService, activityService, spinnerService) {

  var _ = lodashService._,
    student = $scope.ngDialogData.student,
    activityId = $scope.ngDialogData.activityId,
    activityLabel = $scope.ngDialogData.activityLabel,
    articleId = $scope.ngDialogData.articleId,
    date = $scope.ngDialogData.date,
    activityHash = $scope.ngDialogData.activityHash;

  $scope.locked = $scope.ngDialogData.locked;
  $scope.optionarticleValues = $scope.ngDialogData.articleValues;
  $scope.data = {};
  $scope.student = $scope.ngDialogData.student;
  _.forEach($scope.optionarticleValues, function(optionArticle) {
    if (optionArticle.type === 'bool') {
      if (optionArticle.value === 'JA') {
        $scope.data[optionArticle.id] = true;
      } else if ($scope.data[optionArticle.id] === 'NEE') {
        $scope.data[optionArticle.id] = false;
      }
    } else if (optionArticle.type === 'time') {
      if (optionArticle.value) {
        $scope.data[optionArticle.id] = {};
        $scope.data[optionArticle.id].date = optionArticle.value;
        $scope.data[optionArticle.id].checked = true;
        $scope.data[optionArticle.id].formattedDate = moment(optionArticle.value).format('HH:mm');
      }else{
        $scope.data[optionArticle.id] = {};
        $scope.data[optionArticle.id].date = null;
        $scope.data[optionArticle.id].checked = false;
      }
    } else {
      $scope.data[optionArticle.id] = parseInt(optionArticle.value);
    }
  });

  $scope.postMultiValue = function() {
    if ($scope.isValid()) {
      //studentId, date, activityHash, addressId, articleId, articleValues
      var articleValues = [];
      _.forEach($scope.data, function(item, key) {

        if (item === true) {
          item = "JA";
        } else if (item === false) {
          item = "NEE";
        }
        if (item) {
          var tempValue;
          if (_.isObject(item)) {
            if (item.checked) {
              var wasEmpty = true;
              if (item.date) {
                wasEmpty = false;
              }
              tempValue = {
                id: key,
                value: moment(item.date).format('YYYY-MM-DDTHH:mm:ssZ')
              };
              tempValue.value.wasEmpty = wasEmpty;
            } else {
              tempValue = {
                id: key,
                value: null
              };
            }
          } else {
            tempValue = {
              id: key,
              value: item
            };
          }
          articleValues.push(tempValue);
        }
      });

      if ($scope.student.addresses.length < 2) {
        spinnerService.show('modalSpinner');
      }
      $scope.ngDialogData.postRegistration(student.id, date, activityHash, articleId, articleValues);
    }
  };

  $scope.checkTimePicker = function(articleId) {
    $scope.data[articleId].checked = true;
    $scope.data[articleId].formattedDate = moment($scope.data[articleId].date).format('HH:mm');
  };

  $scope.isValid = function() {
    if ($scope.locked) {
      $scope.valid = false;
      return false;
    }

    var found = false;
    var checkedFields = [];
    _.forEach($scope.optionarticleValues, function(optionArticleValue) {
      var key = optionArticleValue.id;
      var value = optionArticleValue.value;
      if (value) {
        var input = $scope.data[key];
        switch (optionArticleValue.type) {
          case 'time':
            if (input.checked) {
              found = true;
            }else if(typeof input.checked === 'boolean' && !input.checked){
              found = true;
            }
            break;
          case 'num':
            // no strict checking here because sometimes it is passed as string
            if (input != value) {
              found = true;
            }
            break;
          case 'bool':
            if (value === 'NEE') {
              value = false;
            } else {
              value = true;
            }
            if (!input) {
              input = false;
            }
            if (input !== value) {
              found = true;
            }
            break;
          default:
            if (input) {
              found = true;
            }
        }
        checkedFields.push(parseInt(key));
      }
    });

    _.forEach($scope.data, function(i, key) {
      if ((i || !_.isEmpty(i)) && checkedFields.indexOf(parseInt(key)) === -1) {
        if (_.isObject(i)) {
          if (i.checked) {
            if (!i.date) {
              i.date = moment().format('YYYY-MM-DDTHH:mm:ssZ');
            }
            found = true;
          }
        } else {
          found = true;
        }
      }
    });

    $scope.valid = found;
    return found;
  };
  $scope.isValid(); // make sure the scope is validated when modal is opened
}];
