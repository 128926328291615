module.exports = [function () {
  function isHighDensity(){
    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
  }

  return {
    restrict: 'A',
    scope: {
      checkRetinaImage: '@'
    },
    link: function (scope, element, attributes) {
      // add 2 to the size part of the url
      if(isHighDensity()){
        scope.checkRetinaImage = scope.checkRetinaImage.replace(/([?&]f=)([^&#0-9]*)([^&#]*)/,'$1$2'+'2');
      }
      element.attr('src',scope.checkRetinaImage);
    }
  };
}];
