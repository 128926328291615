module.exports = ['$http', '$q', 'storage', 'period', 'lodash',
  function($http, $q, storageService, periodService, lodashService)  {
    "use strict";

    var schools = [],
      baseUrl = '/api',
      selectedSchool = {
        id: '',
        name: '',
        name2: '',
        fullName: ''
      },
      holidays = [],
      holidaysAsMoment = [],
      req = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      },
      _ = lodashService._;

    function getSchools() {
      return schools;
    }

    function getSelectedSchool() {
      return selectedSchool;
    }

    // Savedata should be false when the school is set from local storage/cookie,
    // and true whenever the user or the rest api sets the selected school.
    function setSelectedSchool(school, saveData) {
      if (school.id && school.name && school.name2) {
        selectedSchool = school;
        if (saveData) {
          storageService.saveData({
            'schoolId': school.id,
            'schoolName': school.name,
            'schoolName2': school.name2,
            'schoolFullname': school.fullName
          });
        }
      }
    }

    function setDefaultSchool(saveData) {
      var school = getDefaultSchool() || schools[0];
      setSelectedSchool(school, saveData);
    }

    function clearSchool() {
      selectedSchool = {
        fullName: "Geen scholen gevonden"
      };
      schools.push(selectedSchool);
    }

    function getDefaultSchool() {
      // find the first default school
      for (var i = 0, l = schools.length; i < l; i++) {
        var school = schools[i];
        if (school.default) {
          return school;
        }
      }
      // if no default school is found, return null
      return null;
    }

    function updateSchools() {
      req.url = baseUrl + '/locations?startdate=' + periodService.getPeriod().startDate + '&enddate=' + periodService.getPeriod().endDate;
      return $q(function(resolve, reject) {
        $http(req).then(
          function(response) {
            schools = response.data.locations;
            resolve(schools);
          },
          function(error) {
            console.log('error in schoolService: ', error);
            reject(error);
          });
      });
    }

    var lastHolidaysRequest = '';

    function updateHolidays() {
      var period = periodService.getPeriod();
      if (!period.startDateOfSchoolYear || !period.endDateOfSchoolYear) {
        periodService.getSchoolYearBounds();
      }

      req.url = baseUrl + '/locations/' + getSelectedSchool().id + '/holidays?startdate=' + period.startDateOfSchoolYear + '&enddate=' + period.endDateOfSchoolYear;

      // Only call holidays if the last holiday request does not equal the previous one.
      // The two request objects will be equal if the we're still in the same location/schoolyear.
      var promise;
      if (lastHolidaysRequest === req.url) {
        promise = $q(function(resolve, reject) {
          resolve(holidays);
        });
      } else {
        promise = $q(function(resolve, reject) {
          $http(req).then(
            function(response) {
              holidaysAsMoment = [];
              holidays = response.data.holidays;
              _.forEach(holidays, function(holiday) {
                holidaysAsMoment.push(moment(holiday.date, 'YYYY-MM-DDTHH:mm:ssZ'));
              });
              resolve(holidays);
            },
            function(error) {
              console.log('error in schoolService, updateHolidays: ', error);
              reject(error);
            });
        });
      }
      lastHolidaysRequest = req.url;
      return promise;
    }

    function getHolidays() {
      return holidays;
    }

    function getHolidaysAsMoment() {
      return holidaysAsMoment;
    }

    return {
      getSchools: getSchools,
      updateSchools: updateSchools,
      getSelectedSchool: getSelectedSchool,
      setSelectedSchool: setSelectedSchool,
      clearSchool: clearSchool,
      getDefaultSchool: getDefaultSchool,
      setDefaultSchool: setDefaultSchool,
      updateHolidays: updateHolidays,
      getHolidays: getHolidays,
      getHolidaysAsMoment: getHolidaysAsMoment
    };
  }
];
