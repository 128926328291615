module.exports = [function () {
  var currentHover = null;
  var currentHoverIndex;
  function rowOverHandler(event){
    var element = jQuery(this); // get the (delegated) target element
    var index = element.attr('data-index'); // element's index is stored in data because it might not equal the actual index in DOM
    // only trigger the hover if the index has changed
    if(index !== currentHoverIndex){
      var newHover = jQuery('#columnView .column__row[data-index="'+index+'"]');
      addHoverStyle(newHover);
    }

    // make sure the current index is remembered
    currentHoverIndex = index;
  }
  function rowOutHandler(){
    removeHoverStyle();
  }
  function addHoverStyle(elements){
    // first unhover
    removeHoverStyle();
    if(elements){
      elements.addClass('column__row--hover');
      currentHover = elements;
    }
  }
  function removeHoverStyle(){
    if(currentHover){
      currentHover.removeClass('column__row--hover');
      currentHover = null;
    }
    currentHoverIndex = undefined;
  }

  return {
    restrict: 'A',
    link: function (scope, element, attributes) {
      // no event delegation in angular, so we will have to do it with jQuery
        // attach event, only listen to elements with an index attribute
      jQuery(element)
        .on('mouseenter','.column__row[data-index]',rowOverHandler)
        .on('mouseleave','.column__row[data-index]',rowOutHandler);
    }
  };
}];
