/**
  * RestoreService
  *
  * This service provides a way to restore data to the dashboard on page refresh.
 **/

 module.exports = ['$state', 'storage', 'authentication', 'schools', 'groups', 'period',
 function($state, storageService, authenticationService, schoolService, groupService, periodService) {
   "use strict";

   var storedValues = {};

   function restoreUser() {
     authenticationService.setUser(storedValues.user);
   }

   function restoreSelectedSchool() {
     var selectedSchool = {
       'id': storedValues.schoolId,
       'name': storedValues.schoolName,
       'name2': storedValues.schoolName2,
       'fullName': storedValues.schoolFullname
     };
     schoolService.setSelectedSchool(selectedSchool, false);
   }

   function restoreSelectedGroup() {
     var selectedGroup = {
       'groupId': storedValues.groupId,
       'groupName': storedValues.groupName,
       'applications': storedValues.applications
     };
     groupService.setSelectedGroup(selectedGroup, false);
   }

   function restoreSelectedPeriod() {
     periodService.setPeriod(storedValues.startDate, storedValues.endDate, false);
   }

   function restore() {
     console.log('--- RESTORING PREVIOUS SESSION ---');
     storedValues = storageService.retrieveAll();

     if (storedValues) {
       // if cookie exists, restore data and return true.

       if (storedValues.schoolId && storedValues.schoolName && storedValues.schoolName2) {
         restoreUser();
         restoreSelectedSchool();
         restoreSelectedGroup();
         restoreSelectedPeriod();
       }
       else {
         // School has not been previously stored in cookie
         console.log('--- PREVIOUS SESSION NOT FOUND. REDIRECTING... ---');
         $state.go('schoolSelection');
       }
     }
     else {
       // no cookie
       console.log('--- PREVIOUS SESSION NOT FOUND. REDIRECTING... ---');
       $state.go('login');
     }
   }

   return {
     restore: restore
   };
 }];
